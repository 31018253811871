import React, { Fragment, FunctionComponent, useEffect } from "react"
import useStore from "../../Store"
import { Layer, LayerProps, useMap, Source, MapLayerMouseEvent } from "react-map-gl/maplibre"
import { hasPresentKey, isDefined } from "ts-is-present"
import { toGeoJSON } from "@mapbox/polyline"
import { Feature, FeatureCollection, LineString, Point, Position } from "geojson"

export const RouteLinksLayer_MarkerLayerId = "RouteLinksLayer_MarkerLayerId"
export const RouteLinksLayer_LinkIdLayerId = "RouteLinksLayer_LinkIdLayerId"
export const RouteLinksLayer_LineLayerId = "RouteLinksLayer_LineLayerId"
const RouteLinksPoints_Source = "RouteLinksPoints_Source"
const RouteLinksLines_Source = "RouteLinksLines_Source"

export const RouteLinksLayer: FunctionComponent = () => {
   const routeResult = useStore(state => state.routeResult)
   const selectedRouteId = useStore(state => state.selectedRouteId)
   const activeRouteId = useStore(state => state.activeRouteId)
   const debugModeEnabled = useStore(state => state.debugModeEnabled)

   const { current: map } = useMap()

   let hoveredRouteLinkId: number | null = null

   // Ok, sorry for that :-) but it's for debugging, only
   useEffect(() => {
      if (map /*&& map.loaded()*/ && routeResult?.result.value === "Success") {
         setTimeout(() => {
            if (map.getLayer(RouteLinksLayer_LineLayerId) !== undefined) map.moveLayer(RouteLinksLayer_LineLayerId)
            if (map.getLayer(RouteLinksLayer_MarkerLayerId) !== undefined) map.moveLayer(RouteLinksLayer_MarkerLayerId)
            if (map.getLayer(RouteLinksLayer_LinkIdLayerId) !== undefined) map.moveLayer(RouteLinksLayer_LinkIdLayerId)
         }, 75)
      }
   }, [routeResult])

   const onMouseMoveHandler = (e: MapLayerMouseEvent) => {
      const feature = e.features?.[0]
      if (feature) {
         const id = feature.id
         // NUMBER !!! WTF
         if (typeof id === "number") {
            if (hoveredRouteLinkId !== null) {
               map?.setFeatureState({ source: RouteLinksLines_Source, id: hoveredRouteLinkId }, { hover: false })
            }
            hoveredRouteLinkId = id
            map?.setFeatureState({ ...feature }, { hover: true })
         }
      }
   }

   function onMouseLeaveHandler(e: MapLayerMouseEvent) {
      if (hoveredRouteLinkId !== null) {
         map?.setFeatureState({ source: RouteLinksLines_Source, id: hoveredRouteLinkId }, { hover: false })
      }
   }

   useEffect(() => {
      if (selectedRouteId !== -1) {
         map?.on("mousemove", RouteLinksLayer_LineLayerId, onMouseMoveHandler)
         map?.on("mouseleave", RouteLinksLayer_LineLayerId, onMouseLeaveHandler)
         return () => {
            map?.off("mousemove", RouteLinksLayer_LineLayerId, onMouseMoveHandler)
            map?.off("mouseleave", RouteLinksLayer_LineLayerId, onMouseLeaveHandler)
         }
      }
      return
   }, [selectedRouteId])

   function routeLinkIds(): { id: string; pos: Position; line: LineString }[] {
      return (
         routeResult?.routes[activeRouteId].segments
            .flatMap(segment => segment.route_links)
            .filter(isDefined)
            .filter(hasPresentKey("polyline"))
            .map(link => {
               const line = toGeoJSON(link.polyline.line)
               return {
                  id: link.id,
                  pos: line.coordinates[0], // not geometric center, but ok in virtually all cases.
                  line: line,
               }
            }) ?? []
      )
   }

   function featureCollection(): FeatureCollection[] {
      const links = routeLinkIds()
      const pointFeatures = links.map(link => {
         const x = link.pos[0]
         const y = link.pos[1]
         const id = link.id
         const geometry: Point = {
            type: "Point",
            coordinates: [x, y],
         }
         return {
            type: "Feature",
            geometry: geometry,
            id: id,
            properties: {
               id: id,
               // Could add more props, e.g. the link level
            },
         } as Feature
      })

      const lineFeatures = links.map(link => {
         const geometry = link.line
         const id = link.id
         return {
            type: "Feature",
            geometry: geometry,
            id: id,
            properties: {
               id: id,
               // Could add more props, e.g. the link level
            },
         } as Feature
      })

      return [
         {
            type: "FeatureCollection",
            features: pointFeatures,
         },
         {
            type: "FeatureCollection",
            features: lineFeatures,
         },
      ]
   }

   const markerLayerProps: LayerProps = {
      id: RouteLinksLayer_MarkerLayerId,
      type: "circle",
      filter: ["!", ["has", "point_count"]],
      layout: {
         visibility: debugModeEnabled ? "visible" : "none",
      },
      paint: {
         "circle-color": "grey",
         "circle-radius": 3,
      },
   }

   const linkIdLayerProps: LayerProps = {
      id: RouteLinksLayer_LinkIdLayerId,
      type: "symbol",
      layout: {
         "visibility": debugModeEnabled ? "visible" : "none",
         "text-anchor": "top-left",
         "text-offset": [0.4, 0.4],
         "text-field": "{id}",
         "text-font": ["Noto Sans Regular"],
         "text-size": 14,
      },
      paint: {
         "text-color": "#000000",
         "text-halo-color": "#ff4",
         "text-halo-width": 4,
      },
   }

   const lineLayerProps: LayerProps = {
      id: RouteLinksLayer_LineLayerId,
      type: "line",
      layout: {
         "visibility": debugModeEnabled ? "visible" : "none",
         "line-cap": "round",
      },
      paint: {
         "line-width": 3,
         "line-gap-width": 5,
         "line-color": "grey",
         "line-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 1, 0],
      },
   }

   const data = featureCollection()

   return (
      <Fragment>
         <Source id={RouteLinksPoints_Source} type="geojson" data={data[0]}>
            <Layer {...markerLayerProps} />
            <Layer {...linkIdLayerProps} />
         </Source>
         <Source id={RouteLinksLines_Source} type="geojson" data={data[1]}>
            <Layer {...lineLayerProps} />
         </Source>
      </Fragment>
   )
}
