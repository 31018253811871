import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControlLabel,
   Unstable_Grid2 as Grid,
   Paper,
   Switch,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import { FunctionComponent, useState } from "react"
import { Connector } from "../../../out/gen/openapi/ev"
import { useTranslation } from "react-i18next"
import { isEqual } from "lodash"

interface IConnectorSelectorComponentProps {
   connectors: Connector[]
   activeConnectors: Connector[]
   updateConnectors: (connectors: Connector[]) => void
   disabled: boolean
}

export const ConnectorSelectorComponent: FunctionComponent<IConnectorSelectorComponentProps> = props => {
   const { t } = useTranslation()
   const [open, setOpen] = useState(false)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
   const [selectedConnectors, setSelectedConnectors] = useState<Connector[]>([...props.activeConnectors])

   const handleClickOpen = () => {
      setOpen(true)
   }

   const handleClose = (isOk: boolean) => {
      setOpen(false)
      if (isOk && !isEqual(selectedConnectors, props.activeConnectors)) {
         props.updateConnectors(selectedConnectors)
      }
   }

   const isConnectorChecked = (con: Connector) => {
      return selectedConnectors?.includes(con)
   }

   const isAllChecked = () => {
      return selectedConnectors.length === props.connectors.length
   }

   const handleConnectorCheckChanged = (con: Connector, checked: boolean) => {
      if (checked) {
         setSelectedConnectors(selectedConnectors.concat(con))
      } else {
         setSelectedConnectors(selectedConnectors.filter(c => c !== con))
      }
   }

   const handleAllCheckedChanged = (checked: boolean) => {
      if (checked) {
         setSelectedConnectors([...props.connectors])
      } else {
         setSelectedConnectors([])
      }
   }

   return (
      <Box sx={{ pt: 1 }}>
         <Button variant="outlined" onClick={handleClickOpen} disabled={props.disabled}>
            {t("ConnectorSelector_Select_Charging_Connectors")}
         </Button>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby={t("ConnectorSelector_Select_Charging_Connectors")}
         >
            <DialogTitle id="responsive-dialog-title">{t("ConnectorSelector_Select_Charging_Connectors")}</DialogTitle>
            <DialogContent>
               <FormControlLabel
                  control={<Switch checked={isAllChecked()} onChange={(e, c) => handleAllCheckedChanged(c)} />}
                  label={t("Generic_All")}
               ></FormControlLabel>

               <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {props.connectors.map((con, index) => {
                     return (
                        <Grid xs={"auto"} key={index} sx={{ maxWidth: "100%" }}>
                           <Paper sx={{ p: 1 }}>
                              <FormControlLabel
                                 control={
                                    <Switch
                                       checked={isConnectorChecked(con)}
                                       onChange={(event, checked) => handleConnectorCheckChanged(con, checked)}
                                    />
                                 }
                                 label={con.displayName}
                                 sx={{
                                    wordBreak: "break-word",
                                    lineBreak: "anywhere",
                                 }}
                              />
                           </Paper>
                        </Grid>
                     )
                  })}
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => handleClose(false)} autoFocus>
                  {t("Generic_Cancel")}
               </Button>
               <Button onClick={() => handleClose(true)} autoFocus>
                  {t("Generic_Ok")}
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   )
}
