import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import {
   Box,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Unstable_Grid2 as Grid,
   IconButton,
   Paper,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import { uniq } from "lodash"
import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import { Vehicle, predefinedVehicles } from "../charger/PredefinedVehicles"

interface ICarSelectionComponentProps {
   updateCar: (vehicle: Vehicle) => void
   buttonText: string
}

export const CarSelectionComponent: FunctionComponent<ICarSelectionComponentProps> = props => {
   const { t } = useTranslation()
   const [open, setOpen] = useState(false)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
   const [currentBrand, setCurrentBrand] = useState("")

   const handleClickOpen = () => {
      setOpen(true)
      setCurrentBrand("")
   }

   const handleClose = (vehicle?: Vehicle) => {
      setOpen(false)
      if (vehicle) props.updateCar(vehicle)
   }

   return (
      <Box sx={{ pt: 1 }}>
         <Button variant="text" onClick={handleClickOpen}>
            {props.buttonText}
         </Button>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => handleClose()}
            aria-labelledby={t("CarSelection_Select_Car")}
         >
            <DialogTitle id="responsive-dialog-title">{t("CarSelection_Select_Car")}</DialogTitle>
            <DialogContent>
               <IconButton disabled={currentBrand === ""} onClick={() => setCurrentBrand("")}>
                  <ArrowBackIcon />
               </IconButton>
               <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {currentBrand === "" &&
                     uniq(predefinedVehicles.map(v => v.brand)).map((brand, index) => {
                        return (
                           <Grid xs={"auto"} key={index} sx={{ maxWidth: "100%" }}>
                              <Paper sx={{ p: 1 }}>
                                 <Button variant="text" onClickCapture={() => setCurrentBrand(brand)}>
                                    {brand === "Custom" ? t("VehicleConfig_Custom_car_brand") : brand}
                                 </Button>
                              </Paper>
                           </Grid>
                        )
                     })}
                  {currentBrand !== "" &&
                     predefinedVehicles
                        .filter(v => v.brand === currentBrand)
                        .map((v, index) => {
                           return (
                              <Grid xs={"auto"} key={index} sx={{ maxWidth: "100%" }}>
                                 <Paper sx={{ p: 1 }}>
                                    <Button variant="text" onClick={() => handleClose(v)}>
                                       {v.model}
                                    </Button>
                                 </Paper>
                              </Grid>
                           )
                        })}
               </Grid>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => handleClose()} autoFocus>
                  Ok
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   )
}
