import { Place } from "@aws-amplify/geo"
import { CognitoUser } from "amazon-cognito-identity-js"
import { Auth, Geo } from "aws-amplify"
import { Feature, FeatureCollection, Point } from "geojson"
import { produce } from "immer"
import { chunk } from "lodash"
import { isDefined } from "ts-is-present"
import { create } from "zustand"
import { Charger, Configuration, DefaultApi as EvApi } from "../out/gen/openapi/ev"
import {
   AutomaticStopover,
   Charging,
   EnergyStatus,
   FacilityPreference,
   GeoPos,
   RoutePreference,
   RouteResult,
   RouteSegment,
   VehicleParameter,
} from "../out/gen/openapi/routeplanner"
import { Vehicle, predefinedVehicles } from "./components/charger/PredefinedVehicles"
import { UseBreakpoint } from "@aws-amplify/ui-react/dist/types/primitives/types/responsive"

export type ChargerInfo = {
   stopover?: AutomaticStopover
   charger?: Charger
}

// 1.
// On HMI, we have max_energy_kWh and remaining_energy_kWh fixed to the vehicle model.
// User cannot change it. Thus, we build a type containing only the properties that a user can change.
// 2.
// On HMI, we want to control remaining energy in percent, not in kWh. Thus, we replace this property.
export type VehicleEnergyStatus = Omit<EnergyStatus, "max_energy_kWh" | "remaining_energy_kWh"> & {
   remaining_energy_percent: number
}

// todo consider api change for this
export type VehicleParameterExt = VehicleParameter & {
   max_energy_kWh: number
}

export type RouteCalculationSetting = {
   useChAlgo: boolean
   useTrafficRealtime: boolean
   wantManeuvers: boolean
   useAlternatives: boolean
   withRouteLinks: boolean
}

export interface Waypoint {
   textInput: string
   pos: GeoPos | undefined
   place?: Place
}

export interface PositionOnPolyline {
   segmentIndex: number // segment from point index to point index + 1
   posOnSegment: number // relative position 0.0 (at start) to 1.0 (at end)
}

export interface UserBlockage {
   id: number
   atRouteSegment: RouteSegment
   begin: PositionOnPolyline
   end: PositionOnPolyline
}

export type UserBlockageElement = "section" | "begin" | "end"

export interface EditBlockage {
   mode: "blockage-hover" | "blockage-drag" | "blockage-popup"
   element: UserBlockageElement
   blockageId: number
   curPosition?: PositionOnPolyline
}

export type MapEditStatus = undefined | EditBlockage

export type CalculationStatus = "undefined" | "pending" | "finished"

export type RouteViewMode = "alternatives" | "details"

export type PopupProps = {
   show: boolean
   pos: GeoPos
   waypointIdx?: number
}

const useStore = create<AppState>((set, get) => ({
   energyStatus: {
      remaining_energy_percent: 90,
      max_energy_percent: 80,
      min_energy_at_destination_percent: 20,
      min_energy_at_stopover_percent: 10,
      other_devices_power_consumption_kW: 0.3,
   },
   updateEnergyStatus: (energyStatus: Partial<VehicleEnergyStatus>) => {
      set(
         produce<AppState>(draft => {
            draft.energyStatus = { ...draft.energyStatus, ...energyStatus }
         }),
      )
   },

   vehicleParameter: {
      electric_drivetrain: true,
      max_energy_kWh: 80,
      vehicle_mass: 1200,
      tip_speed: 120,
      consumption_speed_curve: [],
   },
   updateVehicleParameter: (vehicleParameter: Partial<VehicleParameterExt>) => {
      set(
         produce<AppState>(draft => {
            draft.vehicleParameter = { ...draft.vehicleParameter, ...vehicleParameter }
         }),
      )
   },
   charging: {
      charge_power_ac: 11,
      charge_power_dc: -1,
      provider_preference: [],
      connectorId: [],
      charging_curve: [],
      facility_preference: [],
   },
   updateCharging: (charging: Partial<Charging>) => {
      set(
         produce<AppState>(draft => {
            draft.charging = { ...draft.charging, ...charging }
         }),
      )
   },
   vehicle: predefinedVehicles[0],
   updateVehicle: (vehicle: Vehicle) => {
      set(
         produce<AppState>(draft => {
            draft.vehicle = vehicle
         }),
      )
   },
   addChargingFacilityPreference: (newItem: FacilityPreference) => {
      set(
         produce<AppState>(draft => {
            // Remove potentially existing entry for this id.
            const idx =
               draft.charging.facility_preference?.findIndex(pref => pref.facility_id === newItem.facility_id) ?? -1
            if (idx >= 0) {
               console.warn(
                  `Remove existing facility preference entry for id ${newItem.facility_id} before adding a new one.`,
               )
               draft.charging.facility_preference?.splice(idx, 1)
            }
            draft.charging.facility_preference?.push(newItem)
         }),
      )
   },
   removeChargingFacilityPreference: (idToBeRemoved: number) => {
      set(
         produce<AppState>(draft => {
            const idx = draft.charging.facility_preference?.findIndex(pref => pref.facility_id === idToBeRemoved) ?? -1
            if (idx >= 0) {
               draft.charging.facility_preference?.splice(idx, 1)
            } else {
               console.warn(
                  `Cannot remove facility preference for charger id ${idToBeRemoved} because there is not entry for this id`,
               )
            }
         }),
      )
   },
   routeCalculationSetting: {
      useChAlgo: false,
      useTrafficRealtime: true,
      wantManeuvers: true,
      useAlternatives: false,
      withRouteLinks: true,
   },
   updateRouteCalculationSetting: (routeCalculationSetting: Partial<RouteCalculationSetting>) => {
      set(
         produce<AppState>(draft => {
            draft.routeCalculationSetting = { ...draft.routeCalculationSetting, ...routeCalculationSetting }
         }),
      )
   },
   start: { textInput: "", pos: undefined },
   dest: [{ textInput: "", pos: undefined }],
   updateStart: async (input: string | GeoPos, place?: Place) => {
      let waypoint = convertWaypointInput(input, place)
      if (!waypoint.place && waypoint.pos) {
         const place = await Geo.searchByCoordinates([waypoint.pos?.lon, waypoint.pos?.lat], {
            maxResults: 1,
         })
         waypoint = convertWaypointInput(input, place)
      }
      set(
         produce<AppState>(draft => {
            draft.start = waypoint
         }),
      )
   },
   addWaypoint: async (input: string | GeoPos) => {
      let waypoint = convertWaypointInput(input)
      if (!waypoint.place && waypoint.pos) {
         const place = await Geo.searchByCoordinates([waypoint.pos?.lon, waypoint.pos?.lat], {
            maxResults: 1,
         })
         waypoint = convertWaypointInput(input, place)
      }
      set(
         produce<AppState>(draft => {
            draft.dest.push(waypoint)
         }),
      )
   },
   updateWaypoint: async (input: string | GeoPos, place: Place | undefined, idx: number) => {
      let waypoint = convertWaypointInput(input, place)
      if (!waypoint.place && waypoint.pos) {
         const place = await Geo.searchByCoordinates([waypoint.pos?.lon, waypoint.pos?.lat], {
            maxResults: 1,
         })
         waypoint = convertWaypointInput(input, place)
      }
      set(
         produce<AppState>(draft => {
            draft.dest[idx] = waypoint
         }),
      )
   },
   removeWaypoint: (idx: number | undefined) => {
      set(
         produce<AppState>(draft => {
            if (idx !== undefined) draft.dest.splice(idx, 1)
         }),
      )
   },
   allDestinationsValid: () => {
      return ![get().start, ...get().dest].some(wp => wp.pos === undefined)
   },
   lastDestinationValid: () => {
      const dest = get().dest
      return dest.length > 0 && dest[dest.length - 1].pos !== undefined
   },
   routePreference: [],
   userBlockages: [],

   update: (updateFunc: (appState: AppState) => void) => {
      set(produce<AppState>(updateFunc))
   },
   routeResult: undefined,
   setRouteResult: (res: RouteResult) => {
      set(
         produce<AppState>(draft => {
            res.routes.sort((a, b) => a.travel_info.time - b.travel_info.time)
            draft.routeResult = res
            draft.routeViewMode = "alternatives"
            draft.selectedRouteId = res.routes.length ? 0 : -1
            draft.activeRouteId = res.routes.length ? 0 : -1
         }),
      )
   },
   clearRoute: () => {
      set(
         produce<AppState>(draft => {
            draft.routeResult = undefined
            draft.selectedRouteId = -1
            draft.hoveredRouteId = -1
            draft.activeRouteId = -1
            draft.calculationStatus = "undefined"
            draft.start = { textInput: "", pos: undefined }
            draft.dest = [{ textInput: "", pos: undefined }]
         }),
      )
   },
   clearRouteResult: () => {
      set(
         produce<AppState>(draft => {
            draft.routeResult = undefined
            draft.selectedRouteId = -1
            draft.hoveredRouteId = -1
            draft.activeRouteId = -1
         }),
      )
   },
   mapEditStatus: undefined,
   calculationStatus: "undefined",
   updateCalculationStatus: (s: CalculationStatus) => {
      set(
         produce<AppState>(draft => {
            draft.calculationStatus = s
         }),
      )
   },
   routeViewMode: "alternatives",
   updateRouteViewMode: (mode: RouteViewMode) => {
      set(
         produce<AppState>(draft => {
            draft.routeViewMode = mode
         }),
      )
   },
   selectedRouteId: -1,
   updateSelectedRouteId: (id: number) => {
      set(
         produce<AppState>(draft => {
            draft.selectedRouteId = id
            if (draft.hoveredRouteId === -1) draft.activeRouteId = id
         }),
      )
   },
   hoveredRouteId: -1,
   updateHoveredRouteId: (id: number) => {
      set(
         produce<AppState>(draft => {
            draft.hoveredRouteId = id
            if (id > -1) draft.activeRouteId = id
            else if (draft.selectedRouteId > -1) draft.activeRouteId = draft.selectedRouteId
            else draft.activeRouteId = -1
         }),
      )
   },
   activeRouteId: -1,
   activeTrafficEventId: -1,
   updateActiveTrafficEventId: (id: number) => {
      set(
         produce<AppState>(draft => {
            draft.activeTrafficEventId = id
         }),
      )
   },
   locationSelectPopupProps: { show: false, pos: { lat: 0, lon: 0 }, waypointIdx: undefined },
   updateLocationSelectPopupProps: (props: PopupProps) => {
      set(
         produce<AppState>(draft => {
            draft.locationSelectPopupProps = props
         }),
      )
   },
   chargersOnCurrentRouteResult: new Map(),
   updateChargersOnCurrentRouteResult: (chargers: Charger[]) => {
      set(
         produce<AppState>(draft => {
            draft.chargersOnCurrentRouteResult = new Map(chargers.map(c => [c.id, c]))
         }),
      )
   },
   chargerFeatureCollection: {
      type: "FeatureCollection",
      features: [],
   },
   setChargerFeatures: (chargers: Feature[]) => {
      set(
         produce<AppState>(draft => {
            draft.chargerFeatureCollection = {
               type: "FeatureCollection",
               features: chargers,
            }
         }),
      )
   },
   selectedCharger: null,
   updateSelectedCharger: (chargingStopover: ChargerInfo | null) => {
      set(
         produce<AppState>(draft => {
            draft.selectedCharger = chargingStopover
         }),
      )
   },
   debugModeEnabled: false,
   setDebugModeEnabled: debugMode => {
      set(
         produce<AppState>(draft => {
            draft.debugModeEnabled = debugMode
         }),
      )
   },
   trafficFlowEnabled: false,
   setTrafficFlowEnabled: trafficFlowEnabled => {
      set(
         produce<AppState>(draft => {
            draft.trafficFlowEnabled = trafficFlowEnabled
         }),
      )
   },
   trafficLayerEnabled: false,
   setTrafficLayerEnabled: (enabled: boolean) => {
      set(
         produce<AppState>(draft => {
            draft.trafficLayerEnabled = enabled
         }),
      )
   },
   chargerLayerEnabled: true,
   setChargerLayerEnabled: (enabled: boolean) => {
      set(
         produce<AppState>(draft => {
            draft.chargerLayerEnabled = enabled
         }),
      )
   },
   terrainLayerEnabled: false,
   setTerrainLayerEnabled: (enabled: boolean) => {
      set(
         produce<AppState>(draft => {
            draft.terrainLayerEnabled = enabled
         }),
      )
   },
   tileBoundaryLayerEnabled: false,
   setTileBoundaryLayerEnabled: (enabled: boolean) => {
      set(
         produce<AppState>(draft => {
            draft.tileBoundaryLayerEnabled = enabled
         }),
      )
   },
   collisionBoxesLayerEnabled: false,
   setCollisionBoxesLayerEnabled: (enabled: boolean) => {
      set(
         produce<AppState>(draft => {
            draft.collisionBoxesLayerEnabled = enabled
         }),
      )
   },
   overdrawInspectorLayerEnabled: false,
   setOverdrawInspectorLayerEnabled: (enabled: boolean) => {
      set(
         produce<AppState>(draft => {
            draft.overdrawInspectorLayerEnabled = enabled
         }),
      )
   },
}))

let isLoadingChargers: boolean = false

async function loadChargers(): Promise<void> {
   if (isLoadingChargers) {
      return
   }
   const evApi = new EvApi(
      new Configuration({
         apiKey: "AsYh7X0x3IaRqAIlpyJ4o85OQBR720UQ3SWGNuA1",
      }),
   )
   try {
      const user = await Auth.currentAuthenticatedUser()
      let username = undefined
      if (user instanceof CognitoUser) {
         username = user.getUsername()
      }
      const res = await evApi.chargerGet(undefined, "compressed", {
         headers: {
            Authorization: username,
         },
      })
      const data = res.data
      if (!Array.isArray(data)) {
         const lowPowerChunks = chunk(data.lowPower, 3)
         const mediumPowerChunks = chunk(data.mediumPower, 3)
         const highPowerChunks = chunk(data.highPower, 3)

         const lowPowerFeatures = lowPowerChunks
            .map(feat => {
               if (Number.isFinite(feat[0]) && Number.isFinite(feat[1]) && Number.isInteger(feat[2])) {
                  const x = feat[0]
                  const y = feat[1]
                  const id = feat[2]
                  const geometry: Point = {
                     type: "Point",
                     coordinates: [x, y],
                  }
                  const feature: Feature = {
                     type: "Feature",
                     geometry: geometry,
                     properties: {
                        id: id,
                        power: "low",
                     },
                  }
                  return feature
               }
               return undefined
            })
            .filter(isDefined)

         const mediumPowerFeatures = mediumPowerChunks
            .map(feat => {
               if (Number.isFinite(feat[0]) && Number.isFinite(feat[1]) && Number.isInteger(feat[2])) {
                  const x = feat[0]
                  const y = feat[1]
                  const id = feat[2]
                  const geometry: Point = {
                     type: "Point",
                     coordinates: [x, y],
                  }
                  const feature: Feature = {
                     type: "Feature",
                     geometry: geometry,
                     properties: {
                        id: id,
                        power: "medium",
                     },
                  }
                  return feature
               }
               return undefined
            })
            .filter(isDefined)

         const highPowerFeatures = highPowerChunks
            .map(feat => {
               if (Number.isFinite(feat[0]) && Number.isFinite(feat[1]) && Number.isInteger(feat[2])) {
                  const x = feat[0]
                  const y = feat[1]
                  const id = feat[2]
                  const geometry: Point = {
                     type: "Point",
                     coordinates: [x, y],
                  }
                  const feature: Feature = {
                     type: "Feature",
                     geometry: geometry,
                     properties: {
                        id: id,
                        power: "high",
                     },
                  }
                  return feature
               }
               return undefined
            })
            .filter(isDefined)

         useStore.setState({
            chargerFeatureCollection: {
               type: "FeatureCollection",
               features: lowPowerFeatures.concat(mediumPowerFeatures, highPowerFeatures),
            },
         })
      }
   } catch (error) {
      console.error("Could not load data", error)
   }
}
loadChargers()
isLoadingChargers = true

export interface AppState {
   energyStatus: VehicleEnergyStatus
   updateEnergyStatus: (energyStatus: Partial<VehicleEnergyStatus>) => void
   vehicleParameter: VehicleParameterExt
   updateVehicleParameter: (vehicleParameter: Partial<VehicleParameterExt>) => void
   charging: Charging
   updateCharging: (charging: Partial<Charging>) => void
   vehicle: Vehicle
   updateVehicle: (vehicle: Vehicle) => void
   addChargingFacilityPreference: (pref: FacilityPreference) => void
   removeChargingFacilityPreference: (id: number) => void
   routeCalculationSetting: RouteCalculationSetting
   updateRouteCalculationSetting: (routeCalculationSetting: Partial<RouteCalculationSetting>) => void
   start: Waypoint
   dest: Waypoint[]
   updateStart: (input: string | GeoPos, place?: Place) => Promise<void>
   addWaypoint: (input: string | GeoPos) => void
   updateWaypoint: (input: string | GeoPos, place: Place | undefined, idx: number) => Promise<void>
   removeWaypoint: (idx: number | undefined) => void
   allDestinationsValid: () => boolean
   lastDestinationValid: () => boolean
   routePreference: RoutePreference[]
   userBlockages: UserBlockage[]
   update: (updateFunc: (appState: AppState) => void) => void
   routeResult: RouteResult | undefined
   setRouteResult: (res: RouteResult) => void
   clearRouteResult: () => void
   clearRoute: () => void
   mapEditStatus: MapEditStatus
   calculationStatus: CalculationStatus
   updateCalculationStatus: (s: CalculationStatus) => void
   routeViewMode: RouteViewMode
   updateRouteViewMode: (mode: RouteViewMode) => void
   chargersOnCurrentRouteResult: Map<number, Charger>
   updateChargersOnCurrentRouteResult: (chargers: Charger[]) => void
   chargerFeatureCollection: FeatureCollection
   setChargerFeatures: (chargers: Feature[]) => void

   selectedRouteId: number
   updateSelectedRouteId: (id: number) => void

   // selectedRoute: Route | null
   // updateSelectedRoute: (mode: Route | null) => void

   hoveredRouteId: number
   updateHoveredRouteId: (id: number) => void
   //updateHoveredRoute: (mode: Route | null) => void

   activeRouteId: number

   activeTrafficEventId: number
   updateActiveTrafficEventId: (id: number) => void

   locationSelectPopupProps: PopupProps
   updateLocationSelectPopupProps: (props: PopupProps) => void

   selectedCharger: ChargerInfo | null
   updateSelectedCharger: (props: ChargerInfo | null) => void

   debugModeEnabled: boolean
   setDebugModeEnabled: (debugMode: boolean) => void

   trafficFlowEnabled: boolean
   setTrafficFlowEnabled: (trafficFlowEnabled: boolean) => void

   trafficLayerEnabled: boolean
   setTrafficLayerEnabled: (trafficLayerEnabled: boolean) => void
   chargerLayerEnabled: boolean
   setChargerLayerEnabled: (chargerLayerEnabled: boolean) => void
   terrainLayerEnabled: boolean
   setTerrainLayerEnabled: (chargerLayerEnabled: boolean) => void
   tileBoundaryLayerEnabled: boolean
   setTileBoundaryLayerEnabled: (chargerLayerEnabled: boolean) => void
   collisionBoxesLayerEnabled: boolean
   setCollisionBoxesLayerEnabled: (chargerLayerEnabled: boolean) => void
   overdrawInspectorLayerEnabled: boolean
   setOverdrawInspectorLayerEnabled: (chargerLayerEnabled: boolean) => void
}

function convertWaypointInput(input: string | GeoPos, place?: Place): Waypoint {
   if (typeof input === "string") {
      const [latStr, lonStr] = input.split(",")
      try {
         let pos: GeoPos = { lat: Number.parseFloat(latStr), lon: Number.parseFloat(lonStr) }
         pos = wrapGeoPos(pos)
         return {
            textInput: input,
            pos: pos,
         }
      } catch (e) {
         return {
            textInput: input,
            pos: undefined,
         }
      }
   } else {
      const pos = wrapGeoPos(input)
      return {
         textInput: `${pos.lat.toFixed(5)}, ${pos.lon.toFixed(5)}`,
         pos: pos,
         place: place,
      }
   }
}

function wrapGeoPos(input: GeoPos): GeoPos {
   if (!Number.isFinite(input.lon)) {
      throw Error("Invalid longitude: " + input.lon)
   }
   if (input.lat < -90 || input.lat > 90) {
      throw Error("Invalid latitude: " + input.lat)
   }
   const wrap = ((((input.lon + 180) % 360) + 360) % 360) - 180
   return { lat: input.lat, lon: wrap === -180 ? 180 : wrap }
}

export function isNumberArray(value: unknown): value is number[] {
   return Array.isArray(value) && value.every(v => typeof v === "number")
}

export type UpdateStore = (updateFunc: (appState: AppState) => void) => void

export default useStore
