import { Fragment, FunctionComponent, useMemo, useState } from "react"
import useStore from "../../Store"
import { Layer, LayerProps, Source } from "react-map-gl/maplibre"
import { toGeoJSON } from "@mapbox/polyline"
import { RouteSegment, TILosOnLinkLosEnum } from "../../../out/gen/openapi/routeplanner"
import {
   addOffsetToTiLos,
   enhanceRouteLinkArray,
   enhanceTiLosArrayOnLink,
   reduceGradientStops,
} from "./RouteLineDisplayHelper"

interface IRouteLineDisplayProperties {
   routeColorActive: string
   routeColorInactive: string
}

function getTiLosColor(los: TILosOnLinkLosEnum) {
   switch (los) {
      case TILosOnLinkLosEnum.FREE:
         return "#4bfe4b"
      case TILosOnLinkLosEnum.HEAVY:
         return "#4bfe4b" // OneCoreGo Map Style: "#acfe2e"
      case TILosOnLinkLosEnum.SLOW:
         return "#fefe4b"
      case TILosOnLinkLosEnum.QUEUING:
         return "#feb42a"
      case TILosOnLinkLosEnum.STATIONARY:
         return "#fe6025"
      case TILosOnLinkLosEnum.INCIDENT:
         return "#ef203c"
      case TILosOnLinkLosEnum.NO_TRAFFIC:
         return "#000000"
      case TILosOnLinkLosEnum.NOT_ASSIGNED:
         return "#ffffff"
      case TILosOnLinkLosEnum.UNKNOWN:
         return "#7C4700"
      default:
         return "#7C4700"
   }
}

function makeGradientStops(routeSegment: RouteSegment): (string | number)[] {
   if (routeSegment.route_links === undefined) return []

   const segmentLine = toGeoJSON(routeSegment.polyline.line)
   const routeLinks2 = enhanceRouteLinkArray(routeSegment.route_links, segmentLine.coordinates.length - 1)
   const enhancedTiLosArrayOnLink = routeLinks2.map(it => enhanceTiLosArrayOnLink(it))
   const enhancedTiLosArrayOnLinkFlat = enhancedTiLosArrayOnLink.flat()
   const tiLos2WithOffset = addOffsetToTiLos(enhancedTiLosArrayOnLinkFlat, segmentLine.coordinates)

   // Code for the use with gradient stoppers (i.e. repeat last point directly before new one to avoid long fading gradient)
   const gradientStops: (string | number)[][] = []
   for (let i = 0; i < tiLos2WithOffset.length; ++i) {
      const next = tiLos2WithOffset[i]
      if (i > 0) {
         const last = tiLos2WithOffset[i - 1]
         if (last.tiLos.tiLosOnLink !== next.tiLos.tiLosOnLink) {
            gradientStops.push([
               last.offset + (next.offset - last.offset) * 0.01,
               getTiLosColor(last.tiLos.tiLosOnLink.los),
            ])
            gradientStops.push([
               next.offset - (next.offset - last.offset) * 0.01,
               getTiLosColor(last.tiLos.tiLosOnLink.los),
            ])
         }
      }
      gradientStops.push([next.offset, getTiLosColor(next.tiLos.tiLosOnLink.los)])
   }

   const reducedGradientStops = reduceGradientStops(gradientStops)
   return reducedGradientStops.flat()
}

export const RouteLineDisplay: FunctionComponent<IRouteLineDisplayProperties> = props => {
   const routeResult = useStore(state => state.routeResult)
   const activeRouteId = useStore(state => state.activeRouteId)
   const trafficFlowEnabled = useStore(state => state.trafficFlowEnabled)
   const [trafficRouteStyle, setTrafficRouteStyle] = useState<LayerProps[][]>([[]])

   const makeTiRouteStyle: (routeSegment: RouteSegment) => LayerProps = routeSegment => {
      const gradientStops = makeGradientStops(routeSegment)

      return {
         id: "route",
         type: "line",
         source: "route",
         layout: {
            "line-join": "round",
            "line-cap": "round",
         },
         paint: {
            "line-gradient": ["interpolate", ["linear"], ["line-progress"], ...gradientStops],
            "line-width": 5,
         },
      }
   }

   const routeStyleActive: LayerProps = {
      id: "route",
      type: "line",
      source: "route",
      layout: {
         "line-join": "round",
         "line-cap": "round",
      },
      paint: {
         "line-color": props.routeColorActive,
         "line-width": 5,
      },
   }

   const routeStyleTrafficOutline: LayerProps = {
      id: "route",
      type: "line",
      source: "route",
      layout: {
         "line-join": "round",
         "line-cap": "round",
      },
      paint: {
         "line-color": "#6b6b6b",
         "line-width": 1,
         "line-gap-width": 5,
      },
   }
   const routeStyleActiveOutline: LayerProps = {
      id: "route",
      type: "line",
      source: "route",
      layout: {
         "line-join": "round",
         "line-cap": "round",
      },
      paint: {
         "line-color": "#2c2c2c",
         "line-width": 1,
         "line-gap-width": 4,
      },
   }
   const routeStyleInactive: LayerProps = {
      id: "route",
      type: "line",
      source: "route",
      layout: {
         "line-join": "round",
         "line-cap": "round",
      },
      paint: {
         "line-color": props.routeColorInactive,
         "line-width": 4,
      },
   }

   useMemo(() => {
      if (routeResult?.routes) {
         const styles: LayerProps[][] = routeResult?.routes.map(s =>
            s.segments.map(seg => {
               if (trafficFlowEnabled) return makeTiRouteStyle(seg)
               else return routeStyleActive
            }),
         )
         setTrafficRouteStyle(styles)
      }
   }, [routeResult?.routes, trafficFlowEnabled])

   return (
      <Fragment>
         {routeResult?.result.value === "Success" &&
            routeResult?.routes
               .map((r, idx) => {
                  return {
                     route: r,
                     id: idx,
                  }
               })
               // The route ordering is crucial, otherwise the layer-z-order ("beforeId=...") gets into troubles.
               .sort((a, _) => {
                  return a.id === activeRouteId ? 1 : -1
               })
               .map(route => {
                  return route.route.segments.map((seg, segIdx) => {
                     return (
                        <Source
                           id={`routeSource-${route.id}-${segIdx}`}
                           key={`routeSource-${route.id}-${segIdx}`}
                           type="geojson"
                           lineMetrics={true}
                           data={{
                              type: "Feature",
                              properties: {},
                              geometry: {
                                 type: "LineString",
                                 //coordinates: coordinates[],
                                 coordinates: toGeoJSON(seg.polyline.line).coordinates,
                              },
                           }}
                        >
                           <Layer
                              {...(route.id === activeRouteId
                                 ? trafficFlowEnabled
                                    ? routeStyleTrafficOutline
                                    : routeStyleActiveOutline
                                 : routeStyleInactive)}
                              id={`routeLayer-${route.id}-${segIdx}-outline`}
                           />
                           <Layer
                              {...(route.id === activeRouteId
                                 ? trafficRouteStyle[route.id][segIdx]
                                 : routeStyleInactive)}
                              id={`routeLayer-${route.id}-${segIdx}`}
                              beforeId={
                                 activeRouteId !== route.id ? `routeLayer-${route.id}-${segIdx}-outline` : undefined
                              }
                           />
                        </Source>
                     )
                  })
               })}
      </Fragment>
   )
}
