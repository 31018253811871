import { List, Paper } from "@mui/material"
import { FunctionComponent } from "react"
import { Route } from "../../../out/gen/openapi/routeplanner"
import useStore from "../../Store"
import { theme } from "../../Theme"
import { AlternativeRoutesItem } from "./AlternativeRoutesItem"

interface AlternativeRoutesProperties {
   routes: Route[]
   activeColor: string
   inactiveColor: string
}

export const AlternativeRoutes: FunctionComponent<AlternativeRoutesProperties> = props => {
   const selectedRouteId = useStore(state => state.selectedRouteId)
   const updateSelectedRouteId = useStore(state => state.updateSelectedRouteId)
   const updateHoveredRouteId = useStore(state => state.updateHoveredRouteId)
   const routeResult = useStore(state => state.routeResult)
   const viewMode = useStore(state => state.routeViewMode)
   const updateRouteViewMode = useStore(state => state.updateRouteViewMode)

   return (
      <Paper
         elevation={3}
         sx={{
            marginTop: { xs: 0, md: theme.spacing(1) },
            padding: theme.spacing(1),
            display: routeResult?.result.value === "Success" && viewMode === "alternatives" ? undefined : "none",
         }}
      >
         <List>
            {props.routes.map((r, i) => (
               <AlternativeRoutesItem
                  route={r}
                  isSelected={i === selectedRouteId}
                  key={`altRoutes${i}`}
                  color={i === selectedRouteId ? props.activeColor : props.inactiveColor}
                  onClick={() => updateSelectedRouteId(i)}
                  onSelectDetails={() => {
                     updateSelectedRouteId(i)
                     updateRouteViewMode("details")
                  }}
                  onMouseEnter={() => updateHoveredRouteId(i)}
                  onMouseLeave={() => updateHoveredRouteId(-1)}
               />
            ))}
         </List>
      </Paper>
   )
}
