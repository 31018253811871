import { Duration } from "luxon"

export function toRouteDurationString(seconds: number): string {
   // Round to full seconds to avoid special artifacts like "1h and 60m"
   seconds = Math.round(seconds / 60) * 60
   let duration = Duration.fromObject(
      {
         hours: 0,
         minutes: 0,
         seconds,
      },
      {
         /*
          * We override luxon's auto-selection of the locale.
          *
          * Both Chrome and Firefox show the following behaviour:
          * 1. navigator.languages is the array of preferred languages as it is configured in the browser
          * 2. new Intl.DateTimeFormat().resolvedOptions().locale is always the system (OS) locale, regardless of
          *   (a) browser setting
          *   (b) dev tools sensor setting
          *
          * Luxon internally uses new Intl.DateTimeFormat().resolvedOptions().locale
          * We override luxon's default behaviour with the preferred language from the navigator.
          *
          * This has two advantages:
          * 1. The setting is in-sync with the value for our translations, as obtained by from i18next-browser-languagedetector
          * 2. It's easy to switch language for testing.
          */
         locale: navigator.languages[0],
      },
   ).normalize()

   if (duration.hours > 48) {
      duration = duration.shiftTo("days", "hours")
   } else if (duration.hours > 0) {
      duration = duration.shiftTo("hours", "minutes")
   } else {
      duration = duration.shiftTo("minutes")
   }

   // Omit printing "0 minutes"
   if (duration.minutes === 0) {
      duration = duration.shiftTo("hours")
   }

   return duration.toHuman({
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      unitDisplay: "narrow",
      // compactDisplay: "short",
      // notation: "compact",
      // useGrouping: true,
      listStyle: "narrow",
   })
}
