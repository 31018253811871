import { GeoPos } from "../../../out/gen/openapi/ev"
import { isPresent } from "ts-is-present"
import { MapRef } from "react-map-gl/maplibre"

export function getQ(map: MapRef | undefined, pos: GeoPos | undefined) {
   // q = "quadrant" on visible map where changer is, with "c" center of visible map.
   // q is used as array index for predefined offsets and anchors
   // +---+---+
   // | 2 | 3 |
   // +---c---+
   // | 0 | 1 |
   // +---+---+
   let q = 0
   if (isPresent(map) && isPresent(pos)) {
      const center = map.getBounds().getCenter()
      if (center.lat > pos.lat && center.lng > pos.lon) q = 0
      if (center.lat > pos.lat && center.lng < pos.lon) q = 1
      if (center.lat < pos.lat && center.lng > pos.lon) q = 2
      if (center.lat < pos.lat && center.lng < pos.lon) q = 3
   }
   return q
}
