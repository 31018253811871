import { Configuration, Connector, DefaultApi as EvApi } from "../../../out/gen/openapi/ev"

const evApi = new EvApi(
   new Configuration({
      apiKey: "AsYh7X0x3IaRqAIlpyJ4o85OQBR720UQ3SWGNuA1",
   }),
)
const unknown: Connector = {}
let connectors: Connector[] = []

async function loadConnectors() {
   if (connectors.length === 0) {
      try {
         connectors = (await evApi.connectorsGet()).data
      } catch (error) {
         console.error("error loading connectors", error)
      }
   }
}
loadConnectors()

export function chargerConnectorIdTConnector(id?: number): Connector {
   if (id !== undefined) {
      return connectors.find(con => con.ndsId === id) ?? unknown
   } else {
      return unknown
   }
}

export function getConnectors(): Connector[] {
   return connectors
}
