import { Box } from "@mui/material"
import { FunctionComponent } from "react"
import useStore, { ChargerInfo } from "../../Store"
import { hasPresentKey, isDefined } from "ts-is-present"
import { Route } from "../../../out/gen/openapi/routeplanner"
import { Charger } from "../../../out/gen/openapi/ev"
import { Marker } from "react-map-gl/maplibre"
import { ChargerInfoMarker } from "./ChargerInfoMarker"

function getChargerInfosOnRoute(route: Route, chargers: Map<number, Charger>): ChargerInfo[] {
   return (
      route.segments
         ?.flatMap(segment => segment.automatic_stopovers)
         //.map(stopover => stopover?.facility_id)
         .filter(isDefined)
         .map(stopover => {
            return {
               stopover,
               charger: chargers.get(stopover?.facility_id ?? -1),
            }
         })
         .filter(hasPresentKey("charger"))
         .filter(it => it.charger.position !== undefined)
   )
}

export const StopoverMarkers: FunctionComponent = () => {
   const chargersOnCurrentRouteResult = useStore(state => state.chargersOnCurrentRouteResult)
   const activeRouteId = useStore(state => state.activeRouteId)
   const routeResult = useStore(state => state.routeResult)
   return (
      <>
         {routeResult?.result.value === "Success" &&
            activeRouteId !== -1 &&
            getChargerInfosOnRoute(routeResult.routes[activeRouteId], chargersOnCurrentRouteResult).map(
               (charger, idx) => <StopoverMarker key={`StopoverMarker-${idx}`} chargerInfo={charger} />,
            )}
      </>
   )
}

type StopoverMarkerProps = {
   chargerInfo: ChargerInfo
   zIndex?: number
}

export const StopoverMarker: FunctionComponent<StopoverMarkerProps> = props => {
   // Fine-tuning of zIndex: The marker on the route must have a higher z-index then
   // the marker info box below.
   // This allows the on-route marker a better capture of mouse-over, i.e. it's easier to click it.
   // This is due to the css styling of the shape of the info box's pointing triangle.
   const zIndex = props.zIndex ?? 5

   const selectedCharger = useStore(state => state.selectedCharger)
   const updateSelectedChargingStopover = useStore(state => state.updateSelectedCharger)

   const isCurrentlySelected = () => {
      const myId = props.chargerInfo.stopover?.facility_id ?? props.chargerInfo.charger?.id ?? undefined
      const selectedId = selectedCharger?.stopover?.facility_id ?? selectedCharger?.charger?.id ?? undefined
      return myId !== undefined && myId === selectedId
   }

   return (
      <>
         <Marker
            latitude={props.chargerInfo.charger?.position?.lat ?? 0}
            longitude={props.chargerInfo.charger?.position?.lon ?? 0}
            style={{ zIndex: zIndex }}
            onClick={e => {
               //setShowInfoMarker(idx)
               updateSelectedChargingStopover(props.chargerInfo)
               e.originalEvent.stopPropagation()
            }}
         >
            <Box
               sx={{
                  "display": "flex",
                  "width": 12,
                  "height": 12,
                  "borderRadius": 2,
                  "border": 2,
                  "borderColor": "primary.dark",
                  "backgroundColor": "white",
                  "&:hover": {
                     backgroundColor: "primary.dark",
                     opacity: [1, 1, 1],
                     cursor: "pointer",
                  },
               }}
               justifyContent={"center"}
               alignItems={"center"}
            />
         </Marker>
         {!isCurrentlySelected() && (
            <ChargerInfoMarker
               onClick={e => {
                  //setShowInfoMarker(idx)
                  updateSelectedChargingStopover(props.chargerInfo)
                  e.stopPropagation()
               }}
               chargerInfo={{
                  stopover: {
                     position: props.chargerInfo.stopover?.position,
                     assumed_charging_time: props.chargerInfo.stopover?.assumed_charging_time,
                  },
               }}
               zIndex={zIndex - 1}
            />
         )}
      </>
   )
}
