import { Fragment, FunctionComponent, useEffect } from "react"
import { Layer, LayerProps, Source, SourceProps, useMap } from "react-map-gl/maplibre"
import useStore from "../../Store"
import { TrafficEventLayer_EventPositionLayerId } from "../routeDisplay/TrafficEventLayer"

interface ITiDisplayProperties {}

const tiSourceProps: SourceProps = {
   type: "vector",
   tiles: [
      // DefaultDbFingerprint=b9fe76575da8d0e67c2b1ff47b4a1588 alias nts-nds-db-9-osm-wo-mr14
      "https://ocs.master.onecorego.cloud/OneCore/Mapview/GetTrafficTile/DefaultDbFingerprint/{z}/{x}/{y}?x-api-key=AsYh7X0x3IaRqAIlpyJ4o85OQBR720UQ3SWGNuA1",
   ],
   minzoom: 5,
   maxzoom: 14,
}

const tiLosProps: LayerProps = {
   "id": "LoS",
   "type": "line",
   "source": "ocserver-ti-vector",
   "source-layer": "LoS",
   "minzoom": 0,
   "filter": [
      "all",
      ["has", "LOS"],
      [
         ">=",
         ["zoom"],
         [
            "match",
            ["get", "class"],
            "motorway",
            5,
            "trunk",
            6,
            "primary",
            7,
            "secondary",
            9,
            "tertiary",
            11,
            "minor",
            13,
            14,
         ],
      ],
   ],
   "layout": {
      "line-cap": "round",
      "line-join": "round",
   },
   "paint": {
      "line-color": [
         "match",
         ["get", "LOS"],
         "FREE_TRAFFIC",
         "#4bfe4b",
         "HEAVY_TRAFFIC",
         "#248224",
         "SLOW_TRAFFIC",
         "#fefe4b",
         "QUEUING_TRAFFIC",
         "#feb42a",
         "STATIONARY_TRAFFIC",
         "#fe6025",
         "INCIDENT",
         "#ef203c",
         "NO_TRAFFIC",
         "#000000",
         "NOT_ASSIGNED",
         "#ffffff",
         "UNKNOWN",
         "#7C4700",
         "#f542dd",
      ],
      "line-width": [
         "interpolate",
         ["linear"],
         ["zoom"],
         5,
         1, // zoom level 5, line-width 1
         12,
         2, // zoom level 12, line-width 2
         14,
         5, // zoom level 14, line-width 6
      ],
      "line-offset": [
         "interpolate",
         ["linear"],
         ["zoom"],
         5, // zoom level 5 -> line-offset +/-1.5
         [
            "case",
            ["==", ["get", "LeftHandDriving"], true],
            -1.5, // If LeftHandDriving is true, set line-offset to negative
            1.5, // If LeftHandDriving is false or undefined, set line-offset positive
         ],
         10, // zoom level 10 -> line-offset +/-3
         ["case", ["==", ["get", "LeftHandDriving"], true], -3, 3],
         14, // zoom level 14 -> line-offset +/-6
         ["case", ["==", ["get", "LeftHandDriving"], true], -6, 6],
      ],
   },
}

const tiIncidentProps: LayerProps = {
   "id": "Incidents",
   "type": "circle",
   "source": "ocserver-ti-vector",
   "source-layer": "Incidents",
   "layout": {
      visibility: "visible",
   },
   "paint": {
      "circle-color": "rgba(207, 53, 53, 1)",
      "circle-stroke-color": "rgba(26, 70, 211, 1)",
      "circle-radius": [
         "interpolate",
         ["linear"],
         ["zoom"],
         5,
         2, // zoom level 5, line-width 1
         14,
         5, // zoom level 14, line-width 6
      ],
      "circle-pitch-scale": "map",
   },
}

const tiMetaDataProps: LayerProps = {
   "id": "Metadata",
   "type": "symbol",
   "source": "ocserver-ti-vector",
   "source-layer": "Metadata",
   "layout": {
      "text-field": ["get", "Timestamp"],
      "text-size": 14,
      "text-offset": [0, 1],
      "text-anchor": "top",
      // Add other layout properties as needed
      "text-font": ["Noto Sans Bold"],
   },
   "paint": {
      "text-color": "rgba(0, 0, 0, 1)",
      "text-halo-color": "#ffffff", // Set the halo color (shadow color)
      "text-halo-width": 2, // Adjust the width of the shadow
   },
}

export const TiDisplay: FunctionComponent<ITiDisplayProperties> = props => {
   const debugModeEnabled = useStore(store => store.debugModeEnabled)

   const { current: map } = useMap()

   // ------  Fix layer ordering ------
   // Potentially, a route is currently displayed (i.e. RouteLineDisplay, RouteLinksLayer, TrafficEventLayer)
   // Be sure to place this traffic layer _below_ the route layers.
   useEffect(() => {
      if (map) {
         setTimeout(() => {
            if (map.getLayer(TrafficEventLayer_EventPositionLayerId) !== undefined) {
               if (tiLosProps.id !== undefined) {
                  map.moveLayer(tiLosProps.id, TrafficEventLayer_EventPositionLayerId)
               }
               if (tiIncidentProps.id !== undefined) {
                  map.moveLayer(tiIncidentProps.id, TrafficEventLayer_EventPositionLayerId)
               }
               if (debugModeEnabled && tiMetaDataProps.id !== undefined) {
                  map.moveLayer(tiMetaDataProps.id, TrafficEventLayer_EventPositionLayerId)
               }
            }
         }, 55)
      }
   }, [])
   // ------  Fix layer ordering ------

   return (
      <Fragment>
         <Source {...tiSourceProps}>
            <Layer {...tiLosProps}></Layer>
            <Layer {...tiIncidentProps}></Layer>
            {debugModeEnabled && <Layer {...tiMetaDataProps}></Layer>}
         </Source>
      </Fragment>
   )
}
