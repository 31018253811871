import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import SportsScoreIcon from "@mui/icons-material/SportsScore"
import AddLocationIcon from "@mui/icons-material/AddLocation"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import PriorityHighIcon from "@mui/icons-material/PriorityHigh"
import CloseIcon from "@mui/icons-material/Close"
import Delete from "@mui/icons-material/Delete"
import { LngLat, Popup, useMap } from "react-map-gl/maplibre"
import { BBox } from "@turf/helpers"
import useStore, { AppState, EditBlockage, UserBlockage } from "../../Store"
import { createUserBlockage } from "../routeDisplay/UserBlockageDisplay"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { toGeoJSON } from "@mapbox/polyline"
import { UserBlockageDisplay } from "../routeDisplay/UserBlockageDisplay"

export const LocationSelectPopup = () => {
   const { t } = useTranslation()
   const debugModeEnabled = useStore(store => store.debugModeEnabled)
   const props = useStore(state => state.locationSelectPopupProps)
   const updateProps = useStore(state => state.updateLocationSelectPopupProps)

   const dest = useStore(state => state.dest)

   const updateStart = useStore(state => state.updateStart)
   const updateWaypoint = useStore(state => state.updateWaypoint)
   const addWaypoint = useStore(state => state.addWaypoint)
   const removeWaypoint = useStore(state => state.removeWaypoint)
   const routeResult = useStore(s => s.routeResult)
   const activeRouteId = useStore(s => s.activeRouteId)
   const userBlockages = useStore(s => s.userBlockages)
   const mapEditStatus = useStore(s => s.mapEditStatus)
   const updateStore = useStore(s => s.update)
   const { current: map } = useMap()

   const activeRoute = routeResult?.routes[activeRouteId]
   const bb = map?.getBounds()
   const mapBounds: BBox | undefined =
      bb !== undefined ? [bb?._sw.lng, bb?._sw.lat, bb?._ne.lng, bb?._ne.lat] : undefined

   return (
      <Popup
         longitude={props.pos.lon}
         latitude={props.pos.lat}
         closeButton={false}
         offset={25}
         onClose={() => {
            updateStore(state => {
               state.mapEditStatus = undefined
            })
            updateProps({ ...props, show: false })
         }}
         closeOnClick={true}
      >
         <Paper
            sx={{
               m: -2, // Fancy hack, so I don't have to change CSS props of the Popup component.
            }}
         >
            {/*<Typography align="center">{`${showPopup.lat.toFixed(5)}, ${showPopup.lng.toFixed(5)}`}</Typography>*/}
            {/*<Divider/>*/}

            {props.waypointIdx === undefined && mapEditStatus === undefined && (
               <List>
                  <ListItem disablePadding key="popup-start">
                     <ListItemButton
                        onClick={e => {
                           updateStart(props.pos)
                           updateProps({ ...props, show: false })
                        }}
                     >
                        <ListItemIcon>
                           <Box
                              display={"flex"}
                              sx={{
                                 "width": 32,
                                 "height": 32,
                                 "borderRadius": 4,
                                 "border": 1,
                                 "borderColor": "primary.dark",
                                 "backgroundColor": "white",
                                 "&:hover": {
                                    backgroundColor: "primary.main",
                                    opacity: [0.9, 0.8, 0.7],
                                 },
                              }}
                              justifyContent={"center"}
                              alignItems={"center"}
                           >
                              <LocationOnIcon />
                           </Box>
                        </ListItemIcon>
                        <ListItemText secondary={t("LocationSelectPopup_SetStart")} />
                     </ListItemButton>
                  </ListItem>
                  {dest.map((wp, idx) => (
                     <ListItem disablePadding key={`popup-entry-${idx}`}>
                        <ListItemButton
                           onClick={e => {
                              updateWaypoint(props.pos, undefined, idx)
                              updateProps({ ...props, show: false })
                           }}
                        >
                           <ListItemIcon>
                              <Box
                                 display={"flex"}
                                 sx={{
                                    "width": 32,
                                    "height": 32,
                                    "borderRadius": 4,
                                    "border": 1,
                                    "borderColor": "primary.dark",
                                    "backgroundColor": "white",
                                    "&:hover": {
                                       backgroundColor: "primary.main",
                                       opacity: [0.9, 0.8, 0.7],
                                    },
                                 }}
                                 justifyContent={"center"}
                                 alignItems={"center"}
                              >
                                 {idx < dest.length - 1 ? `${idx + 1}` : <SportsScoreIcon />}
                              </Box>
                           </ListItemIcon>
                           <ListItemText
                              secondary={
                                 idx < dest.length - 1
                                    ? `Set ${idx + 1}. ${t("LocationSelectPopup_SetWaypoint")}`
                                    : `${t("LocationSelectPopup_SetDestination")}`
                              }
                           />
                        </ListItemButton>
                     </ListItem>
                  ))}
                  {dest.length && dest[dest.length - 1].textInput !== "" && (
                     <ListItem disablePadding key="popup-entry-add">
                        <ListItemButton
                           onClick={e => {
                              addWaypoint(props.pos)
                              updateProps({ ...props, show: false })
                           }}
                        >
                           <ListItemIcon>
                              <AddLocationIcon />
                           </ListItemIcon>
                           <ListItemText secondary={t("LocationSelectPopup_AddDestination")} />
                        </ListItemButton>
                     </ListItem>
                  )}
                  {
                     <ListItem disablePadding key="popup-entry-add-blockage">
                        <ListItemButton
                           disabled={activeRoute == null}
                           onClick={e => {
                              if (mapBounds)
                                 createUserBlockage(updateStore, activeRoute, [props.pos.lon, props.pos.lat], mapBounds)
                              updateProps({ ...props, show: false })
                           }}
                        >
                           <ListItemIcon>
                              <PriorityHighIcon />
                           </ListItemIcon>
                           <ListItemText secondary={t("LocationSelectPopup_AddUserBlockage")} />
                        </ListItemButton>
                     </ListItem>
                  }
                  {
                     <ListItem disablePadding key="popup-entry-remove-all-blockages">
                        <ListItemButton
                           disabled={userBlockages?.length === 0}
                           onClick={e => {
                              if (mapBounds)
                                 updateStore(state => {
                                    state.userBlockages = []
                                 })
                              updateProps({ ...props, show: false })
                           }}
                        >
                           <ListItemIcon>
                              <CloseIcon />
                           </ListItemIcon>
                           <ListItemText secondary={t("LocationSelectPopup_RemoveAllUserBlockages")} />
                        </ListItemButton>
                     </ListItem>
                  }
                  {debugModeEnabled && (
                     <ListItem disablePadding key="coords-to-clipboard">
                        <ListItemButton
                           onClick={e => {
                              navigator.clipboard.writeText(props.pos.lat.toString() + ", " + props.pos.lon.toString())
                              updateProps({ ...props, show: false })
                           }}
                        >
                           <ListItemIcon>
                              <ContentPasteIcon />
                           </ListItemIcon>
                           <ListItemText secondary={"coords to clipboard"} />
                        </ListItemButton>
                     </ListItem>
                  )}
               </List>
            )}
            {(mapEditStatus as EditBlockage)?.mode === "blockage-popup" && (
               <List>
                  <ListItem disablePadding key="popup-entry-remove-blockage">
                     <ListItemButton
                        onClick={e => {
                           updateStore(state => {
                              state.userBlockages = userBlockages.filter(
                                 b => b.id !== (mapEditStatus as EditBlockage)?.blockageId,
                              )
                              state.mapEditStatus = undefined
                           })
                           updateProps({ ...props, show: false })
                        }}
                     >
                        <ListItemIcon>
                           <CloseIcon />
                        </ListItemIcon>
                        <ListItemText secondary={t("LocationSelectPopup_RemoveUserBlockage")} />
                     </ListItemButton>
                  </ListItem>
               </List>
            )}
            {props.waypointIdx !== undefined && mapEditStatus === undefined && (
               <List>
                  <ListItem disablePadding key="popup-start">
                     <ListItemButton
                        onClick={e => {
                           removeWaypoint(props.waypointIdx)
                           updateProps({ ...props, show: false })
                        }}
                     >
                        <ListItemIcon>
                           <Box
                              display={"flex"}
                              sx={{
                                 "width": 32,
                                 "height": 32,
                                 "borderRadius": 4,
                                 "border": 1,
                                 "borderColor": "primary.dark",
                                 "backgroundColor": "white",
                                 "&:hover": {
                                    backgroundColor: "primary.main",
                                    opacity: [0.9, 0.8, 0.7],
                                 },
                              }}
                              justifyContent={"center"}
                              alignItems={"center"}
                           >
                              <Delete />
                           </Box>
                        </ListItemIcon>
                        <ListItemText secondary="Remove" />
                     </ListItemButton>
                  </ListItem>
               </List>
            )}
         </Paper>
      </Popup>
   )
}
