import { FunctionComponent, useEffect, useState } from "react"
import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   ListItem,
   ListItemAvatar,
   Stack,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import Box from "@mui/material/Box"
import DeleteIcon from "@mui/icons-material/Delete"
import { Charger, Supplier } from "../../../out/gen/openapi/ev"
import useStore from "../../Store"
import { triggerGetChargers } from "../../pages/RoutingPage"
import { List, ListItemText } from "@mui/material/"
import IconButton from "@mui/material/IconButton"
import MyLocationIcon from "@mui/icons-material/MyLocation"
import { useMap } from "react-map-gl/maplibre"
import Avatar from "@mui/material/Avatar"
import FavoriteIcon from "@mui/icons-material/Favorite"
import NotInterestedIcon from "@mui/icons-material/NotInterested"

export const PreferredChangers: FunctionComponent = () => {
   const { t } = useTranslation()
   const { current: map } = useMap()
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
   const [open, setOpen] = useState(false)

   const [chargerDetails, setChargerDetails] = useState([] as Charger[])
   const charging = useStore(store => store.charging)
   const updateSelectedCharger = useStore(state => state.updateSelectedCharger)
   const removeChargingFacilityPreference = useStore(store => store.removeChargingFacilityPreference)

   const handleClickOpen = () => {
      // setSelectedSuppliers([...props.activeSuppliers])
      setOpen(true)
   }

   const handleClose = (isOk: boolean) => {
      setOpen(false)
   }

   const handleGoto = (it: Charger) => {
      if (it.position !== undefined) {
         setOpen(false)
         updateSelectedCharger({
            stopover: undefined,
            charger: it,
         })
         map?.flyTo({
            center: [it.position.lon, it.position.lat],
            zoom: 14,
         })
      }
   }

   const handleDelete = (it: Charger) => {
      removeChargingFacilityPreference(it.id)
   }

   useEffect(() => {
      // -----------------------------------------------------------------------------------------
      // Fetching chargers multiple times and at multiple places is bad design and this
      // is subject for optimization.
      // Probably, a caching charging-facility manager should be introduced.
      // -----------------------------------------------------------------------------------------
      console.log("PreferredChangers effect facility_preference changed")
      const ids = charging.facility_preference?.map(it => it.facility_id)
      if (ids !== undefined) {
         triggerGetChargers(ids)
            .then(res => setChargerDetails(res))
            .catch(err => console.error(err))
      }
   }, [charging.facility_preference])

   return (
      <Box sx={{ pt: 1 }}>
         <Button variant="outlined" onClick={handleClickOpen} disabled={false}>
            {t("PreferredChargers_Title")}
         </Button>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby={t("PreferredChargers_Title")}
         >
            <DialogTitle id="responsive-dialog-title">{t("PreferredChargers_Title")}</DialogTitle>
            <DialogContent>
               <List>
                  {chargerDetails.map(it => {
                     return (
                        <ListItem
                           secondaryAction={
                              <Stack direction={"row"} spacing={1}>
                                 <IconButton aria-label="goto" onClick={() => handleGoto(it)}>
                                    <MyLocationIcon />
                                 </IconButton>
                                 <IconButton aria-label="delete" onClick={() => handleDelete(it)}>
                                    <DeleteIcon />
                                 </IconButton>
                              </Stack>
                           }
                        >
                           <ListItemAvatar>
                              <Avatar>
                                 {charging.facility_preference
                                    ?.find(item => item.facility_id === it.id)
                                    ?.pref.match("preferred|mandatory") ? (
                                    <FavoriteIcon />
                                 ) : (
                                    <NotInterestedIcon />
                                 )}
                              </Avatar>
                           </ListItemAvatar>
                           <ListItemText primary={it.supplier} secondary={it.attributes[0].displayName} />
                        </ListItem>
                     )
                  })}
               </List>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => handleClose(false)} autoFocus>
                  {t("Generic_Close")}
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   )
}
