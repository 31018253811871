import { toGeoJSON } from "@mapbox/polyline"
import { point } from "@turf/helpers"
import along from "@turf/along"
import { lineString } from "@turf/helpers"
import length from "@turf/length"
import lineSliceAlong from "@turf/line-slice-along"
import { FeatureCollection } from "geojson"
import { FunctionComponent, useEffect } from "react"
import { Layer, LayerProps, Source, useMap } from "react-map-gl/maplibre"
import { hasPresentKey, isDefined, isPresent } from "ts-is-present"
import useStore from "../../Store"

//import RoadConstructionIconUrl from "../../../public/assets/images/RoadContruction.png"

import "../../images/OneCoreGo_Text.svg"

export const TrafficEventLayer_EventPositionLayerId = "TrafficEventLayer_EventPositionLayerId"
export const TrafficEventLayer_EventLineLayerId = "TrafficEventLayer_EventLineLayerId"

export const TrafficEventLayer: FunctionComponent = () => {
   const routeResult = useStore(state => state.routeResult)
   const activeRouteId = useStore(state => state.activeRouteId)
   const debugModeEnabled = useStore(state => state.debugModeEnabled)

   const { current: map } = useMap()

   useEffect(() => {
      if (map /*&& map.loaded()*/ && routeResult?.result.value === "Success") {
         setTimeout(() => {
            if (map.getLayer(TrafficEventLayer_EventPositionLayerId) !== undefined) {
               map.moveLayer(TrafficEventLayer_EventPositionLayerId)
            }
         }, 85)
      }
   }, [routeResult])

   function featureCollection(): FeatureCollection[] {
      const data =
         routeResult?.routes[activeRouteId].segments
            .flatMap(it => it.route_links)
            .filter(isDefined)
            .filter(hasPresentKey("polyline"))
            .filter(hasPresentKey("ti_messages"))
            .map(it => {
               const line = lineString(toGeoJSON(it.polyline.line).coordinates)
               const lineLengthMeter = length(line, { units: "meters" })

               return it.ti_messages
                  .filter(msg => msg.id !== 0) // ID zero for flow messages
                  .map(msg => {
                     const startMeter = msg.start * lineLengthMeter
                     const endMeter = msg.end * lineLengthMeter

                     // It's possible that the length is 0. This should not be but it's possible.
                     if (endMeter - startMeter > 0) {
                        const eventLineFeature = lineSliceAlong(line, startMeter, endMeter, { units: "meters" })

                        // Place icon at start of ti event along path
                        const eventPositionFeature = point(eventLineFeature.geometry.coordinates[0])

                        // Optionally place icon at the middle of the ti event's along the path
                        // const eventLineLength = length(eventLineFeature, { units: "meters" })
                        // const eventPositionFeature = along(eventLineFeature, eventLineLength / 2, { units: "meters" })

                        if (eventLineFeature.properties) eventLineFeature.properties["msgId"] = msg.id
                        if (eventPositionFeature.properties) eventPositionFeature.properties["msgId"] = msg.id
                        return {
                           ...msg,
                           startPositionFeature: eventPositionFeature,
                           lineSliceFeature: eventLineFeature,
                        }
                     } else {
                        const eventPositionFeature = along(line, startMeter, { units: "meters" })
                        if (eventPositionFeature.properties) eventPositionFeature.properties["msgId"] = msg.id
                        return {
                           ...msg,
                           startPositionFeature: eventPositionFeature,
                           lineSliceFeature: null,
                        }
                     }
                  })
            })
            .flat()
            .filter(isDefined) ?? []

      const startPositionFeatures = data
         ?.map(it => it.startPositionFeature)
         // filter consecutive entries with same id.
         .filter((val, i, arr) => i === 0 || arr[i - 1].id !== arr[i].id)

      const lineSliceFeatures = data?.map(it => it.lineSliceFeature).filter(isPresent)

      return [
         {
            type: "FeatureCollection",
            features: startPositionFeatures,
         },
         {
            type: "FeatureCollection",
            features: lineSliceFeatures,
         },
      ]
   }

   const eventLayerProps: LayerProps = {
      id: TrafficEventLayer_EventPositionLayerId,
      type: "symbol",
      layout: {
         "visibility": "visible",
         "icon-image": "Attention",
         "icon-size": 1.0,
         "icon-offset": [0, 0],
      },
   }

   const lineLayerProps: LayerProps = {
      id: TrafficEventLayer_EventLineLayerId,
      type: "line",
      layout: {
         visibility: debugModeEnabled ? "visible" : "none",
      },
      paint: {
         "line-dasharray": [7, 4],
         "line-width": 2,
         "line-gap-width": 7,
         "line-color": "red",
         "line-opacity": 1,
      },
   }

   const [eventPosition, eventLine] = featureCollection()
   return (
      <>
         <Source id="trafficEventPositions-source" type="geojson" data={eventPosition}>
            <Layer {...eventLayerProps} />
         </Source>

         <Source id="trafficEventLine-source" type="geojson" data={eventLine}>
            <Layer {...lineLayerProps} />
         </Source>
      </>
   )
}
