import { Configuration, DefaultApi as EvApi, Supplier } from "../../../out/gen/openapi/ev"

const evApi = new EvApi(
   new Configuration({
      apiKey: "AsYh7X0x3IaRqAIlpyJ4o85OQBR720UQ3SWGNuA1",
   }),
)
const unknown: Supplier = {}
let suppliers: Supplier[] = []

async function loadSuppliers() {
   if (suppliers.length === 0) {
      try {
         suppliers = (await evApi.suppliersGet()).data
      } catch (error) {
         console.error("error loading suppliers", error)
      }
   }
}
loadSuppliers()

export function chargerSupplierIdToSupplier(id?: number): Supplier {
   if (id !== undefined) {
      return suppliers.find(con => con.supplierId === id) ?? unknown
   } else {
      return unknown
   }
}

export function chargerSupplierNameToSupplier(name?: string): Supplier {
   if (name !== undefined) {
      return suppliers.find(con => con.name === name) ?? unknown
   } else {
      return unknown
   }
}

export function getSuppliers(): Supplier[] {
   return suppliers
}
