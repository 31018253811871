import AltRouteIcon from "@mui/icons-material/AltRoute"
import VehicleConfigIcon from "@mui/icons-material/DirectionsCar"
import SettingsIcon from "@mui/icons-material/Settings"
import UnfoldLessIcon from "@mui/icons-material/UnfoldLess"
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore"
import { TabContext, TabList } from "@mui/lab"
import TabPanel from "@mui/lab/TabPanel"
import { Box, Button, Collapse, Paper, Tab, Tabs } from "@mui/material"
import { FunctionComponent, useState } from "react"
import useStore from "../../Store"
import { theme } from "../../Theme"
import { AlternativeRoutes } from "../itinerary/AlternativeRoutes"
import { Itinerary } from "../itinerary/Itinerary"
import { VehicleConfigComponent } from "../vehicle/VehicleConfig"
import { VehicleInput } from "../vehicle/VehicleInput"
import { LocationsInputContainer } from "./LocationsInputContainer"

type RouteInputContainerProps = {
   // Could be handles by theme (from the store).
   routeColorActive: string
   routeColorInactive: string
}

type Tabs = "route" | "settings" | "vehicle"

export const RouteInputContainer: FunctionComponent<RouteInputContainerProps> = props => {
   const routeViewMode = useStore(state => state.routeViewMode)
   const routeResult = useStore(state => state.routeResult)
   const setRouteResult = useStore(state => state.setRouteResult)
   const [isExpanded, setIsExpanded] = useState(true)
   const [activeTab, setActiveTab] = useState<Tabs>("route")

   return (
      <Box
         sx={{
            display: "flex",
            position: "absolute",
            zIndex: 5,
            margin: { xs: 0, md: theme.spacing(1) },
            maxHeight: { xs: "100dvh", md: `calc(100% - ${theme.spacing(2)})` }, // 2 = marginTop + marginBottom
            maxWidth: { xs: "100vw", md: `calc(100% - ${theme.spacing(2)})` }, // 2 = marginTop + marginBottom
            overflow: "auto", // TODO: we should better set overflow for the individual components within the container but it's tricky to set correct height
            width: { xs: "100vw", md: "35em" },
            height: { xs: "100%", md: "auto" },
            flexDirection: "column",
            pointerEvents: "none",
            justifyContent: "space-between",
         }}
      >
         <Box
            sx={{
               display: routeViewMode === "alternatives" ? "block" : "none",
            }}
         >
            <Collapse in={isExpanded}>
               <Paper
                  elevation={3}
                  sx={{
                     flexDirection: "column",
                     padding: theme.spacing(2),
                     pointerEvents: "auto",
                  }}
               >
                  <TabContext value={activeTab}>
                     <TabList variant="fullWidth" onChange={(e, v) => setActiveTab(v)} sx={{ mb: 1 }}>
                        <Tab icon={<AltRouteIcon />} aria-label="route" value={"route"} />
                        <Tab icon={<SettingsIcon />} aria-label="settings" value={"settings"} />
                        <Tab icon={<VehicleConfigIcon />} aria-label="vehicle" value={"vehicle"} />
                     </TabList>
                     <TabPanel value="route" sx={{ p: 0 }}>
                        <LocationsInputContainer onRoute={route => setRouteResult(route)} />
                     </TabPanel>
                     <TabPanel value="settings" sx={{ p: 0 }}>
                        <VehicleInput />
                     </TabPanel>
                     <TabPanel value="vehicle" sx={{ p: 0 }}>
                        <VehicleConfigComponent />
                     </TabPanel>
                  </TabContext>
               </Paper>
            </Collapse>
         </Box>
         <Box
            sx={{
               display: routeViewMode === "alternatives" ? "flex" : "none",
               flexDirection: "column",
            }}
         >
            <Button
               onClick={() => setIsExpanded(!isExpanded)}
               color="primary"
               variant="contained"
               sx={{
                  display: { xs: routeViewMode === "alternatives" ? "block" : "none", md: "none" },
                  pointerEvents: "auto",
                  alignSelf: "center",
               }}
            >
               {isExpanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
            </Button>
            {routeResult && (
               <Collapse
                  in={isExpanded}
                  collapsedSize={"5vh"}
                  sx={{
                     pointerEvents: "auto",
                  }}
               >
                  <AlternativeRoutes
                     routes={routeResult?.routes ?? []}
                     activeColor={props.routeColorActive}
                     inactiveColor={props.routeColorInactive}
                  />
               </Collapse>
            )}
         </Box>
         <Box
            sx={{
               overflow: { xs: "scroll", md: "auto" },
               pointerEvents: "auto",
               justifySelf: "flex-start",
               display: routeViewMode === "details" ? "block" : "none",
               marginTop: "0px",
            }}
         >
            <Itinerary />
         </Box>
      </Box>
   )
}
