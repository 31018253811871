import { TerrainControl } from "maplibre-gl"
import { Fragment, FunctionComponent, useEffect } from "react"
import { Layer, Source, useMap } from "react-map-gl/maplibre"

export const TerrainLayer: FunctionComponent = () => {
   const { current: map } = useMap()

   useEffect(() => {
      if (map !== undefined) {
         const realmap = map.getMap()
         if (realmap.getTerrain() === null) {
            realmap.setTerrain({ source: "terrain" })
         }
      }
   }, [])

   useEffect(
      () => () => {
         if (map !== undefined) {
            const realmap = map.getMap()
            if (realmap.getTerrain() !== null) {
               realmap.setTerrain(null)
            }
         }
      },
      [],
   )

   return (
      <Fragment>
         <Layer
            id="hills"
            type="hillshade"
            source="terrain-hillshade"
            paint={{
               "hillshade-shadow-color": "#473B24",
            }}
         ></Layer>
      </Fragment>
   )
}
