import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material"
import { Chart, ChartData, ChartOptions } from "chart.js"
import "chart.js/auto"
import dragDataPlugin from "chartjs-plugin-dragdata"
import { isEqual } from "lodash"
import { FunctionComponent, useEffect, useRef, useState } from "react"
import { Line } from "react-chartjs-2"
import { ChargingCurveEntry } from "../../../out/gen/openapi/routeplanner"
import { useTranslation } from "react-i18next"

Chart.register(dragDataPlugin)

interface IChargingCurveComponentProps {
   currentChargingCurve: ChargingCurveEntry[]
   updateChargingCurve: (curve: ChargingCurveEntry[]) => void
}

let resetChart = false

export const ChargingCurveComponent: FunctionComponent<IChargingCurveComponentProps> = props => {
   const { t } = useTranslation()
   const [open, setOpen] = useState(false)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
   const chart = useRef<Chart<"line", number[], string>>(null)
   const [chartData, setChartData] = useState<ChartData<"line", number[], string>>({
      datasets: [],
   })

   const formatOptions: Intl.NumberFormatOptions = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "percent",
   }

   const formatter = new Intl.NumberFormat(undefined, formatOptions)

   const init = () => {
      return {
         labels: props.currentChargingCurve.map(c => formatter.format(c.soc)),
         datasets: [
            {
               data: props.currentChargingCurve.map(c => c.pow),
               label: "kw",
               // fill: true,
               tension: 0,
               borderWidth: 4,
               borderColor: theme.palette.secondary.main,
               // backgroundColor: theme.palette.secondary.dark,
               pointHitRadius: 25,
            },
         ],
      }
   }

   useEffect(() => {
      setChartData(init())
   }, [props.currentChargingCurve])

   const handleClickOpen = () => {
      setOpen(true)
      if (resetChart) {
         setChartData(init())
         resetChart = false
      }
   }

   const handleClose = (isOk: boolean) => {
      setOpen(false)
      const newCurve = chartData.datasets[0].data.map((kw, index) => {
         return {
            soc: props.currentChargingCurve[index].soc,
            pow: kw,
         }
      })
      if (isOk && !isEqual(props.currentChargingCurve, newCurve)) {
         props.updateChargingCurve(newCurve)
      } else {
         resetChart = true
      }
   }

   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   const plugins: any = {
      dragData: {
         round: 0,
         showTooltip: true,
         // onDragStart: function (e: any, datasetIndex: any, index: any, value: any) {
         //    // console.log(e, datasetIndex, index, value)
         // },
         // onDrag: (e: MouseEvent, datasetIndex: number, index: number, value: number) => {
         //    // e.target.style.cursor = "grabbing"
         //    // console.log(e, datasetIndex, index, value)
         // },
         // onDragEnd: (e: MouseEvent, datasetIndex: number, index: number, value: number) => {},
      },
   }

   const options: ChartOptions<"line"> = {
      animation: false,
      scales: {
         y: {
            min: 0,
            max: 350,
         },
      },
      onHover: e => {
         if (e.native !== null && e.native.target !== null) {
            const point = chart.current?.getElementsAtEventForMode(e.native, "nearest", { intersect: true }, false)
            if (point !== undefined && point.length > 0) {
               ;(e.native.target as HTMLElement).style.cursor = "grab"
            } else {
               ;(e.native.target as HTMLElement).style.cursor = "default"
            }
         }
      },
      plugins: plugins,
   }

   return (
      <Box sx={{ pt: 1 }}>
         <Button variant="outlined" onClick={handleClickOpen} disabled={props.currentChargingCurve.length === 0}>
            {t("ChargingCurve_Edit_Charging_Curve")}
         </Button>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby={t("ChargingCurve_Set_Charging_Curve")}
         >
            <DialogTitle id="responsive-dialog-title">{t("ChargingCurve_Set_Charging_Curve")}</DialogTitle>
            <DialogContent>
               <Box>
                  <Line ref={chart} options={options} data={chartData} plugins={[{ id: "dragData" }]} />
               </Box>
            </DialogContent>
            <DialogActions>
               <Button onClick={() => handleClose(false)} autoFocus>
                  {t("Generic_Cancel")}
               </Button>
               <Button onClick={() => handleClose(true)} autoFocus>
                  {t("Generic_Ok")}
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   )
}
