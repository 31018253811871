import OneCoreGo from "./OneCoreGo.svg"
import Attention from "./../../public/assets/roadsigns/vienna_convention/Attention.svg"

type CustomIcon = {
   src: string
   name: string
}

const CustomIcons: CustomIcon[] = [
   {
      src: OneCoreGo,
      name: "OneCoreGo",
   },
   {
      src: Attention,
      name: "Attention",
   },
]

export default CustomIcons
