import { Place } from "@aws-amplify/geo"
import { Autocomplete } from "@mui/material"
import TextField from "@mui/material/TextField"
import { Geo } from "aws-amplify"
import { throttle } from "lodash"
import distance from "@turf/distance"
import { FunctionComponent, useEffect, useMemo, useState } from "react"
import { useMap } from "react-map-gl/maplibre"
import { GeoPos } from "../../../out/gen/openapi/routeplanner"
import { Waypoint } from "../../Store"

interface PosSearchProperties {
   label: string
   positionInput: Waypoint
   updatePosition: (pos: GeoPos, place: Place) => void
   clearPosition: () => void
}

const MIN_TERM_LENGTH = 3

export const LocationSearch: FunctionComponent<PosSearchProperties> = props => {
   const [value, setValue] = useState<Place | string | null>(null)
   const [inputValue, setInputValue] = useState("")
   const [options, setOptions] = useState<readonly Place[]>([])
   const [blockUpdate, setBlockUpdate] = useState(true)
   const { current: map } = useMap()

   const searchByText = useMemo(
      () =>
         throttle(async (input: string) => {
            return await Geo.searchByText(input, {
               maxResults: 5,
               biasPosition: map ? [map.getCenter().lng, map.getCenter().lat] : undefined,
            })
         }, 200),
      [],
   )

   // Callback when the input position changes by the geocoding result from click-on-map
   useEffect(() => {
      if (props.positionInput.place) {
         setOptions([])
         setValue(props.positionInput.place)
      } else {
         setValue("")
      }
   }, [props.positionInput.place])

   useEffect(() => {
      if (inputValue.length < MIN_TERM_LENGTH) {
         setOptions([])
      } else {
         searchByText(inputValue)?.then(searchResult => {
            // TODO use AbortController to cancel potential pending request
            setOptions(searchResult)
         })
      }
   }, [inputValue])

   useEffect(() => {
      if (typeof value === "string") return
      const point = value?.geometry?.point
      const current = props.positionInput.pos
      // Skip update if block is active or undefined input given
      if (blockUpdate || !point) return
      // If we have an existing waypoint, check distance to the updated value. Issue update only if pos changed.
      if (current && distance(point, [current.lon, current.lat], { units: "meters" }) > 10) {
         props.updatePosition({ lon: point[0], lat: point[1] }, value)
      } else {
         props.updatePosition({ lon: point[0], lat: point[1] }, value)
      }
      setBlockUpdate(true)
   }, [value])

   return (
      <Autocomplete
         autoComplete={true}
         // "freeSolo" allows us to have a selected value that is not in the options-list.
         // This happens when the place is determined by geocoded when "click-on-map" rather than selecting FTS result.
         freeSolo={true}
         options={options}
         filterOptions={x => x}
         blurOnSelect={true}
         autoHighlight
         autoSelect
         renderInput={params => <TextField {...params} label={props.label} fullWidth />}
         onInputChange={(_, value, reason) => {
            if (reason === "input") {
               setBlockUpdate(false)
               setInputValue(value)
            }
         }}
         onChange={(_, value, reason, details) => {
            if ((reason === "selectOption" || reason === "blur") && typeof value !== "string") {
               setValue(value)
            }
            if (reason === "clear") {
               props.clearPosition()
            }
         }}
         value={value}
         isOptionEqualToValue={(option, value) => option.label === value.label}
         // includeInputInList={true}
         // loading={...}
         sx={{ minWidth: "35ch", flexGrow: "1" }}
      ></Autocomplete>
   )
}
