import SportsScoreIcon from "@mui/icons-material/SportsScore"
import { Box } from "@mui/material"
import { Fragment, FunctionComponent } from "react"
import { Marker } from "react-map-gl/maplibre"
import useStore from "../../Store"

interface WaypointMarkersProperties {}

export const WaypointMarkers: FunctionComponent<WaypointMarkersProperties> = props => {
   const dest = useStore(state => state.dest)
   const updateWaypoint = useStore(state => state.updateWaypoint)
   const updateLocationSelectPopupProps = useStore(state => state.updateLocationSelectPopupProps)
   return (
      <Fragment>
         {dest
            .filter(wp => wp.pos)
            .map((wp, idx) => {
               return (
                  <Marker
                     key={`wp-marker${idx}`}
                     latitude={wp.pos?.lat ?? 0}
                     longitude={wp.pos?.lon ?? 0}
                     draggable={true}
                     onDragEnd={e => updateWaypoint({ lat: e.lngLat.lat, lon: e.lngLat.lng }, undefined, idx)}
                  >
                     <Box
                        display={"flex"}
                        sx={{
                           "width": 32,
                           "height": 32,
                           "borderRadius": 4,
                           "border": 1,
                           "borderColor": "primary.dark",
                           "backgroundColor": "white",
                           "&:hover": {
                              backgroundColor: "primary.main",
                              opacity: [0.9, 0.8, 0.7],
                           },
                        }}
                        justifyContent={"center"}
                        alignItems={"center"}
                        onContextMenu={event => {
                           event.stopPropagation()
                           event.preventDefault()
                           if (dest.length > 1) {
                              updateLocationSelectPopupProps({
                                 show: true,
                                 pos: wp.pos ?? { lat: 0, lon: 0 },
                                 waypointIdx: idx,
                              })
                           }
                        }}
                     >
                        {idx < dest.length - 1 ? `${idx + 1}` : <SportsScoreIcon />}
                     </Box>
                  </Marker>
               )
            })}
      </Fragment>
   )
}
