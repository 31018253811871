import { Checkbox, FormControlLabel, FormGroup, Stack } from "@mui/material"
import { Fragment, FunctionComponent } from "react"
import useStore from "../Store"
import { theme } from "../Theme"

interface IMapLayerControlProperties {}

export const MapLayerControl: FunctionComponent<IMapLayerControlProperties> = props => {
   const trafficLayerEnabled = useStore(store => store.trafficLayerEnabled)
   const setTrafficLayerEnabled = useStore(store => store.setTrafficLayerEnabled)
   const chargerLayerEnabled = useStore(store => store.chargerLayerEnabled)
   const setChargerLayerEnabled = useStore(store => store.setChargerLayerEnabled)
   const terrainLayerEnabled = useStore(store => store.terrainLayerEnabled)
   const setTerrainLayerEnabled = useStore(store => store.setTerrainLayerEnabled)
   const tileBoundaryLayerEnabled = useStore(store => store.tileBoundaryLayerEnabled)
   const setTileBoundaryLayerEnabled = useStore(store => store.setTileBoundaryLayerEnabled)
   const collisionBoxesLayerEnabled = useStore(store => store.collisionBoxesLayerEnabled)
   const setCollisionBoxesLayerEnabled = useStore(store => store.setCollisionBoxesLayerEnabled)
   const overdrawInspectorLayerEnabled = useStore(store => store.overdrawInspectorLayerEnabled)
   const setOverdrawInspectorLayerEnabled = useStore(store => store.setOverdrawInspectorLayerEnabled)

   const debugModeEnabled = useStore(store => store.debugModeEnabled)

   return (
      <Fragment>
         <Stack
            direction="row"
            sx={{
               position: "absolute",
               bottom: 0,
               left: 0,
               padding: theme.spacing(2),
               margin: theme.spacing(2),
               zIndex: 1,
               backgroundColor: theme.palette.background.paper,
            }}
         >
            <FormGroup>
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={chargerLayerEnabled}
                        onChange={(_, checked) => setChargerLayerEnabled(checked)}
                     />
                  }
                  label="Charger"
               />
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={trafficLayerEnabled}
                        onChange={(_, checked) => setTrafficLayerEnabled(checked)}
                     />
                  }
                  label="Traffic"
               />
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={terrainLayerEnabled}
                        onChange={(_, checked) => setTerrainLayerEnabled(checked)}
                     />
                  }
                  label="3D-Terrain"
               />
               {debugModeEnabled && (
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={tileBoundaryLayerEnabled}
                           onChange={(_, checked) => setTileBoundaryLayerEnabled(checked)}
                        />
                     }
                     label="Tile Boundary"
                  />
               )}
               {debugModeEnabled && (
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={collisionBoxesLayerEnabled}
                           onChange={(_, checked) => setCollisionBoxesLayerEnabled(checked)}
                        />
                     }
                     label="Collision Boxes"
                  />
               )}
               {debugModeEnabled && (
                  <FormControlLabel
                     control={
                        <Checkbox
                           checked={overdrawInspectorLayerEnabled}
                           onChange={(_, checked) => setOverdrawInspectorLayerEnabled(checked)}
                        />
                     }
                     label="Overdraw Inspector"
                  />
               )}
            </FormGroup>
         </Stack>
      </Fragment>
   )
}
