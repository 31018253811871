import { Box, Tooltip } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { FunctionComponent, ReactNode } from "react"

type ComponentWithCheckCircleProps = {
   tooltip: string
   onClick: () => void
   isChecked: boolean
   component: ReactNode
}
export const ComponentWithCheckCircle: FunctionComponent<ComponentWithCheckCircleProps> = props => {
   return (
      <Tooltip title={props.tooltip}>
         <Box onClick={props.onClick}>
            {props.component}
            <CheckCircleIcon
               fontSize={"medium"}
               sx={{
                  ml: -1.5,
                  mb: -1,
                  width: "50%",
                  color: props.isChecked ? "rgb(71,236,71)" : "rgba(0,255,0,0)",
               }}
            />
         </Box>
      </Tooltip>
   )
}
