import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import BatteryCharging60Icon from "@mui/icons-material/BatteryCharging60"
import DangerousIcon from "@mui/icons-material/Dangerous"
import HourglassTopIcon from "@mui/icons-material/HourglassTop"
import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material"
import { Geo } from "aws-amplify"
import { isEmpty, last, sum } from "lodash"
import { Fragment, FunctionComponent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMap } from "react-map-gl/maplibre"
import { AutomaticStopover, Route, RouteSegment } from "../../../out/gen/openapi/routeplanner"
import { toRouteDistance } from "./distance"
import { toRouteDurationString } from "./time"

export function wpSegmentHumanDistance(segment: RouteSegment, route: Route) {
   let distanceOffset = 0
   if (!isEmpty(segment.automatic_stopovers)) {
      distanceOffset =
         route.travel_info.distance -
         (last(segment.automatic_stopovers)?.distance_to_end_of_route ?? route.travel_info.distance) -
         sum(route.segments.slice(0, route.segments.indexOf(segment)).map(s => s.travel_info.distance))
   }
   const segmentDistance = segment.travel_info.distance - distanceOffset
   return toRouteDistance(segmentDistance)
}

export function wpSegmentHumanDuration(segment: RouteSegment, route: Route) {
   let timeOffset = 0
   if (!isEmpty(segment.automatic_stopovers)) {
      const timeToLastStop =
         route.travel_info.time -
         (last(segment.automatic_stopovers)?.travel_time_to_end_of_route ?? route.travel_info.time)

      const prevSegmentTime =
         sum(route.segments.slice(0, route.segments.indexOf(segment)).map(s => s.travel_info.time)) +
         sum(
            route.segments
               ?.slice(0, route.segments.indexOf(segment))
               .flatMap(s => s.automatic_stopovers)
               .flatMap(as => as?.assumed_charging_time ?? 0),
         )

      const thisSegmentChargingTime = sum(segment.automatic_stopovers?.map(as => as.assumed_charging_time ?? 0))

      timeOffset = prevSegmentTime + thisSegmentChargingTime - timeToLastStop
   }

   const segmentDuration = segment.travel_info.time + timeOffset
   return toRouteDurationString(segmentDuration)
}

interface WaypointItemProperties {
   segment: RouteSegment
   route: Route
}

export const WaypointItem: FunctionComponent<WaypointItemProperties> = props => {
   const { t } = useTranslation()
   const [waypointLabelPrimary, setWaypointLabelPrimary] = useState<string>("")
   const [waypointLabelSecondary, setWaypointLabelSecondary] = useState<string>("")
   const segment = props.segment
   const route = props.route
   const { current: map } = useMap()

   let distanceOffset = 0
   if (!isEmpty(segment.automatic_stopovers)) {
      distanceOffset =
         route.travel_info.distance -
         (last(segment.automatic_stopovers)?.distance_to_end_of_route ?? route.travel_info.distance) -
         sum(route.segments.slice(0, route.segments.indexOf(segment)).map(s => s.travel_info.distance))
   }

   const segmentDistance = segment.travel_info.distance - distanceOffset

   let timeOffset = 0
   if (!isEmpty(segment.automatic_stopovers)) {
      const timeToLastStop =
         route.travel_info.time -
         (last(segment.automatic_stopovers)?.travel_time_to_end_of_route ?? route.travel_info.time)

      const prevSegmentTime =
         sum(route.segments.slice(0, route.segments.indexOf(segment)).map(s => s.travel_info.time)) +
         sum(
            route.segments
               ?.slice(0, route.segments.indexOf(segment))
               .flatMap(s => s.automatic_stopovers)
               .flatMap(as => as?.assumed_charging_time ?? 0),
         )

      const thisSegmentChargingTime = sum(segment.automatic_stopovers?.map(as => as.assumed_charging_time ?? 0))

      timeOffset = prevSegmentTime + thisSegmentChargingTime - timeToLastStop
   }

   const segmentDuration = segment.travel_info.time + timeOffset

   useEffect(() => {
      const load = async () => {
         const res = await Geo.searchByCoordinates([segment.end_location.lon, segment.end_location.lat], {
            maxResults: 1,
         })
         setWaypointLabelPrimary(
            `${t("Itinerary_Waypoint")}: ${res.street ?? res.municipality ?? res.region ?? res.country ?? ""}`,
         )
         setWaypointLabelSecondary(res.label ?? "")
      }
      load().catch(e => console.error(e))
   }, [segment])

   return (
      <Fragment>
         <ListItem disablePadding={true} dense={false}>
            <ListItemButton
               onClick={() => {
                  map?.flyTo({ center: [segment.end_location.lon, segment.end_location.lat], zoom: 14 })
               }}
            >
               {/*<ListItemIcon>
                  <FmdGoodIcon />
               </ListItemIcon>*/}
               <ListItemText primary={waypointLabelPrimary} secondary={waypointLabelSecondary} />
            </ListItemButton>
         </ListItem>
         {/*<Divider textAlign="right">{`${segmentHumanDuration} (${segmentHumanDistance})`}</Divider>*/}
      </Fragment>
   )
}

export function soSegmentHumanDistance(stopover: AutomaticStopover, distanceToEndPreviousStop: number) {
   let distanceToCharger = null
   if (stopover.distance_to_end_of_route !== undefined) {
      distanceToCharger = distanceToEndPreviousStop - stopover.distance_to_end_of_route
   }
   return distanceToCharger !== null ? toRouteDistance(distanceToCharger) : "unknown"
}

export function timeToEndEnterStopover(stopover: AutomaticStopover) {
   return stopover.travel_time_to_end_of_route
}

export function timeToEndLeaveStopover(stopover: AutomaticStopover) {
   return stopover.travel_time_to_end_of_route !== undefined && stopover.assumed_charging_time !== undefined
      ? stopover.travel_time_to_end_of_route - stopover.assumed_charging_time
      : undefined
}

// todo - expect travel_info.time to include charging times already
function segmentFullTime(segment: RouteSegment) {
   return segment.travel_info.time + sum(segment.automatic_stopovers?.map(so => so.assumed_charging_time))
}

export function timeToEndEnterSegment(route: Route, segmentIndex: number) {
   return sum(route.segments.slice(segmentIndex).map(seg => segmentFullTime(seg)))
}
export function timeToEndLeaveSegment(route: Route, segmentIndex: number) {
   return sum(route.segments.slice(segmentIndex + 1).map(seg => segmentFullTime(seg)))
}

export function timeToEndAfterLastSO(route: Route, segmentIndex: number) {
   const lastSO = route.segments[segmentIndex].automatic_stopovers?.findLast(so => true)
   return lastSO != null ? timeToEndLeaveStopover(lastSO) : timeToEndEnterSegment(route, segmentIndex)
}

export function timeSpawnHumanDuration(timeToEndFrom: number | undefined, timeToEndTo: number | undefined) {
   return timeToEndFrom !== undefined && timeToEndTo !== undefined
      ? toRouteDurationString(timeToEndFrom - timeToEndTo)
      : "unknown"
}

interface StopoverItemProperties {
   stopover: AutomaticStopover
   distanceToEndPreviousStop: number
   timeToEndPreviousStop: number
}

export const StopoverItem: FunctionComponent<StopoverItemProperties> = props => {
   const { current: map } = useMap()
   const [humanDistance, setHumanDistance] = useState("unknown")
   const [humanDuration, setHumanDuration] = useState("unknown")

   const formatOptions: Intl.NumberFormatOptions = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "percent",
   }

   useEffect(() => {
      let distanceToCharger = null
      if (props.stopover.distance_to_end_of_route !== undefined) {
         distanceToCharger = props.distanceToEndPreviousStop - props.stopover.distance_to_end_of_route
      }
      setHumanDistance(distanceToCharger !== null ? toRouteDistance(distanceToCharger) : "unknown")
   }, [props.distanceToEndPreviousStop, props.stopover.distance_to_end_of_route])

   useEffect(() => {
      let durationWithStop = null

      if (
         props.stopover.travel_time_to_end_of_route !== undefined &&
         props.stopover.assumed_charging_time !== undefined
      ) {
         durationWithStop = props.timeToEndPreviousStop - props.stopover.travel_time_to_end_of_route
      }
      setHumanDuration(durationWithStop !== null ? toRouteDurationString(durationWithStop) : "unknown")
   }, [props.stopover.travel_time_to_end_of_route, props.stopover.assumed_charging_time, props.timeToEndPreviousStop])

   return (
      <Fragment>
         <ListItem disablePadding={true} dense={false}>
            <ListItemButton
               onClick={() => {
                  const pos = props.stopover.position
                  if (pos) map?.flyTo({ center: [pos.lon, pos.lat], zoom: 14 })
               }}
            >
               <ListItemText
                  primary={props.stopover.display_name ?? "Charging Station"}
                  secondary={
                     <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        {props.stopover.reachability_by_energy === "NOT_REACHABLE" && (
                           <Stack direction="row" justifyContent="flex-start" alignItems="center">
                              <DangerousIcon fontSize="small" color="error" />
                              {props.stopover.reachability_by_energy}
                           </Stack>
                        )}
                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                           <HourglassTopIcon fontSize="small" />
                           {toRouteDurationString(props.stopover.assumed_charging_time ?? 0)}
                        </Stack>
                        <Stack direction="row" justifyContent="flex-start" alignItems="center">
                           <BatteryCharging60Icon />
                           {props.stopover.remaining_energy?.toLocaleString(undefined, formatOptions)}
                           <ArrowRightAltIcon />
                           {props.stopover.recommended_recharge_level?.toLocaleString(undefined, formatOptions)}
                        </Stack>
                     </Stack>
                  }
                  secondaryTypographyProps={{
                     component: "div",
                     marginTop: 1,
                  }}
               />
            </ListItemButton>
         </ListItem>
      </Fragment>
   )
}
