import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import EvStationIcon from "@mui/icons-material/EvStation"
import FmdGoodIcon from "@mui/icons-material/FmdGood"
import MoreTimeIcon from "@mui/icons-material/MoreTime"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import {
   Box,
   Divider,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Paper,
   Stack,
   Typography,
} from "@mui/material"
import { grey } from "@mui/material/colors"
import { Geo } from "aws-amplify"
import { last, sum } from "lodash"
import { Fragment, FunctionComponent, useEffect, useState } from "react"
import { useMap } from "react-map-gl/maplibre"
import { Maneuver } from "../../../out/gen/openapi/routeplanner"
import useStore from "../../Store"
import { theme } from "../../Theme"
import {
   StopoverItem,
   WaypointItem,
   soSegmentHumanDistance,
   timeToEndEnterSegment,
   timeToEndLeaveSegment,
   timeToEndEnterStopover,
   timeToEndLeaveStopover,
   timeToEndAfterLastSO,
   timeSpawnHumanDuration,
   wpSegmentHumanDistance,
   wpSegmentHumanDuration,
} from "./ItineraryItem"
import { RouteToNextWaypoint } from "./RouteInfo"
import { toRouteDistance } from "./distance"
import { toRouteDurationString } from "./time"
import { useTranslation } from "react-i18next"

interface ItineraryProperties {}

export const Itinerary: FunctionComponent<ItineraryProperties> = props => {
   const { t } = useTranslation()
   const start = useStore(state => state.start)
   const selectedRouteId = useStore(state => state.selectedRouteId)
   const routeViewMode = useStore(state => state.routeViewMode)
   const updateRouteViewMode = useStore(state => state.updateRouteViewMode)
   const routeResult = useStore(state => state.routeResult)

   const { current: map } = useMap()
   const zoomToManeuver = (m: Maneuver) => map?.flyTo({ center: [m.center_point.lon, m.center_point.lat], zoom: 18 })

   const distance = routeResult?.routes[selectedRouteId]?.travel_info.distance ?? -1
   const duration = routeResult?.routes[selectedRouteId]?.travel_info.time ?? -1
   const trafficDelay = routeResult?.routes[selectedRouteId]?.travel_info.delay ?? -1
   const hasRoadblock = (routeResult?.routes[selectedRouteId]?.number_traffic_blockings ?? -1) > 0

   const humanDistance = toRouteDistance(distance)
   const humanDuration = toRouteDurationString(duration)
   const humanTrafficDelay = toRouteDurationString(trafficDelay)
   const trafficDelayColor =
      trafficDelay / duration < 0.15
         ? theme.palette.success
         : trafficDelay / duration < 0.2
           ? theme.palette.warning
           : theme.palette.error

   const [from, setFrom] = useState<string>("")
   const [to, setTo] = useState<string>("")

   useEffect(() => {
      const load = async () => {
         const fromPos = start.pos
         const toPos = last(routeResult?.routes[selectedRouteId]?.segments)?.end_location
         const [fromRes, toRes] = await Promise.all([
            start.place ??
               (await Geo.searchByCoordinates([fromPos?.lon ?? 0, fromPos?.lat ?? 0], {
                  maxResults: 1,
               })),
            await Geo.searchByCoordinates([toPos?.lon ?? 0, toPos?.lat ?? 0], {
               maxResults: 1,
            }),
         ])
         setFrom(fromRes?.label ?? "")
         setTo(toRes?.label ?? "")
      }
      load().catch(e => console.error(e))
   }, [selectedRouteId])

   const totalTravelTime: number = routeResult?.routes[selectedRouteId]?.travel_info?.time ?? 0.0
   const totalTravelDistance: number = routeResult?.routes[selectedRouteId]?.travel_info?.distance ?? 0.0
   const startLocation = routeResult?.routes[0]?.start_location
   let gridLne = 0
   function incGridLine() {
      ++gridLne
      return null
   }
   function sxLocation() {
      return { gridColumn: 2, gridRowStart: 4 * gridLne + 1, gridRowEnd: 4 * gridLne + 4 }
   }
   function sxRoute() {
      return {
         gridColumn: 2,
         gridRow: 4 * gridLne + 4,
         padding: "10px",
         marginLeft: "8px",
         marginRight: "8px",
      }
   }
   function timeline() {
      return (
         <Box
            sx={{
               display: "flex",
               gridColumn: 1,
               gridRowStart: 4 * gridLne + 3,
               gridRowEnd: 4 * gridLne + 6,
               justifyContent: "center",
               width: "100%",
               padding: "10px",
            }}
         >
            <Box sx={{ width: "1px", height: "100%", background: theme.palette.primary.main }}></Box>
         </Box>
      )
   }

   return (
      <Paper
         elevation={3}
         sx={{
            marginTop: theme.spacing(1),
            padding: theme.spacing(1),
            display: routeViewMode === "details" ? undefined : "none",
         }}
      >
         <Box>
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "row",
               }}
            >
               <IconButton
                  onClick={() => {
                     updateRouteViewMode("alternatives")
                  }}
               >
                  <ArrowBackIcon />
               </IconButton>
               <Box sx={{ margin: 2 }}>
                  <Typography variant="body2">
                     {t("Itinerary_From")}: {from}
                  </Typography>
                  <Typography variant="body2">
                     {t("Itinerary_To")}: {to}
                  </Typography>
               </Box>
            </Box>
            <Divider variant="fullWidth" sx={{ m: 2 }} />
            <Stack spacing={theme.spacing(1)} alignItems={"start"} sx={{ ml: 2 }}>
               {duration > -1 && (
                  <Typography variant={"h6"}>
                     {t("Itinerary_TravelTime")} {humanDuration}
                  </Typography>
               )}
               {distance > -1 && (
                  <Typography variant={"subtitle2"} sx={{ color: grey[500] }}>
                     {t("Itinerary_TravelDistance")} {humanDistance}
                  </Typography>
               )}
               {trafficDelay > -1 && (
                  <Stack direction={"row"} spacing={theme.spacing(1)}>
                     <MoreTimeIcon fontSize={"small"} sx={{ color: trafficDelayColor.main }} />
                     <Typography variant={"subtitle2"} color={trafficDelayColor.main}>
                        {t("Itinerary_TrafficDelay")} {humanTrafficDelay}
                     </Typography>
                  </Stack>
               )}
               {hasRoadblock && (
                  <Stack spacing={theme.spacing(1)} direction={"row"}>
                     <WarningAmberIcon fontSize={"small"} sx={{ color: theme.palette.error.main }} />
                     <Typography variant={"subtitle2"} color={theme.palette.error.main}>
                        {t("Itinerary_Roadblocks")}
                     </Typography>
                  </Stack>
               )}
            </Stack>
            <Divider variant="fullWidth" sx={{ m: 2 }} />
            <List sx={{ display: "grid" }}>
               <FmdGoodIcon
                  sx={{
                     gridColumn: 1,
                     gridRow: 4 * gridLne + 2,
                     justifySelf: "center",
                     color: theme.palette.primary.main,
                  }}
               />
               <ListItem disablePadding={true} dense={false} sx={sxLocation()}>
                  <ListItemButton
                     onClick={() => {
                        if (startLocation) map?.flyTo({ center: [startLocation.lon, startLocation.lat], zoom: 14 })
                     }}
                  >
                     {/*<ListItemIcon>
                        <FmdGoodIcon />
                     </ListItemIcon>*/}
                     <ListItemText primary={`${t("Itinerary_Start")}: ${from}`} secondary={""} />
                  </ListItemButton>
               </ListItem>

               {routeResult?.routes[selectedRouteId]?.segments.map((rs, i, segments) => {
                  const distanceToEndPreviousWaypointIfHaveStopover =
                     rs.automatic_stopovers !== undefined
                        ? rs.automatic_stopovers[rs.automatic_stopovers.length - 1].distance_to_end_of_route
                        : undefined
                  const distanceToEndPreviousWaypoint =
                     distanceToEndPreviousWaypointIfHaveStopover ??
                     totalTravelDistance - sum(segments.slice(0, i).map(s => s.travel_info.distance))
                  return (
                     <Fragment key={`segment-route-${i}`}>
                        {rs.automatic_stopovers?.map((as, j, asArray) => {
                           const distanceToEndPreviousStop =
                              asArray[j - 1]?.distance_to_end_of_route ??
                              routeResult?.routes[selectedRouteId]?.travel_info.distance -
                                 sum(segments.slice(0, i).map(s => s.travel_info.distance))
                           const timeToEndLeavePreviousStop =
                              j > 0
                                 ? timeToEndLeaveStopover(asArray[j - 1])
                                 : timeToEndEnterSegment(routeResult?.routes[selectedRouteId], i)
                           return (
                              <Fragment key={`stopover-route-${i}-${j}`}>
                                 {timeline()}
                                 <Box sx={sxRoute()}>
                                    <RouteToNextWaypoint
                                       routeSegment={rs}
                                       stopoverIndex={j}
                                       totalTravelTime={totalTravelTime}
                                       distanceToEndPreviousStop={distanceToEndPreviousStop}
                                       onClickManeuver={zoomToManeuver}
                                       distance={soSegmentHumanDistance(as, distanceToEndPreviousStop)}
                                       duration={timeSpawnHumanDuration(
                                          timeToEndLeavePreviousStop,
                                          timeToEndEnterStopover(as),
                                       )}
                                    />
                                 </Box>
                                 {incGridLine()}
                                 <EvStationIcon
                                    sx={{
                                       gridColumn: 1,
                                       gridRow: 4 * gridLne + 2,
                                       justifySelf: "center",
                                       color: theme.palette.primary.main,
                                    }}
                                 />
                                 <Box key={`stopover-${i}-${j}`} sx={sxLocation()}>
                                    <StopoverItem
                                       key={`stopoverListItem-${i}-${j}`}
                                       stopover={as}
                                       distanceToEndPreviousStop={
                                          asArray[j - 1]?.distance_to_end_of_route ??
                                          routeResult?.routes[selectedRouteId]?.travel_info.distance -
                                             sum(segments.slice(0, i).map(s => s.travel_info.distance))
                                       }
                                       timeToEndPreviousStop={
                                          asArray[j - 1]?.travel_time_to_end_of_route ??
                                          routeResult?.routes[selectedRouteId]?.travel_info.time -
                                             sum(segments.slice(0, i).map(s => s.travel_info.time))
                                       }
                                    />
                                 </Box>
                              </Fragment>
                           )
                        })}
                        {timeline()}
                        <Box key={`waypoint-route${i}`} sx={sxRoute()}>
                           <RouteToNextWaypoint
                              routeSegment={rs}
                              stopoverIndex={undefined}
                              totalTravelTime={totalTravelTime}
                              distanceToEndPreviousStop={distanceToEndPreviousWaypoint}
                              onClickManeuver={zoomToManeuver}
                              distance={wpSegmentHumanDistance(rs, routeResult?.routes[selectedRouteId])}
                              duration={timeSpawnHumanDuration(
                                 timeToEndAfterLastSO(routeResult?.routes[selectedRouteId], i),
                                 timeToEndLeaveSegment(routeResult?.routes[selectedRouteId], i),
                              )}
                           />
                        </Box>
                        {incGridLine()}
                        <FmdGoodIcon
                           sx={{
                              gridColumn: 1,
                              gridRow: 4 * gridLne + 2,
                              justifySelf: "center",
                              color: theme.palette.primary.main,
                           }}
                        />
                        <Box key={`waypoint-${i}`} sx={sxLocation()}>
                           <WaypointItem segment={rs} route={routeResult?.routes[selectedRouteId]} />
                        </Box>
                     </Fragment>
                  )
               })}
            </List>
         </Box>
      </Paper>
   )
}
