import { Box, Divider, Typography } from "@mui/material"
import React, { FunctionComponent, useState } from "react"
import { toRouteDistance } from "./distance"
import { Maneuver, RouteSegment } from "../../../out/gen/openapi/routeplanner"
import { toRouteDurationString } from "./time"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import {
   Container,
   Icon,
   ArrowSVG as ArrowSVGBase,
   IArrowProps,
   Direction,
   ManeuverIcon,
   ManeuverIconIDs,
} from "@na/motsbase"

import "@na/motsbase/dist/gen/motsbase.css"
import { grey } from "@mui/material/colors"

function ArrowSVG(props: IArrowProps) {
   const chgProps: IArrowProps = {
      ...props,
      thickness: 4,
      arrowWidth: 7,

      frontArrowColor: grey[900],
      altArrowColor: grey[400],
      backArrowColor: grey[400],
   }
   return <ArrowSVGBase {...chgProps} />
}

function ManeuverIconComp(maneuver: Required<Maneuver>) {
   //const info = this.props.info
   //if (info.man.ManeuverDetailsList.length === 0) return <Container />
   //const manType: GuidanceTypes.ManeuverType = info.man.ManeuverDetailsList[0].ManeuverLengthInfo.ManeuverType

   const manDirection =
      maneuver.maneuver_direction.toString() === "SLIGHT_LEFT "
         ? "SLIGHT_LEFT" // hack - remove when API is fixed
         : maneuver.maneuver_direction

   let arrow: ManeuverIconIDs | React.JSX.Element = "MT-invalid"

   function getDirection(maneuver: Required<Maneuver>) {
      return maneuver.maneuver_angle >= 0 ? maneuver.maneuver_angle : maneuver.maneuver_angle + 360.0
   }

   // determine maneuver type string for:
   // - directional maneuvers
   switch (maneuver.maneuver_type) {
      case "MT_CROSSROAD":
      case "MT_STRAIGHT_ON":
      case "MT_TURN":
         {
            const mainDir = getDirection(maneuver)

            if (manDirection === "UTURN_LEFT" || (manDirection === "HARD_LEFT" && mainDir <= 190)) {
               arrow = <ArrowSVG uturn={{ direction: Direction.Left, foreground: true }} />
            } else if (manDirection === "UTURN_RIGHT" || (manDirection === "HARD_RIGHT" && mainDir >= 170)) {
               arrow = <ArrowSVG uturn={{ direction: Direction.Right, foreground: true }} />
            } else {
               const alternatives: number[] = []
               if (
                  maneuver.route_symbol_list !== undefined &&
                  maneuver.route_symbol_list.length > 0 &&
                  maneuver.route_symbol_list[0].route_symbol !== undefined
               ) {
                  for (let rsi = 2; rsi < maneuver.route_symbol_list[0].route_symbol.length; ++rsi) {
                     const symbolElement = maneuver.route_symbol_list[0].route_symbol[rsi]
                     if (
                        symbolElement.role === "ER_SIDE_STREET" &&
                        symbolElement.direction !== undefined &&
                        symbolElement.direction >= 20 &&
                        symbolElement.direction <= 340 &&
                        (symbolElement.allowed === "BOTH_ALLOWED" || symbolElement.allowed === "EXIT_ALLOWED")
                     ) {
                        alternatives.push(symbolElement.direction - 180)
                     }
                  }
               }
               arrow = <ArrowSVG altArrow={alternatives} frontArrow={[mainDir]} />
            }
         }
         break

      case "MT_LEAVE_OFFROAD":
      case "MT_ENTER_OFFROAD":
         switch (manDirection) {
            case "LEFT":
               arrow = <ArrowSVG frontArrow={[-90]} />
               break
            case "SLIGHT_LEFT":
               arrow = <ArrowSVG frontArrow={[-45]} />
               break
            case "HARD_LEFT":
               arrow = <ArrowSVG frontArrow={[-135]} />
               break
            case "UTURN_LEFT":
               arrow = <ArrowSVG uturn={{ direction: Direction.Left, foreground: true }} />
               break
            case "RIGHT":
               arrow = <ArrowSVG frontArrow={[+90]} />
               break
            case "SLIGHT_RIGHT":
               arrow = <ArrowSVG frontArrow={[+45]} />
               break
            case "HARD_RIGHT":
               arrow = <ArrowSVG frontArrow={[+135]} />
               break
            case "UTURN_RIGHT":
               arrow = <ArrowSVG uturn={{ direction: Direction.Right, foreground: true }} />
               break
            case "STRAIGHT_ON":
               arrow = <ArrowSVG frontArrow={[0]} />
         }
         break
      case "MT_ROUNDABOUT":
      case "MT_ENTER_ROUNDABOUT":
      case "MT_LEAVE_ROUNDABOUT":
      case "MT_SQUARE":
         {
            const alternatives: number[] = []
            const manDir = getDirection(maneuver)
            const heading = manDir <= 180 ? manDir : manDir - 360
            if (
               maneuver.route_symbol_list !== undefined &&
               maneuver.route_symbol_list.length > 0 &&
               maneuver.route_symbol_list[0].route_symbol !== undefined
            ) {
               for (let rsi = 1; rsi < maneuver.route_symbol_list[0].route_symbol.length; ++rsi) {
                  const symbolElement = maneuver.route_symbol_list[0].route_symbol[rsi]
                  if (
                     symbolElement.role === "ER_SIDE_STREET" &&
                     symbolElement.direction !== undefined &&
                     symbolElement.direction >= 10 &&
                     symbolElement.direction <= 350 &&
                     (symbolElement.allowed === "BOTH_ALLOWED" || symbolElement.allowed === "EXIT_ALLOWED")
                  ) {
                     const dir = symbolElement.direction - 180
                     alternatives.push(dir)
                  }
               }
            }
            arrow = (
               <ArrowSVG
                  roundabout={{
                     direction: maneuver.driving_side === "LEFT" ? Direction.Left : Direction.Right,
                     heading: heading,
                     stubs: alternatives,
                  }}
               />
            )
         }
         break
      case "MT_U_TURN":
      case "MT_U_TURN_IF_POSSIBLE":
         switch (manDirection) {
            case "LEFT":
            case "UTURN_LEFT":
            case "SLIGHT_LEFT":
            case "HARD_LEFT":
               arrow = <ArrowSVG uturn={{ direction: Direction.Left, foreground: true }} />
               break
            case "RIGHT":
            case "UTURN_RIGHT":
            case "SLIGHT_RIGHT":
            case "HARD_RIGHT":
               arrow = <ArrowSVG uturn={{ direction: Direction.Right, foreground: true }} />
               break
            default:
               break
         }
         break
      case "MT_BIFURCATION":
      case "MT_HIGHWAY_BIFURCATION":
         switch (manDirection) {
            case "SLIGHT_LEFT":
            case "LEFT":
            case "HARD_LEFT":
               arrow = <ArrowSVG frontArrow={[-45]} backArrow={[0]} />
               break
            case "SLIGHT_RIGHT":
            case "RIGHT":
            case "HARD_RIGHT":
               arrow = <ArrowSVG frontArrow={[+45]} backArrow={[0]} />
               break
            case "STRAIGHT_ON":
               arrow = <ArrowSVG frontArrow={[0]} />
               break
            default:
               break
         }
         break
      case "MT_TRIFURCATION":
      case "MT_HIGHWAY_TRIFURCATION":
         switch (manDirection) {
            case "SLIGHT_LEFT":
            case "LEFT":
            case "HARD_LEFT":
               arrow = <ArrowSVG frontArrow={[-45]} backArrow={[0, +45]} />
               break
            case "SLIGHT_RIGHT":
            case "RIGHT":
            case "HARD_RIGHT":
               arrow = <ArrowSVG frontArrow={[+45]} backArrow={[0, -45]} />
               break
            case "STRAIGHT_ON":
               arrow = <ArrowSVG frontArrow={[0]} backArrow={[0, -45, +45]} />
               break
            default:
               break
         }
         break
      case "MT_EXIT":
      case "MT_HIGHWAY_EXIT":
      case "MT_PASS_SAPA_AREA":
      case "MT_PASS_SERVICE_AREA":
      case "MT_PASS_PARKING_AREA":
         switch (manDirection) {
            case "SLIGHT_LEFT":
            case "LEFT":
            case "HARD_LEFT":
               arrow = <ArrowSVG slightTurn={Direction.Left} />
               break
            case "SLIGHT_RIGHT":
            case "RIGHT":
            case "HARD_RIGHT":
               arrow = <ArrowSVG slightTurn={Direction.Right} />
               break
            case "STRAIGHT_ON":
               // does this make sense
               arrow = <ArrowSVG frontArrow={[0]} />
               break
            default:
               break
         }
         break
      case "MT_LANE_MERGE":
         switch (manDirection) {
            case "LEFT":
            case "SLIGHT_LEFT":
            case "HARD_LEFT":
               arrow = <ArrowSVG frontArrow={[0]} mergeStubs={[Direction.Right]} />
               break
            case "RIGHT":
            case "SLIGHT_RIGHT":
            case "HARD_RIGHT":
               arrow = <ArrowSVG frontArrow={[0]} mergeStubs={[Direction.Left]} />
               break
            case "STRAIGHT_ON":
               arrow = <ArrowSVG frontArrow={[0]} mergeStubs={[Direction.Left, Direction.Right]} />
               break
            default:
               break
         }
         break

      // - non-directional maneuvers
      case "MT_DESTINATION":
         if (
            maneuver.maneuver_properties !== undefined &&
            maneuver.maneuver_properties.findIndex(e => e === "PARKING_ROUTE") !== -1
         ) {
            arrow = "MT-enter-parking-route"
         } else {
            arrow = "MT-destination"
         }
         break
      case "MT_WAYPOINT":
         // if (false /*info.parkingSpot == null*/) {
         //    arrow = "MT-destination"
         // } else {
         arrow = "MT-enter-parking-route"
         // }
         break
      case "MT_ENTER_TOLL":
      case "MT_TOLL_BOOTH":
         arrow = "MT-enter-toll"
         break
      case "MT_ENTER_BORDER_CROSS":
         arrow = "MT-enter-border-cross"
         break
      case "MT_ENTER_CARTRAIN":
         arrow = "MT-enter-cartrain"
         break
      case "MT_ENTER_EXPRESS_LANE":
         arrow = "MT-enter-express-lane"
         break
      case "MT_LEAVE_EXPRESS_LANE":
         arrow = "MT-leave-express-lane"
         break
      case "MT_ENTER_FERRY":
         arrow = "MT-enter-ferry"
         break
      case "MT_HIGHWAY_ENTER":
         arrow = "MT-highway-enter"
         break
      case "MT_ENTER_HOV_LANE":
         arrow = "MT-enter-hov-lane"
         break
      case "MT_LEAVE_HOV_LANE":
         arrow = "MT-leave-hov-lane"
         break
      case "MT_ENTER_RAILWAY_CROSS":
         arrow = "MT-enter-railway-cross"
         break
      case "MT_ENTER_SCHOOLZONE":
         arrow = "MT-enter-schoolzone"
         break
      case "MT_ENTER_TIMEDOMAIN":
         arrow = "MT-enter-timedomain"
         break
      case "MT_ENTER_TUNNEL":
         arrow = "MT-enter-tunnel"
         break
      case "MT_ENTER_UNPAVED_ROAD":
         arrow = "MT-enter-unpaved-road"
         break
      case "MT_ENTER_VIGNETTE":
         arrow = "MT-enter-vignette"
         break
      case "MT_ENTER_IPD":
         arrow = "MT-enter-ipd"
         break
      case "MT_LEAVE_IPD":
         arrow = "MT-leave-ipd"
         break
      case "MT_ENTER_RESTRICTED":
         {
            arrow = "MT-enter-restricted"
         }
         break
      case "MT_TRANSPORTATION_CHANGE": {
         return <Container />
      }
      case "MT_ENTER_TRIP":
      case "MT_LEAVE_TRIP":
      case "MT_CHANGE_TRIP": {
         return <Container />
      }
      case "MT_CHARGING_STOPOVER":
         arrow = "MT-charging-stopover"
         break
      case "MT_ENTER_PARKING_ROUTE":
         arrow = "MT-enter-parking-route"
         break

      // - not yet assigned (show INVALID for now)
      case "MT_HIGHWAY_CHANGE_LANE":
      case "MT_START_ON_ROUNDABOUT":
      case "MT_TURN_AT_ROUNDABOUT":
      case "MT_TURN_BEFORE_ROUNDABOUT":
      case "MT_SILENT":
      case "MT_INVALID":
      default:
         arrow = <ArrowSVG unknown />
         break
   }

   // not generated, a maneuver icon
   if (typeof arrow === "string")
      return (
         <ManeuverIcon
            fit={true} // todo
            iconID={arrow}
            roadSignConvention={"Vienna"} // todo
            style={{ position: "static" }}
         />
      )

   // generated, an internal svg
   return (
      <Icon
         fit={true} // todo
         iconID="Transparent"
         style={{ color: "var(--col-arrow-route)", position: "static" }}
      >
         {arrow}
      </Icon>
   )
}

function ManeuverList(
   maneuvers: Maneuver[] | undefined,
   distanceToEndOfRouteBegin: number | undefined,
   distanceToEndOfRouteEnd: number | undefined,
   totalTravelTime: number,
   distanceToEndPreviousStop: number,
   onClick: (maneuver: Maneuver) => void,
) {
   if (maneuvers === undefined) return null

   function isInRange(distanceToEndOfRoute: number | undefined) {
      if (distanceToEndOfRoute === undefined) return false

      const isBehindBegin = distanceToEndOfRouteBegin === undefined || distanceToEndOfRoute < distanceToEndOfRouteBegin
      const isBeforeEnd = distanceToEndOfRouteEnd === undefined || distanceToEndOfRoute >= distanceToEndOfRouteEnd
      return isBehindBegin && isBeforeEnd
   }

   function turnToStreetName(maneuver: Maneuver) {
      if (maneuver.road_name_after_maneuver !== undefined && maneuver.road_number_after_maneuver !== undefined)
         return maneuver.road_name_after_maneuver + " " + maneuver.road_number_after_maneuver
      else if (maneuver.road_name_after_maneuver !== undefined) return maneuver.road_name_after_maneuver
      else if (maneuver.road_number_after_maneuver !== undefined) return maneuver.road_number_after_maneuver
      else return ""
   }

   function distanceToPrecedingManeuver(allManeuvers: Maneuver[], thisManeuverIndex: number) {
      if (thisManeuverIndex >= 0 && thisManeuverIndex < allManeuvers.length) {
         const distToDstThis = allManeuvers[thisManeuverIndex].distance_to_end_of_route

         if (thisManeuverIndex < 1) {
            if (distToDstThis !== undefined) return toRouteDistance(distanceToEndPreviousStop - distToDstThis)
         } else {
            const distToDstPred = allManeuvers[thisManeuverIndex - 1].distance_to_end_of_route

            if (distToDstThis !== undefined && distToDstPred !== undefined)
               return toRouteDistance(distToDstPred - distToDstThis)
         }
      }

      return ""
   }

   const timeFormat = new Intl.RelativeTimeFormat()

   function timeFromBeginningOfRoute(maneuver: Maneuver) {
      if (maneuver.travel_time_to_end_of_route === undefined) return ""

      const travelTime = Math.max(0.0, totalTravelTime - maneuver.travel_time_to_end_of_route)
      return toRouteDurationString(travelTime)
   }

   function ManeuverElements(maneuver: Required<Maneuver>, allManeuvers: Maneuver[], thisManeuverIndex: number) {
      return [
         <Box
            key={`ManeuverIcon-${thisManeuverIndex}`}
            sx={{
               "width": "40px",
               "height": "36px",
               "margin": "5px",
               "marginLeft": "0px",
               "gridColumn": 1,
               "&:hover": {
                  cursor: "pointer",
               },
            }}
            onClick={() => {
               onClick(maneuver)
            }}
         >
            {ManeuverIconComp(maneuver) /* todo want required in API */}
         </Box>,
         <Typography
            key={`Distance-${thisManeuverIndex}`}
            variant={"body2"}
            sx={{ minWidth: "20px", margin: "5px", gridColumn: 2, alignSelf: "center" }}
         >
            {distanceToPrecedingManeuver(allManeuvers, thisManeuverIndex)}
         </Typography>,
         <Typography
            key={`StreetName-${thisManeuverIndex}`}
            variant={"body2"}
            sx={{ gridColumn: 3, alignSelf: "center" }}
         >
            {turnToStreetName(maneuver)}
         </Typography>,

         //<Typography sx={{gridColumn: 4, alignSelf: "center"}}>
         //   {timeFromBeginningOfRoute(maneuver)}
         //</Typography>,

         //<Typography key= {`StreetName-${thisManeuverIndex}`} sx={{gridColumn:3, alignSelf: "center" }}>
         //   {maneuver.center_point.lat.toString() + ", " + maneuver.center_point.lon.toString()}
         //</Typography>,
      ]
   }

   const maneuverEntries = maneuvers
      .filter(m => isInRange(m.distance_to_end_of_route))
      .flatMap((mano, index, all) => ManeuverElements(mano as Required<Maneuver>, all, index))

   if (maneuverEntries.length === 0) return null

   return (
      <Box
         key="ManeuverList"
         sx={{
            display: "grid",
            gridTemplateColumns: "48px 80px auto",
         }}
      >
         {maneuverEntries}
      </Box>
   )
}

// stopoverIndex == undefined for waypoint
interface RouteToNextWaypointProps {
   routeSegment: RouteSegment
   stopoverIndex: number | undefined
   totalTravelTime: number
   distanceToEndPreviousStop: number
   onClickManeuver: (maneuver: Maneuver) => void
   distance: string
   duration: string
}

export const RouteToNextWaypoint: FunctionComponent<RouteToNextWaypointProps> = props => {
   const [showManeuvers, setShowManeuvers] = useState(false)

   const rs = props.routeSegment

   function distanceToEndOfRouteBegin() {
      if (props.stopoverIndex === undefined) {
         return rs.automatic_stopovers != null && rs.automatic_stopovers.length > 0
            ? rs.automatic_stopovers[rs.automatic_stopovers.length - 1].distance_to_end_of_route
            : undefined
      } else {
         return props.stopoverIndex > 0 && rs.automatic_stopovers != null
            ? rs.automatic_stopovers[props.stopoverIndex - 1].distance_to_end_of_route
            : undefined
      }
   }

   function distanceToEndOfRouteEnd() {
      if (props.stopoverIndex === undefined) {
         return undefined
      } else {
         return rs.automatic_stopovers != null
            ? rs.automatic_stopovers[props.stopoverIndex].distance_to_end_of_route
            : undefined
      }
   }

   const icon = showManeuvers ? (
      <ExpandLessIcon sx={{ flex: "0 0 auto", justifySelf: "end" }} onClick={e => setShowManeuvers(false)} />
   ) : (
      <ExpandMoreIcon sx={{ flex: "0 0 auto", justifySelf: "end" }} onClick={e => setShowManeuvers(true)} />
   )

   const maneuverList = ManeuverList(
      rs.maneuvers,
      distanceToEndOfRouteBegin(),
      distanceToEndOfRouteEnd(),
      props.totalTravelTime,
      props.distanceToEndPreviousStop,
      props.onClickManeuver,
   )

   return (
      <Box key={`routeToNextWaypoint-${distanceToEndOfRouteEnd()}`} sx={{ display: "flex", flexDirection: "row" }}>
         <Box sx={{ flex: "1 1 auto" }}>
            <Divider variant="fullWidth" sx={{ marginBottom: 2 }} />
            <Box sx={{ ml: 0, display: "flex", justifyContent: "end" }}>
               <Typography variant={"body2"} sx={{ flex: "1 1 10px" }}>
                  {props.duration} ({props.distance})
               </Typography>
               {maneuverList != null ? icon : null}
            </Box>
            {showManeuvers ? maneuverList : null}
            <Divider variant="fullWidth" sx={{ marginTop: 2 }} />
         </Box>
      </Box>
   )
}
