import { Box, FormControl, FormControlLabel, FormLabel, Switch } from "@mui/material"
import { useTranslation } from "react-i18next"
import useStore, { UpdateStore } from "../../Store"
import { SupplierSelectorComponent } from "./SupplierSelector"
import { chargerSupplierNameToSupplier, getSuppliers } from "../charger/SupplierHelper"
import { BatteryConfiguration } from "./BatteryConfiguration"
import { PreferredChangers } from "./PreferredChangers"
import { PreferenceType, RoadProperty, RoutePreference } from "../../../out/gen/openapi/routeplanner"

export const VehicleInput = () => {
   const { t } = useTranslation()
   const vehicle = useStore(store => store.vehicle)
   const routeCalculationSetting = useStore(state => state.routeCalculationSetting)
   const updateRouteCalculationSetting = useStore(state => state.updateRouteCalculationSetting)
   const trafficFlowEnabled = useStore(store => store.trafficFlowEnabled)
   const setTrafficFlowEnabled = useStore(store => store.setTrafficFlowEnabled)
   const debugModeEnabled = useStore(store => store.debugModeEnabled)
   const setDebugModeEnabled = useStore(store => store.setDebugModeEnabled)
   const charging = useStore(store => store.charging)
   const updateCharging = useStore(store => store.updateCharging)
   const updateStore = useStore(store => store.update)
   const routePreferences = useStore(s => s.routePreference)

   return (
      <Box>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel component={"legend"}>{t("VehicleInput_CalculationSetting")}</FormLabel>

            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ useTrafficRealtime: e.target.checked })
                     }}
                     checked={routeCalculationSetting.useTrafficRealtime}
                  />
               }
               label={t("VehicleInput_UseRTTI")}
            />
            {[RoadProperty.HIGHWAYS_MOTORWAYS, RoadProperty.TOLL_ROADS, RoadProperty.FERRY, RoadProperty.TUNNELS].map(
               roadProp => (
                  <FormControlLabel
                     control={
                        <Switch
                           onChange={e => {
                              updateStore(s => {
                                 s.routePreference = s.routePreference.filter(p => p.road_property !== roadProp)
                                 if (e.target.checked)
                                    s.routePreference.push({ road_property: roadProp, pref: PreferenceType.EXCLUDED })
                              })
                           }}
                           checked={routePreferences.some(
                              p => p.road_property === roadProp && p.pref === PreferenceType.EXCLUDED,
                           )}
                        />
                     }
                     label={t("Avoid_" + roadProp)}
                  />
               ),
            )}
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ wantManeuvers: e.target.checked })
                     }}
                     checked={routeCalculationSetting.wantManeuvers}
                  />
               }
               label={t("VehicleInput_WantManeuvers")}
            />
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ useAlternatives: e.target.checked })
                     }}
                     checked={routeCalculationSetting.useAlternatives}
                  />
               }
               label={t("VehicleInput_UseAlternatives")}
            />
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        setTrafficFlowEnabled(e.target.checked)
                     }}
                     checked={trafficFlowEnabled && routeCalculationSetting.withRouteLinks}
                     disabled={!routeCalculationSetting.withRouteLinks}
                  />
               }
               label={t("VehicleInput_TrafficFlow")}
            />

            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        updateRouteCalculationSetting({ useChAlgo: e.target.checked })
                     }}
                     checked={routeCalculationSetting.useChAlgo}
                  />
               }
               label={t("VehicleInput_UseCH")}
            />
            <FormControlLabel
               control={
                  <Switch
                     onChange={e => {
                        setDebugModeEnabled(e.target.checked)
                     }}
                     checked={debugModeEnabled}
                  />
               }
               label={t("VehicleInput_DevMode")}
            />
            {debugModeEnabled && (
               <FormControlLabel
                  control={
                     <Switch
                        onChange={e => {
                           updateRouteCalculationSetting({ withRouteLinks: e.target.checked })
                        }}
                        checked={routeCalculationSetting.withRouteLinks}
                     />
                  }
                  label={t("VehicleInput_WithRouteLinks")}
               />
            )}
         </FormControl>

         <Box sx={{ display: "EV" === vehicle.type ? "inline" : "none" }}>
            <BatteryConfiguration />
         </Box>
         <SupplierSelectorComponent
            activeSuppliers={
               charging.provider_preference?.map(sup => chargerSupplierNameToSupplier(sup.provider)) ?? []
            }
            disabled={false}
            suppliers={getSuppliers()}
            updateSuppliers={sup =>
               updateCharging({
                  provider_preference: sup.map(sup => {
                     return {
                        pref: "preferred",
                        provider: sup.name ?? "",
                     }
                  }),
               })
            }
         />
         <PreferredChangers />
      </Box>
   )
}
