/* tslint:disable */
/* eslint-disable */
/**
 * Route Planning API
 * API for online route planning with Neusoft One Core.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Automatically inserted stopover (i.e. a charging station)
 * @export
 * @interface AutomaticStopover
 */
export interface AutomaticStopover {
    /**
     * The facility ID if this stopover is a ev charging station. Use the ID with the ev-api to query for details on this facility.
     * @type {number}
     * @memberof AutomaticStopover
     */
    'facility_id'?: number;
    /**
     * Name of the stopover that can be displayed to the user
     * @type {string}
     * @memberof AutomaticStopover
     */
    'display_name'?: string;
    /**
     * 
     * @type {GeoPos}
     * @memberof AutomaticStopover
     */
    'position'?: GeoPos;
    /**
     * 
     * @type {ReachabilityByEnergy}
     * @memberof AutomaticStopover
     */
    'reachability_by_energy'?: ReachabilityByEnergy;
    /**
     * 
     * @type {ReachabilityByOpeningHours}
     * @memberof AutomaticStopover
     */
    'reachability_by_opening_hours'?: ReachabilityByOpeningHours;
    /**
     * Expected remaining energy when the stopover is reached (as fraction of max_energy_kWh, values between 0.0 and 1.0)
     * @type {number}
     * @memberof AutomaticStopover
     */
    'remaining_energy'?: number;
    /**
     * Minimum energy level up to which the driver shall charge the battery at this stopover in order to reach the next charging facility or the destination (as fraction of max_energy_kWh)
     * @type {number}
     * @memberof AutomaticStopover
     */
    'minimum_recharge_level'?: number;
    /**
     * Recommended energy level up to which the driver should charge the battery at this stopover as fraction of max_energy_kWh. This value may be > MinimumRechargeLevel when charging an additional amount of energy here would be faster than charging it at a subsequent charging stopover, and the total charging time along the route would be reduced.
     * @type {number}
     * @memberof AutomaticStopover
     */
    'recommended_recharge_level'?: number;
    /**
     * Estimated time that is required at this stopover for charging from expected remaining energy to RecommendedRechargeLevel. This will typically be a very rough estimation because technical data about charging station and vehicle characteristics are mostly not available in the required quality. The charging time is included in the travel time of the route.
     * @type {number}
     * @memberof AutomaticStopover
     */
    'assumed_charging_time'?: number;
    /**
     * Route length from this stopover to end of route, in meters.
     * @type {number}
     * @memberof AutomaticStopover
     */
    'distance_to_end_of_route'?: number;
    /**
     * Estimated travel time from this stopover to end of route, in seconds, including the assumed charging times of this and all subsequent automatic stopovers until the end of the route.
     * @type {number}
     * @memberof AutomaticStopover
     */
    'travel_time_to_end_of_route'?: number;
    /**
     * Energy consumption from waypoint to end of route, in kWh. This is the plain energy required for driving the route, not influenced by recharging. The \'other_devices_power_consumption_kW\' for the time spent at this (or a subsequent) stopover is not contained.
     * @type {number}
     * @memberof AutomaticStopover
     */
    'consumption_to_end_of_route_kWh'?: number;
}


/**
 * A rectangle box on the map given by the northeast (ne) and southwest (sw) coordinates.
 * @export
 * @interface BoundingBox
 */
export interface BoundingBox {
    /**
     * 
     * @type {GeoPos}
     * @memberof BoundingBox
     */
    'nw': GeoPos;
    /**
     * 
     * @type {GeoPos}
     * @memberof BoundingBox
     */
    'se': GeoPos;
}
/**
 * 
 * @export
 * @interface Charging
 */
export interface Charging {
    /**
     * maximum usable charging speed (Kilowatt), alternating/ current (0 = AC not usable)
     * @type {number}
     * @memberof Charging
     */
    'charge_power_ac': number;
    /**
     * maximum usable charging speed (Kilowatt), direct current (0 = DC not usable)
     * @type {number}
     * @memberof Charging
     */
    'charge_power_dc': number;
    /**
     * 
     * @type {Array<ProviderPreference>}
     * @memberof Charging
     */
    'provider_preference'?: Array<ProviderPreference>;
    /**
     * 
     * @type {Array<FacilityPreference>}
     * @memberof Charging
     */
    'facility_preference'?: Array<FacilityPreference>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Charging
     */
    'connectorId'?: Array<number>;
    /**
     * 
     * @type {Array<ChargingCurveEntry>}
     * @memberof Charging
     */
    'charging_curve': Array<ChargingCurveEntry>;
}
/**
 * State of charge to power mapping.
 * @export
 * @interface ChargingCurveEntry
 */
export interface ChargingCurveEntry {
    /**
     * Relative battery charging state (0.0 - 1.0).
     * @type {number}
     * @memberof ChargingCurveEntry
     */
    'soc': number;
    /**
     * Maximum usable electric power in kW.
     * @type {number}
     * @memberof ChargingCurveEntry
     */
    'pow': number;
}
/**
 * A speed consumption pair.
 * @export
 * @interface CscEntry
 */
export interface CscEntry {
    /**
     * The speed.
     * @type {number}
     * @memberof CscEntry
     */
    'sp': number;
    /**
     * The consumption in l/100Km if electric_drivetrain is false kWh/100Km if electric_drivetrain is true
     * @type {number}
     * @memberof CscEntry
     */
    'csm': number;
}
/**
 * Configure the energy status of the car.
 * @export
 * @interface EnergyStatus
 */
export interface EnergyStatus {
    /**
     * The maximum energy which the battery of the car can contain when 100% charged. This value is the de facto real-life possible maximal value which the battery can contain, not the nominal value.
     * @type {number}
     * @memberof EnergyStatus
     */
    'max_energy_kWh': number;
    /**
     * Available total remaining energy available in the battery of the car in kWh.
     * @type {number}
     * @memberof EnergyStatus
     */
    'remaining_energy_kWh': number;
    /**
     * Electric power consumption of all other (non-engine) in-car devices in use. For example air condition, heating, entertainment-system, electronic control units etc. ExtraConsumption_kWh = otherDevicesPowerConsumption_kW × duration If this consumption is not known, choose a statistical realistic average value to correctly reflect that energy drain, for example 0.8 kW. This value is only about consumers, not considering energy sources like recuperation or solar cells etc.
     * @type {number}
     * @memberof EnergyStatus
     */
    'other_devices_power_consumption_kW'?: number;
    /**
     * The maximum energy which the battery of the car should never be charged above. The value is in percent relative to the maximum energy of the battery as given in \'maxEnergy_kWh\'.
     * @type {number}
     * @memberof EnergyStatus
     */
    'max_energy_percent'?: number;
    /**
     * The minimum energy which the battery of the car should never fall below before reaching the final destination of the trip. The value is in percent relative to the maximum energy of the battery as given in \'maxEnergy_kWh\'.
     * @type {number}
     * @memberof EnergyStatus
     */
    'min_energy_at_destination_percent'?: number;
    /**
     * The minimum energy which the battery of the car should never fall below before reaching a charging station stop-over of the trip. The value is in percent relative to the maximum energy of the battery as given in \'maxEnergy_kWh\'.
     * @type {number}
     * @memberof EnergyStatus
     */
    'min_energy_at_stopover_percent'?: number;
}
/**
 * Specifies user preferences regarding individual charging facilities. * excluded - these charging stations will not be considered as stopover * preferred - no other station is used as long as an acceptable route using preferred facilities exists * mandatory - if mandatory facilities are given, only these stations are considered.
 * @export
 * @interface FacilityPreference
 */
export interface FacilityPreference {
    /**
     * 
     * @type {PreferenceType}
     * @memberof FacilityPreference
     */
    'pref': PreferenceType;
    /**
     * The facility ID of a specific ev charging station
     * @type {number}
     * @memberof FacilityPreference
     */
    'facility_id': number;
}


/**
 * A geoposition in WGS84 coordinates.
 * @export
 * @interface GeoPos
 */
export interface GeoPos {
    /**
     * Longitude parameter of a WGS84 coordinate.
     * @type {number}
     * @memberof GeoPos
     */
    'lon': number;
    /**
     * Latitude parameter of a WGS84 coordinate.
     * @type {number}
     * @memberof GeoPos
     */
    'lat': number;
}
/**
 * Recommended labeling position for a route
 * @export
 * @interface LabelingPosition
 */
export interface LabelingPosition {
    /**
     * 
     * @type {GeoPos}
     * @memberof LabelingPosition
     */
    'position'?: GeoPos;
}
/**
 * Information for lane recommendations and lane display rendering for a Maneuver
 * @export
 * @interface LaneInformation
 */
export interface LaneInformation {
    /**
     * 
     * @type {Array<LaneInformationLaneRecommendation>}
     * @memberof LaneInformation
     */
    'lane_recommendation'?: Array<LaneInformationLaneRecommendation>;
    /**
     * 
     * @type {LaneInformationLaneNumbersOnLink}
     * @memberof LaneInformation
     */
    'lane_numbers_on_link'?: LaneInformationLaneNumbersOnLink;
}
/**
 * 
 * @export
 * @interface LaneInformationLaneNumbersOnLink
 */
export interface LaneInformationLaneNumbersOnLink {
    /**
     * Lane number in the link in travel direction
     * @type {number}
     * @memberof LaneInformationLaneNumbersOnLink
     */
    'entrylink_lanenumber_forward'?: number;
    /**
     * Lane number in the link against travel direction
     * @type {number}
     * @memberof LaneInformationLaneNumbersOnLink
     */
    'entrylink_lanenumber_backward'?: number;
    /**
     * 
     * @type {number}
     * @memberof LaneInformationLaneNumbersOnLink
     */
    'exitlink_lanenumber_forward'?: number;
    /**
     * 
     * @type {number}
     * @memberof LaneInformationLaneNumbersOnLink
     */
    'exitlink_lanenumber_backward'?: number;
}
/**
 * (LaneRecomPoint in fidl). \"maneuver_phase\" omitted because OC always fills it with MP_INVALID anyway, and has a \"ToDo remove\".
 * @export
 * @interface LaneInformationLaneRecommendation
 */
export interface LaneInformationLaneRecommendation {
    /**
     * 
     * @type {number}
     * @memberof LaneInformationLaneRecommendation
     */
    'dist_to_maneuver'?: number;
    /**
     * List of information per lane
     * @type {Array<LaneRecommendation>}
     * @memberof LaneInformationLaneRecommendation
     */
    'lane_recom_info'?: Array<LaneRecommendation>;
}
/**
 * Lane recommendation level
 * @export
 * @enum {string}
 */

export const LaneRecomLevel = {
    UNCLASSIFIED: 'LR_UNCLASSIFIED',
    NOT_RECOMMENDED: 'LR_NOT_RECOMMENDED',
    RECOMMENDED: 'LR_RECOMMENDED',
    BEST_RECOMMENDED: 'LR_BEST_RECOMMENDED'
} as const;

export type LaneRecomLevel = typeof LaneRecomLevel[keyof typeof LaneRecomLevel];


/**
 * Describes a single lane (struct LaneRecom in fidl)
 * @export
 * @interface LaneRecommendation
 */
export interface LaneRecommendation {
    /**
     * lane divider type
     * @type {string}
     * @memberof LaneRecommendation
     */
    'lane_mark'?: LaneRecommendationLaneMarkEnum;
    /**
     * 
     * @type {LaneRecommendationLaneDetail}
     * @memberof LaneRecommendation
     */
    'lane_detail'?: LaneRecommendationLaneDetail;
}

export const LaneRecommendationLaneMarkEnum = {
    UNKNOWN: 'LM_UNKNOWN',
    ARROWS: 'LM_ARROWS',
    DOUBLE_SOLID: 'LM_DOUBLE_SOLID',
    SOLID: 'LM_SOLID',
    DASHED: 'LM_DASHED',
    PHYSICAL_DIVIDER: 'LM_PHYSICAL_DIVIDER',
    DOUBLE_DASHED: 'LM_DOUBLE_DASHED',
    SOLID_DASHED: 'LM_SOLID_DASHED',
    DASHED_SOLID: 'LM_DASHED_SOLID'
} as const;

export type LaneRecommendationLaneMarkEnum = typeof LaneRecommendationLaneMarkEnum[keyof typeof LaneRecommendationLaneMarkEnum];

/**
 * (struct LaneDetail in LaneTypes.fidl)
 * @export
 * @interface LaneRecommendationLaneDetail
 */
export interface LaneRecommendationLaneDetail {
    /**
     * 
     * @type {LaneRecomLevel}
     * @memberof LaneRecommendationLaneDetail
     */
    'lane_recom_level'?: LaneRecomLevel;
    /**
     * Lane status * LS_OPEN: Indicates the lane will be changed to multiple lanes in coming route. * LS_CLOSE: Indicates the lane together with other lanes will be changed to one lane in coming route. * LS_INCREASED: Indicates the lane is increased from previous lane. 
     * @type {string}
     * @memberof LaneRecommendationLaneDetail
     */
    'lane_status'?: LaneRecommendationLaneDetailLaneStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof LaneRecommendationLaneDetail
     */
    'lane_kinds'?: Array<LaneRecommendationLaneDetailLaneKindsEnum>;
    /**
     * List of direction arrows that are painted on the lane
     * @type {Array<LaneRecommendationLaneDetailLaneArrows>}
     * @memberof LaneRecommendationLaneDetail
     */
    'lane_arrows'?: Array<LaneRecommendationLaneDetailLaneArrows>;
}

export const LaneRecommendationLaneDetailLaneStatusEnum = {
    UNKNOWN: 'LS_UNKNOWN',
    NORMAL: 'LS_NORMAL',
    OPEN: 'LS_OPEN',
    CLOSE: 'LS_CLOSE',
    INCREASED: 'LS_INCREASED'
} as const;

export type LaneRecommendationLaneDetailLaneStatusEnum = typeof LaneRecommendationLaneDetailLaneStatusEnum[keyof typeof LaneRecommendationLaneDetailLaneStatusEnum];
export const LaneRecommendationLaneDetailLaneKindsEnum = {
    UNKNOWN: 'LK_UNKNOWN',
    HOV: 'LK_HOV',
    HOV_TOLL: 'LK_HOV_TOLL',
    EXPRESS: 'LK_EXPRESS',
    TAXI: 'LK_TAXI',
    BUS: 'LK_BUS',
    EMERGENCY_ONLY: 'LK_EMERGENCY_ONLY',
    REVERSIBLE: 'LK_REVERSIBLE',
    TIME_DEPENDENCY: 'LK_TIME_DEPENDENCY',
    TOLL: 'LK_TOLL',
    TOLL_CASH: 'LK_TOLL_CASH',
    TOLL_ETC: 'LK_TOLL_ETC',
    TOLL_CREDIT_CARD: 'LK_TOLL_CREDIT_CARD',
    VIGNETTE: 'LK_VIGNETTE'
} as const;

export type LaneRecommendationLaneDetailLaneKindsEnum = typeof LaneRecommendationLaneDetailLaneKindsEnum[keyof typeof LaneRecommendationLaneDetailLaneKindsEnum];

/**
 * (LaneArrow in LaneTypes.fidl)
 * @export
 * @interface LaneRecommendationLaneDetailLaneArrows
 */
export interface LaneRecommendationLaneDetailLaneArrows {
    /**
     * 
     * @type {string}
     * @memberof LaneRecommendationLaneDetailLaneArrows
     */
    'lane_direction'?: LaneRecommendationLaneDetailLaneArrowsLaneDirectionEnum;
    /**
     * 
     * @type {LaneRecomLevel}
     * @memberof LaneRecommendationLaneDetailLaneArrows
     */
    'lane_arrow_recom_level'?: LaneRecomLevel;
    /**
     * regulation for lane change to left (enum LCL_ALLOWED/LCL_PROHIBITED in fidl)
     * @type {boolean}
     * @memberof LaneRecommendationLaneDetailLaneArrows
     */
    'lane_change_left_allowed'?: boolean;
    /**
     * regulation for lane change to right (enum LCL_ALLOWED/LCL_PROHIBITED in fidl)
     * @type {boolean}
     * @memberof LaneRecommendationLaneDetailLaneArrows
     */
    'lane_change_right_allowed'?: boolean;
}

export const LaneRecommendationLaneDetailLaneArrowsLaneDirectionEnum = {
    UNKNOWN: 'LD_UNKNOWN',
    UTURN_LEFT: 'LD_UTURN_LEFT',
    UTURN_RIGHT: 'LD_UTURN_RIGHT',
    RIGHT_ONE: 'LD_RIGHT_ONE',
    RIGHT_TWO: 'LD_RIGHT_TWO',
    RIGHT_THREE: 'LD_RIGHT_THREE',
    AHEAD: 'LD_AHEAD',
    LEFT_THREE: 'LD_LEFT_THREE',
    LEFT_TWO: 'LD_LEFT_TWO',
    LEFT_ONE: 'LD_LEFT_ONE'
} as const;

export type LaneRecommendationLaneDetailLaneArrowsLaneDirectionEnum = typeof LaneRecommendationLaneDetailLaneArrowsLaneDirectionEnum[keyof typeof LaneRecommendationLaneDetailLaneArrowsLaneDirectionEnum];

/**
 * Define the setting for all language-dependent properties in the route result.
 * @export
 * @interface LanguageSetting
 */
export interface LanguageSetting {
    /**
     * ISO language code according to ISO 639-3 in three lower case characters.
     * @type {string}
     * @memberof LanguageSetting
     */
    'isoLanguageCode'?: string;
    /**
     * The ISO country code according to ISO-3166-1 alpha 3 code in three upper case characters.
     * @type {string}
     * @memberof LanguageSetting
     */
    'isoCountryCode'?: string;
    /**
     * List containing the allowable ISO script codes.
     * @type {Array<string>}
     * @memberof LanguageSetting
     */
    'isoScriptCode'?: Array<string>;
}
/**
 * A path on the map defined by a sequence of links.
 * @export
 * @interface LinkPath
 */
export interface LinkPath {
    /**
     * The links of the path. Ids may be from different map levels.
     * @type {Array<string>}
     * @memberof LinkPath
     */
    'linkIds': Array<string>;
    /**
     * A portion (at begin) of the first link is not part of the path.
     * @type {number}
     * @memberof LinkPath
     */
    'excludeBeginOfFirstLink'?: number;
    /**
     * Only the first portion of the last link belongs to the path.
     * @type {number}
     * @memberof LinkPath
     */
    'includeBeginOfLastLink'?: number;
}
/**
 * A maneuver along the route (combination of ManeuverMetaInfo, Maneuver, and ManeuverDetail in fidl) 
 * @export
 * @interface Maneuver
 */
export interface Maneuver {
    /**
     * 
     * @type {GeoPos}
     * @memberof Maneuver
     */
    'center_point': GeoPos;
    /**
     * 
     * @type {BoundingBox}
     * @memberof Maneuver
     */
    'bounding_rect'?: BoundingBox;
    /**
     * ID of the link before the maneuver
     * @type {string}
     * @memberof Maneuver
     */
    'entry_link_id'?: string;
    /**
     * ID of the link after the maneuver
     * @type {string}
     * @memberof Maneuver
     */
    'exit_link_id'?: string;
    /**
     * 
     * @type {ManeuverType}
     * @memberof Maneuver
     */
    'maneuver_type': ManeuverType;
    /**
     * List of properties (enum) of maneuvers to distinguish maneuvers from same type. * HIGHWAY_ENTRY - maneuver as a highway or motorway entry. * HIGHWAY_EXIT - maneuver where the route leaves highway to ramp, i.e. JCT position. * EXIT_AT_RAMP - maneuver where the route exits from ramp to general road behind highway. * CHARGING_STATION_WAYPOINT - maneuver where the charging station waypoint is. * HOME - maneuver where home is. * COMPANY - maneuver where company is. * PARKING_ROUTE - maneuver that is on the parking route path. 
     * @type {Array<string>}
     * @memberof Maneuver
     */
    'maneuver_properties'?: Array<ManeuverManeuverPropertiesEnum>;
    /**
     * Maneuver length between entry link to exit link, in meters
     * @type {number}
     * @memberof Maneuver
     */
    'maneuver_length'?: number;
    /**
     * Maneuver change angle, in degree (negative values = left turn)
     * @type {number}
     * @memberof Maneuver
     */
    'maneuver_angle'?: number;
    /**
     * Indicates combination with subsequent maneuver in acoustic output
     * @type {boolean}
     * @memberof Maneuver
     */
    'combined'?: boolean;
    /**
     * (RouteSymbolInfoList in fidl)
     * @type {Array<ManeuverRouteSymbolList>}
     * @memberof Maneuver
     */
    'route_symbol_list'?: Array<ManeuverRouteSymbolList>;
    /**
     * 
     * @type {LaneInformation}
     * @memberof Maneuver
     */
    'lane_information'?: LaneInformation;
    /**
     * Route length from this maneuver to end of route, in meters.
     * @type {number}
     * @memberof Maneuver
     */
    'distance_to_end_of_route'?: number;
    /**
     * Estimated travel time from this maneuver to end of route, in seconds
     * @type {number}
     * @memberof Maneuver
     */
    'travel_time_to_end_of_route'?: number;
    /**
     * distance from current maneuver to the previous maneuver (in meters). If the maneuver is the first one, it indicates the distance from this maneuver to the start of the route.
     * @type {number}
     * @memberof Maneuver
     */
    'maneuver_offset'?: number;
    /**
     * Road name of entry to maneuver
     * @type {string}
     * @memberof Maneuver
     */
    'intersection_name'?: string;
    /**
     * Additional guidance information, e.g. \"after the super market\"
     * @type {string}
     * @memberof Maneuver
     */
    'natural_guidance_text'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Maneuver
     */
    'has_traffic_light'?: boolean;
    /**
     * road number after the maneuver. If a road has multiple road numbers, they will be separated by slashes (\'/\') and combined into one string
     * @type {string}
     * @memberof Maneuver
     */
    'road_number_after_maneuver'?: string;
    /**
     * the name of the road after the maneuver
     * @type {string}
     * @memberof Maneuver
     */
    'road_name_after_maneuver'?: string;
    /**
     * list of enum(TOLL_ROADS, ...) Properties of the road after the maneuver point.
     * @type {Array<RoadProperty>}
     * @memberof Maneuver
     */
    'road_properties_after_maneuver'?: Array<RoadProperty>;
    /**
     * list of enum(TOLL_ROADS, ...) Properties of the road in front of the maneuver point.
     * @type {Array<RoadProperty>}
     * @memberof Maneuver
     */
    'road_properties_before_maneuver'?: Array<RoadProperty>;
    /**
     * Left or right hand driving
     * @type {string}
     * @memberof Maneuver
     */
    'driving_side'?: ManeuverDrivingSideEnum;
    /**
     * Turn direction of the maneuver
     * @type {string}
     * @memberof Maneuver
     */
    'maneuver_direction'?: ManeuverManeuverDirectionEnum;
    /**
     * Exit counter number for complex maneuver (roundabout, trafficsquare...)
     * @type {number}
     * @memberof Maneuver
     */
    'exit_number'?: number;
}

export const ManeuverManeuverPropertiesEnum = {
    HIGHWAY_ENTRY: 'HIGHWAY_ENTRY',
    HIGHWAY_EXIT: 'HIGHWAY_EXIT',
    EXIT_AT_RAMP: 'EXIT_AT_RAMP',
    CHARGING_STATION_WAYPOINT: 'CHARGING_STATION_WAYPOINT',
    HOME: 'HOME',
    COMPANY: 'COMPANY',
    PARKING_ROUTE: 'PARKING_ROUTE'
} as const;

export type ManeuverManeuverPropertiesEnum = typeof ManeuverManeuverPropertiesEnum[keyof typeof ManeuverManeuverPropertiesEnum];
export const ManeuverDrivingSideEnum = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
} as const;

export type ManeuverDrivingSideEnum = typeof ManeuverDrivingSideEnum[keyof typeof ManeuverDrivingSideEnum];
export const ManeuverManeuverDirectionEnum = {
    INVALID: 'INVALID',
    STRAIGHT_ON: 'STRAIGHT_ON',
    LEFT: 'LEFT',
    SLIGHT_LEFT: 'SLIGHT_LEFT',
    HARD_LEFT: 'HARD_LEFT',
    RIGHT: 'RIGHT',
    SLIGHT_RIGHT: 'SLIGHT_RIGHT',
    HARD_RIGHT: 'HARD_RIGHT',
    UTURN_RIGHT: 'UTURN_RIGHT',
    UTURN_LEFT: 'UTURN_LEFT'
} as const;

export type ManeuverManeuverDirectionEnum = typeof ManeuverManeuverDirectionEnum[keyof typeof ManeuverManeuverDirectionEnum];

/**
 * Describing a passing state of the maneuver depending on the car position on the route. The phase is used to generate a proper driving recommendation for the current maneuver.
 * @export
 * @enum {string}
 */

export const ManeuverPhase = {
    INVALID: 'MP_INVALID',
    BEGIN: 'MP_BEGIN',
    PREPARE: 'MP_PREPARE',
    APPROACH: 'MP_APPROACH',
    ACTION: 'MP_ACTION',
    END: 'MP_END'
} as const;

export type ManeuverPhase = typeof ManeuverPhase[keyof typeof ManeuverPhase];


/**
 * 
 * @export
 * @interface ManeuverRouteSymbolList
 */
export interface ManeuverRouteSymbolList {
    /**
     * 
     * @type {ManeuverPhase}
     * @memberof ManeuverRouteSymbolList
     */
    'maneuver_phase'?: ManeuverPhase;
    /**
     * 
     * @type {Array<RouteSymbolInfo>}
     * @memberof ManeuverRouteSymbolList
     */
    'route_symbol'?: Array<RouteSymbolInfo>;
}


/**
 * General type of the maneuver * `MT_STRAIGHT_ON` - straight on maneuver. * `MT_TURN` - simple turn maneuver. * \'MT_U_TURN\' - u-turn maneuver 
 * @export
 * @enum {string}
 */

export const ManeuverType = {
    INVALID: 'MT_INVALID',
    STRAIGHT_ON: 'MT_STRAIGHT_ON',
    TURN: 'MT_TURN',
    U_TURN: 'MT_U_TURN',
    U_TURN_IF_POSSIBLE: 'MT_U_TURN_IF_POSSIBLE',
    CROSSROAD: 'MT_CROSSROAD',
    ROUNDABOUT: 'MT_ROUNDABOUT',
    SQUARE: 'MT_SQUARE',
    EXIT: 'MT_EXIT',
    BIFURCATION: 'MT_BIFURCATION',
    TRIFURCATION: 'MT_TRIFURCATION',
    HIGHWAY_ENTER: 'MT_HIGHWAY_ENTER',
    HIGHWAY_EXIT: 'MT_HIGHWAY_EXIT',
    HIGHWAY_BIFURCATION: 'MT_HIGHWAY_BIFURCATION',
    HIGHWAY_TRIFURCATION: 'MT_HIGHWAY_TRIFURCATION',
    HIGHWAY_CHANGE_LANE: 'MT_HIGHWAY_CHANGE_LANE',
    DESTINATION: 'MT_DESTINATION',
    WAYPOINT: 'MT_WAYPOINT',
    TURN_AT_ROUNDABOUT: 'MT_TURN_AT_ROUNDABOUT',
    TURN_BEFORE_ROUNDABOUT: 'MT_TURN_BEFORE_ROUNDABOUT',
    START_ON_ROUNDABOUT: 'MT_START_ON_ROUNDABOUT',
    ENTER_OFFROAD: 'MT_ENTER_OFFROAD',
    LEAVE_OFFROAD: 'MT_LEAVE_OFFROAD',
    ENTER_HOV_LANE: 'MT_ENTER_HOV_LANE',
    LEAVE_HOV_LANE: 'MT_LEAVE_HOV_LANE',
    ENTER_EXPRESS_LANE: 'MT_ENTER_EXPRESS_LANE',
    LEAVE_EXPRESS_LANE: 'MT_LEAVE_EXPRESS_LANE',
    ENTER_TOLL: 'MT_ENTER_TOLL',
    ENTER_VIGNETTE: 'MT_ENTER_VIGNETTE',
    ENTER_RESTRICTED: 'MT_ENTER_RESTRICTED',
    ENTER_TUNNEL: 'MT_ENTER_TUNNEL',
    ENTER_FERRY: 'MT_ENTER_FERRY',
    ENTER_RAILWAY_CROSS: 'MT_ENTER_RAILWAY_CROSS',
    ENTER_UNPAVED_ROAD: 'MT_ENTER_UNPAVED_ROAD',
    ENTER_CARTRAIN: 'MT_ENTER_CARTRAIN',
    ENTER_TIMEDOMAIN: 'MT_ENTER_TIMEDOMAIN',
    ENTER_SCHOOLZONE: 'MT_ENTER_SCHOOLZONE',
    ENTER_BORDER_CROSS: 'MT_ENTER_BORDER_CROSS',
    LANE_MERGE: 'MT_LANE_MERGE',
    TOLL_BOOTH: 'MT_TOLL_BOOTH',
    PASS_SAPA_AREA: 'MT_PASS_SAPA_AREA',
    PASS_SERVICE_AREA: 'MT_PASS_SERVICE_AREA',
    PASS_PARKING_AREA: 'MT_PASS_PARKING_AREA',
    SILENT: 'MT_SILENT',
    ENTER_IPD: 'MT_ENTER_IPD',
    LEAVE_IPD: 'MT_LEAVE_IPD',
    ENTER_ROUNDABOUT: 'MT_ENTER_ROUNDABOUT',
    LEAVE_ROUNDABOUT: 'MT_LEAVE_ROUNDABOUT',
    TRANSPORTATION_CHANGE: 'MT_TRANSPORTATION_CHANGE',
    ENTER_TRIP: 'MT_ENTER_TRIP',
    LEAVE_TRIP: 'MT_LEAVE_TRIP',
    CHANGE_TRIP: 'MT_CHANGE_TRIP',
    CHARGING_STOPOVER: 'MT_CHARGING_STOPOVER',
    ENTER_PARKING_ROUTE: 'MT_ENTER_PARKING_ROUTE'
} as const;

export type ManeuverType = typeof ManeuverType[keyof typeof ManeuverType];


/**
 * Road name or number along a RouteLink
 * @export
 * @interface NameOnLink
 */
export interface NameOnLink {
    /**
     * The actual name string
     * @type {string}
     * @memberof NameOnLink
     */
    'name': string;
    /**
     * Start of range on link where this name applies to (in meters from start point of link).
     * @type {number}
     * @memberof NameOnLink
     */
    'start'?: number;
    /**
     * Length of range on link where this name applies to (in meters). When left out, the named range reaches to the end of the link.
     * @type {number}
     * @memberof NameOnLink
     */
    'length'?: number;
}
/**
 * 
 * @export
 * @interface Polyline
 */
export interface Polyline {
    /**
     * A series of coordinates encoded into a string. See https://developers.google.com/maps/documentation/utilities/polylinealgorithm
     * @type {string}
     * @memberof Polyline
     */
    'line': string;
}
/**
 * Generic type to express preferences
 * @export
 * @enum {string}
 */

export const PreferenceType = {
    EXCLUDED: 'excluded',
    PREFERRED: 'preferred',
    MANDATORY: 'mandatory'
} as const;

export type PreferenceType = typeof PreferenceType[keyof typeof PreferenceType];


/**
 * With a list of provider preferences the selection of charging stations can be controlled * excluded - stations with (only) this provider will not be considered as stopover * preferred - no other station is used as long as an acceptable route using preferred providers exists * mandatory - if mandatory providers are given only stations of these providers are used. Mandatory providers can additional be preferred.
 * @export
 * @interface ProviderPreference
 */
export interface ProviderPreference {
    /**
     * 
     * @type {PreferenceType}
     * @memberof ProviderPreference
     */
    'pref': PreferenceType;
    /**
     * 
     * @type {string}
     * @memberof ProviderPreference
     */
    'provider': string;
}


/**
 * Indicates if a routeSegment or stopover can be reached with the available electric energy or fuel. * ENERGY_NOT_CONSIDERED - reachability was not checked (e.g. feature \'add charging stopovers\' was not requested, or used transportation means does not consume energy) * REACHABLE_BY_ENERGY - location can be reached with either initially available energy, or after the planned preceding recharging/refueling stop(s) * BELOW_ENERGY_RESERVE - location can probably be reached, but likely not considering the requested energy reserve * NOT_REACHABLE - vehicle will run off energy before reaching the location
 * @export
 * @enum {string}
 */

export const ReachabilityByEnergy = {
    ENERGY_NOT_CONSIDERED: 'ENERGY_NOT_CONSIDERED',
    REACHABLE_BY_ENERGY: 'REACHABLE_BY_ENERGY',
    BELOW_ENERGY_RESERVE: 'BELOW_ENERGY_RESERVE',
    NOT_REACHABLE: 'NOT_REACHABLE'
} as const;

export type ReachabilityByEnergy = typeof ReachabilityByEnergy[keyof typeof ReachabilityByEnergy];


/**
 * Indicates if a stopover or facility can be reached in time during its opening hours. * OPENING_HOURS_NOT_CONSIDERED - time limitiations were not checked (e.g. starting time not given) * UNLIMITED_OPENING_HOURS - the facility is known to be available 24hrs/7days, so there will never be a problem * REACHABLE_IN_TIME - according to the ETA estimation, the facility will be reached during its opening hours * OPENING_HOURS_UNKNOWN - no opening hours information available for this facility * EARLY_ARRIVAL - expected arrival will be within a certain time span before the facility will open, user will probably have to waiting for some - acceptable - time * LATE_ARRIVAL - expected arrival time plus estimated charging time will be close to, or even slightly beyond, the closing time of the facility * OUTSIDE_OPENING_HOURS - expected arrival time, or end of expected charging time, is clearly outside the opening hours
 * @export
 * @enum {string}
 */

export const ReachabilityByOpeningHours = {
    OPENING_HOURS_NOT_CONSIDERED: 'OPENING_HOURS_NOT_CONSIDERED',
    UNLIMITED_OPENING_HOURS: 'UNLIMITED_OPENING_HOURS',
    REACHABLE_IN_TIME: 'REACHABLE_IN_TIME',
    OPENING_HOURS_UNKNOWN: 'OPENING_HOURS_UNKNOWN',
    EARLY_ARRIVAL: 'EARLY_ARRIVAL',
    LATE_ARRIVAL: 'LATE_ARRIVAL',
    OUTSIDE_OPENING_HOURS: 'OUTSIDE_OPENING_HOURS'
} as const;

export type ReachabilityByOpeningHours = typeof ReachabilityByOpeningHours[keyof typeof ReachabilityByOpeningHours];


/**
 * Attributes of a road before or after a maneuver
 * @export
 * @enum {string}
 */

export const RoadProperty = {
    FERRY: 'FERRY',
    TOLL_ROADS: 'TOLL_ROADS',
    TUNNELS: 'TUNNELS',
    HIGHWAYS_MOTORWAYS: 'HIGHWAYS_MOTORWAYS',
    VEHICLE_SIZE_LIMIT: 'VEHICLE_SIZE_LIMIT',
    CRIME_AREAS: 'CRIME_AREAS',
    MAIN_MOTORWAY: 'MAIN_MOTORWAY',
    NARROW_ROAD: 'NARROW_ROAD',
    RAMP: 'RAMP',
    UNPAVED: 'UNPAVED',
    OFF_ROAD: 'OFF_ROAD',
    CARTRAIN: 'CARTRAIN',
    VIGNETTE: 'VIGNETTE',
    HOV: 'HOV',
    EXPRESS: 'EXPRESS',
    TIMEDOMAIN: 'TIMEDOMAIN',
    SCHOOLZONE: 'SCHOOLZONE',
    PEDESTRIAN_ZONE: 'PEDESTRIAN_ZONE',
    SEASONAL_CLOSED: 'SEASONAL_CLOSED',
    IN_PROCESS_DATA: 'IN_PROCESS_DATA',
    START_OR_DESTINATION_ROAD_ONLY: 'START_OR_DESTINATION_ROAD_ONLY',
    BORDER_CROSS: 'BORDER_CROSS',
    TOLL_BOOTH: 'TOLL_BOOTH',
    RAILWAY_CROSS: 'RAILWAY_CROSS',
    SERVICE: 'SERVICE',
    PARKING: 'PARKING',
    CONTROLLED_ACCESS: 'CONTROLLED_ACCESS',
    PARALLEL: 'PARALLEL',
    PARKING_ROUTE: 'PARKING_ROUTE',
    RESTRICTED: 'RESTRICTED'
} as const;

export type RoadProperty = typeof RoadProperty[keyof typeof RoadProperty];


/**
 * 
 * @export
 * @interface Route
 */
export interface Route {
    /**
     * 
     * @type {TravelInfo}
     * @memberof Route
     */
    'travel_info': TravelInfo;
    /**
     * 
     * @type {BoundingBox}
     * @memberof Route
     */
    'bounds': BoundingBox;
    /**
     * 
     * @type {GeoPos}
     * @memberof Route
     */
    'start_location': GeoPos;
    /**
     * The number of traffic blockings present on the route. One may receive a route with such a number >0 in the use case that one calculates a route which ignores traffic by setting use_traffic_realtime false in the RouteRequest.
     * @type {number}
     * @memberof Route
     */
    'number_traffic_blockings'?: number;
    /**
     * A short list of relevant road names, road numbers, exit names, city names etc. that differentiate this route other routes from the same result set (for display purposes only).
     * @type {Array<ViaInfo>}
     * @memberof Route
     */
    'via_infos'?: Array<ViaInfo>;
    /**
     * 
     * @type {LabelingPosition}
     * @memberof Route
     */
    'labeling_position'?: LabelingPosition;
    /**
     * A list of segments of the route. A segment describes the part of the route between two consecutive waypoints.
     * @type {Array<RouteSegment>}
     * @memberof Route
     */
    'segments': Array<RouteSegment>;
}
/**
 * Route links describe route planning result route in the most detailed parts. Client application can specifically request and use this information when presentation to the user is intended on that level, e.g. for a map display during an active route guidance.
 * @export
 * @interface RouteLink
 */
export interface RouteLink {
    /**
     * The unique identifier the routing link in the navigation database used for route calculation. Clients can make use of this id when then use a id compatible navigation database.
     * @type {string}
     * @memberof RouteLink
     */
    'id': string;
    /**
     * 
     * @type {TravelInfo}
     * @memberof RouteLink
     */
    'travel_info': TravelInfo;
    /**
     * 
     * @type {Polyline}
     * @memberof RouteLink
     */
    'polyline'?: Polyline;
    /**
     * Start of this link by means of index in the array of coordinates given by the encoded polyline of the segment.
     * @type {number}
     * @memberof RouteLink
     */
    'start_idx'?: number;
    /**
     * Traffic delay on the respective route link in seconds
     * @type {number}
     * @memberof RouteLink
     */
    'delay'?: number;
    /**
     * Traffic-Level-of-Service (traffic flow) along this RouteLink
     * @type {Array<TILosOnLink>}
     * @memberof RouteLink
     */
    'ti_los'?: Array<TILosOnLink>;
    /**
     * Traffic information messages along this RouteLink
     * @type {Array<TIMessageOnLink>}
     * @memberof RouteLink
     */
    'ti_messages'?: Array<TIMessageOnLink>;
    /**
     * List of ids and lengts of links on most detailed data level. Links are sorted in order of driving,
     * @type {Array<RouteLinkLowestLevelLinks>}
     * @memberof RouteLink
     */
    'lowest_level_links'?: Array<RouteLinkLowestLevelLinks>;
    /**
     * List of road names of this RouteLink
     * @type {Array<NameOnLink>}
     * @memberof RouteLink
     */
    'road_names'?: Array<NameOnLink>;
    /**
     * List of road numbers of this RouteLink
     * @type {Array<NameOnLink>}
     * @memberof RouteLink
     */
    'road_numbers'?: Array<NameOnLink>;
}
/**
 * 
 * @export
 * @interface RouteLinkLowestLevelLinks
 */
export interface RouteLinkLowestLevelLinks {
    /**
     * unique identifier the lowest level routing link in the navigation database
     * @type {string}
     * @memberof RouteLinkLowestLevelLinks
     */
    'id': string;
    /**
     * length of this link, in meters.
     * @type {number}
     * @memberof RouteLinkLowestLevelLinks
     */
    'length'?: number;
}
/**
 * Describes the result status of the route planning. If no route can be calculated for the given route planning input, the value will be \'Failed\' and the property message contains a free text description of the reason. Otherwise, this property is left blank.
 * @export
 * @interface RoutePlanningStatus
 */
export interface RoutePlanningStatus {
    /**
     * 
     * @type {string}
     * @memberof RoutePlanningStatus
     */
    'value'?: RoutePlanningStatusValueEnum;
    /**
     * 
     * @type {string}
     * @memberof RoutePlanningStatus
     */
    'message'?: string;
}

export const RoutePlanningStatusValueEnum = {
    SUCCESS: 'Success',
    FAILED: 'Failed'
} as const;

export type RoutePlanningStatusValueEnum = typeof RoutePlanningStatusValueEnum[keyof typeof RoutePlanningStatusValueEnum];

/**
 * Defines the usage of roads by their attribues. Currently these road_properties and prefs are supported * HIGHWAYS_MOTORWAYS * TOLL_ROADS - any kind of toll * FERRY - ship and car train * TUNNELS * excluded - roads with the given road_property are not used
 * @export
 * @interface RoutePreference
 */
export interface RoutePreference {
    /**
     * 
     * @type {RoadProperty}
     * @memberof RoutePreference
     */
    'road_property': RoadProperty;
    /**
     * 
     * @type {PreferenceType}
     * @memberof RoutePreference
     */
    'pref': PreferenceType;
}


/**
 * 
 * @export
 * @interface RouteRequest
 */
export interface RouteRequest {
    /**
     * 
     * @type {GeoPos}
     * @memberof RouteRequest
     */
    'startOfTrip': GeoPos;
    /**
     * 
     * @type {Array<GeoPos>}
     * @memberof RouteRequest
     */
    'waypoints': Array<GeoPos>;
    /**
     * Maximum number of routes to be calculated. The number of routes in the actual result may be lower, if not as many route alternatives as requested can be calculated. Recommended setting is 1..5
     * @type {number}
     * @memberof RouteRequest
     */
    'max_routes'?: number;
    /**
     * 
     * @type {Array<RoutePreference>}
     * @memberof RouteRequest
     */
    'route_preference'?: Array<RoutePreference>;
    /**
     * 
     * @type {RouteRequestUserBlockages}
     * @memberof RouteRequest
     */
    'user_blockages'?: RouteRequestUserBlockages;
    /**
     * 
     * @type {LanguageSetting}
     * @memberof RouteRequest
     */
    'language_setting'?: LanguageSetting;
    /**
     * 
     * @type {EnergyStatus}
     * @memberof RouteRequest
     */
    'energy_status'?: EnergyStatus;
    /**
     * The route planner service provides list predefined configurations for a set of car models. By this configuration, the route planner will known about the details of the vehicle including the energy consumption and charing parameters.
     * @type {string}
     * @memberof RouteRequest
     */
    'car_model'?: string;
    /**
     * 
     * @type {VehicleParameter}
     * @memberof RouteRequest
     */
    'vehicle_parameter'?: VehicleParameter;
    /**
     * 
     * @type {Charging}
     * @memberof RouteRequest
     */
    'charging'?: Charging;
    /**
     * Usage of Contraction Hierarchies as alternative fast Routing Algorithm. Default is false.
     * @type {boolean}
     * @memberof RouteRequest
     */
    'use_ch_routing_algo'?: boolean;
    /**
     * Use real time traffic data, incoming from a realtime traffic data provider.
     * @type {boolean}
     * @memberof RouteRequest
     */
    'use_traffic_realtime'?: boolean;
    /**
     * Include maneuver list in route result?
     * @type {boolean}
     * @memberof RouteRequest
     */
    'want_maneuvers'?: boolean;
    /**
     * Provide detailed RouteLink information on the segments of the calculated routes in the response.
     * @type {boolean}
     * @memberof RouteRequest
     */
    'with_route_links'?: boolean;
    /**
     * Provide ids and lengts of links in most detailed data level. Requires with_route_links = true
     * @type {boolean}
     * @memberof RouteRequest
     */
    'with_lowest_level_links'?: boolean;
    /**
     * Include road names into RouteLink information. Requires with_route_links = true
     * @type {boolean}
     * @memberof RouteRequest
     */
    'with_road_names'?: boolean;
    /**
     * Include road numbers into RouteLink information. Requires with_route_links = true
     * @type {boolean}
     * @memberof RouteRequest
     */
    'with_road_numbers'?: boolean;
}
/**
 * Defines roads that must not be used by the route(s).
 * @export
 * @interface RouteRequestUserBlockages
 */
export interface RouteRequestUserBlockages {
    /**
     * 
     * @type {Array<LinkPath>}
     * @memberof RouteRequestUserBlockages
     */
    'blocked_paths'?: Array<LinkPath>;
}
/**
 * 
 * @export
 * @interface RouteResult
 */
export interface RouteResult {
    /**
     * 
     * @type {RoutePlanningStatus}
     * @memberof RouteResult
     */
    'result': RoutePlanningStatus;
    /**
     * The calculated routes for the given route planning input. This array can be empty if no route could be calculated.
     * @type {Array<Route>}
     * @memberof RouteResult
     */
    'routes': Array<Route>;
}
/**
 * 
 * @export
 * @interface RouteSegment
 */
export interface RouteSegment {
    /**
     * 
     * @type {TravelInfo}
     * @memberof RouteSegment
     */
    'travel_info': TravelInfo;
    /**
     * 
     * @type {Polyline}
     * @memberof RouteSegment
     */
    'polyline': Polyline;
    /**
     * 
     * @type {GeoPos}
     * @memberof RouteSegment
     */
    'end_location': GeoPos;
    /**
     * 
     * @type {ReachabilityByEnergy}
     * @memberof RouteSegment
     */
    'reachability_by_energy'?: ReachabilityByEnergy;
    /**
     * List of automatically inserted stopovers on the route section before the end of the route segment.
     * @type {Array<AutomaticStopover>}
     * @memberof RouteSegment
     */
    'automatic_stopovers'?: Array<AutomaticStopover>;
    /**
     * 
     * @type {Array<RouteLink>}
     * @memberof RouteSegment
     */
    'route_links'?: Array<RouteLink>;
    /**
     * List of maneuvers along the route segment.
     * @type {Array<Maneuver>}
     * @memberof RouteSegment
     */
    'maneuvers'?: Array<Maneuver>;
}


/**
 * Describing the route guidance symbol element attribute. These information will be used to draw RGS view. (RouteSymbolElem in fidl)
 * @export
 * @interface RouteSymbolInfo
 */
export interface RouteSymbolInfo {
    /**
     * 
     * @type {RouteSymbolRole}
     * @memberof RouteSymbolInfo
     */
    'role'?: RouteSymbolRole;
    /**
     * (RouteSymbolElemType; simply named \"type\" in fidl)
     * @type {string}
     * @memberof RouteSymbolInfo
     */
    'allowed'?: RouteSymbolInfoAllowedEnum;
    /**
     * 
     * @type {number}
     * @memberof RouteSymbolInfo
     */
    'direction'?: number;
    /**
     * 
     * @type {RouteSymbolInfoPriorStubDetail}
     * @memberof RouteSymbolInfo
     */
    'prior_stub_detail'?: RouteSymbolInfoPriorStubDetail;
}

export const RouteSymbolInfoAllowedEnum = {
    BOTH_ALLOWED: 'BOTH_ALLOWED',
    ENTRY_ALLOWED: 'ENTRY_ALLOWED',
    EXIT_ALLOWED: 'EXIT_ALLOWED',
    NONE_ALLOWED: 'NONE_ALLOWED'
} as const;

export type RouteSymbolInfoAllowedEnum = typeof RouteSymbolInfoAllowedEnum[keyof typeof RouteSymbolInfoAllowedEnum];

/**
 * 
 * @export
 * @interface RouteSymbolInfoPriorStubDetail
 */
export interface RouteSymbolInfoPriorStubDetail {
    /**
     * Side street index for route symbol elements start from zero
     * @type {number}
     * @memberof RouteSymbolInfoPriorStubDetail
     */
    'side_street_number'?: number;
    /**
     * Distance in meters
     * @type {number}
     * @memberof RouteSymbolInfoPriorStubDetail
     */
    'distance_to_maneuver'?: number;
    /**
     * 
     * @type {GeoPos}
     * @memberof RouteSymbolInfoPriorStubDetail
     */
    'crossing_point'?: GeoPos;
}
/**
 * Role of an element of a route guidance symbol
 * @export
 * @enum {string}
 */

export const RouteSymbolRole = {
    NO_ROLE: 'ER_NO_ROLE',
    DESTINATION: 'ER_DESTINATION',
    WAYPOINT: 'ER_WAYPOINT',
    TURN: 'ER_TURN',
    EXIT: 'ER_EXIT',
    BIFURCATION: 'ER_BIFURCATION',
    ROUNDABOUT_RIGHT: 'ER_ROUNDABOUT_RIGHT',
    ROUNDABOUT_LEFT: 'ER_ROUNDABOUT_LEFT',
    SQUARE_RIGHT: 'ER_SQUARE_RIGHT',
    SQUARE_LEFT: 'ER_SQUARE_LEFT',
    UTURN_RIGHT: 'ER_UTURN_RIGHT',
    UTURN_LEFT: 'ER_UTURN_LEFT',
    MICHIGAN_UTURN_RIGHT: 'ER_MICHIGAN_UTURN_RIGHT',
    MICHIGAN_UTURN_LEFT: 'ER_MICHIGAN_UTURN_LEFT',
    SIDE_STREET: 'ER_SIDE_STREET',
    SIDE_STREET_BEFORE: 'ER_SIDE_STREET_BEFORE',
    START_ON_ROUNDABOUT_RIGHT: 'ER_START_ON_ROUNDABOUT_RIGHT',
    START_ON_ROUNDABOUT_LEFT: 'ER_START_ON_ROUNDABOUT_LEFT',
    TRIFURCATION: 'ER_TRIFURCATION',
    TUNNEL_ENTRY: 'ER_TUNNEL_ENTRY',
    TOLL_ENTRY: 'ER_TOLL_ENTRY',
    VIGNETTE_ENTRY: 'ER_VIGNETTE_ENTRY',
    CARTRAIN_ENTRY: 'ER_CARTRAIN_ENTRY',
    UNPAVED_ENTRY: 'ER_UNPAVED_ENTRY',
    HIGHWAY_ENTRY: 'ER_HIGHWAY_ENTRY',
    RESTRICTED_AREA: 'ER_RESTRICTED_AREA',
    LANE_MERGE_RIGHT: 'ER_LANE_MERGE_RIGHT',
    LANE_MERGE_LEFT: 'ER_LANE_MERGE_LEFT',
    LANE_MERGE_BOTH: 'ER_LANE_MERGE_BOTH',
    FERRY_ENTRY: 'ER_FERRY_ENTRY',
    HOV_LANE_ENTER: 'ER_HOV_LANE_ENTER',
    HOV_LANE_LEAVE: 'ER_HOV_LANE_LEAVE',
    EXPRESS_LANE_ENTER: 'ER_EXPRESS_LANE_ENTER',
    EXPRESS_LANE_LEAVE: 'ER_EXPRESS_LANE_LEAVE',
    RAILWAY_CROSS_ENTER: 'ER_RAILWAY_CROSS_ENTER',
    TIMEDOMAIN_ENTER: 'ER_TIMEDOMAIN_ENTER',
    SCHOOLZONE_ENTER: 'ER_SCHOOLZONE_ENTER',
    BORDER_CROSS_ENTER: 'ER_BORDER_CROSS_ENTER',
    ENTER_OFFROAD: 'ER_ENTER_OFFROAD',
    LEAVE_OFFROAD: 'ER_LEAVE_OFFROAD',
    TOLL_BOOTH: 'ER_TOLL_BOOTH',
    PASS_SAPA_AREA: 'ER_PASS_SAPA_AREA',
    PASS_SERVICE_AREA: 'ER_PASS_SERVICE_AREA',
    PASS_PARKING_AREA: 'ER_PASS_PARKING_AREA',
    TRANSPORTATION_CHANGE: 'ER_TRANSPORTATION_CHANGE',
    ENTER_TRIP: 'ER_ENTER_TRIP',
    LEAVE_TRIP: 'ER_LEAVE_TRIP',
    CHANGE_TRIP: 'ER_CHANGE_TRIP',
    CHARGING_STOPOVER: 'ER_CHARGING_STOPOVER',
    ENTER_PARKING_ROUTE: 'ER_ENTER_PARKING_ROUTE'
} as const;

export type RouteSymbolRole = typeof RouteSymbolRole[keyof typeof RouteSymbolRole];


/**
 * 
 * @export
 * @interface TILosOnLink
 */
export interface TILosOnLink {
    /**
     * The individual Traffic-Level-of-Service (traffic flow) Identical with TPEG standard, expect for special values 254, 255
     * @type {string}
     * @memberof TILosOnLink
     */
    'los': TILosOnLinkLosEnum;
    /**
     * End position given relative as a fraction of the length of the route link
     * @type {number}
     * @memberof TILosOnLink
     */
    'end': number;
}

export const TILosOnLinkLosEnum = {
    UNKNOWN: 'Unknown',
    FREE: 'Free',
    HEAVY: 'Heavy',
    SLOW: 'Slow',
    QUEUING: 'Queuing',
    STATIONARY: 'Stationary',
    NO_TRAFFIC: 'NoTraffic',
    NOT_ASSIGNED: 'NotAssigned',
    INCIDENT: 'Incident'
} as const;

export type TILosOnLinkLosEnum = typeof TILosOnLinkLosEnum[keyof typeof TILosOnLinkLosEnum];

/**
 * Listing of the traffic events on a given RouteLink
 * @export
 * @interface TIMessageOnLink
 */
export interface TIMessageOnLink {
    /**
     * Traffic message by their respective id
     * @type {number}
     * @memberof TIMessageOnLink
     */
    'id': number;
    /**
     * Start position given relative as a fraction of the length of the route link
     * @type {number}
     * @memberof TIMessageOnLink
     */
    'start': number;
    /**
     * End position given relative as a fraction of the length of the route link
     * @type {number}
     * @memberof TIMessageOnLink
     */
    'end': number;
}
/**
 * An overview about the basic travel information of the route or a part of it.
 * @export
 * @interface TravelInfo
 */
export interface TravelInfo {
    /**
     * Length of this part of the route in meter.
     * @type {number}
     * @memberof TravelInfo
     */
    'distance': number;
    /**
     * The travel time for this part of the route in seconds. In case of travel delays on this part of the route, they are are included in this value. In case of automatic stopovers on this part of the route, the assumed charging times are included in this value.
     * @type {number}
     * @memberof TravelInfo
     */
    'time': number;
    /**
     * The travel delay for this part of the route in seconds.
     * @type {number}
     * @memberof TravelInfo
     */
    'delay'?: number;
    /**
     * The energy consumptions for this part of the route in kWh.
     * @type {number}
     * @memberof TravelInfo
     */
    'energyConsumption'?: number;
}
/**
 * 
 * @export
 * @interface VehicleParameter
 */
export interface VehicleParameter {
    /**
     * drivetrain is electric if true combustion engine if false
     * @type {boolean}
     * @memberof VehicleParameter
     */
    'electric_drivetrain': boolean;
    /**
     * The mass of the vehicle in kg
     * @type {number}
     * @memberof VehicleParameter
     */
    'vehicle_mass': number;
    /**
     * The tip speed in km/h for consumption. The maximum 255 equals unbounded.
     * @type {number}
     * @memberof VehicleParameter
     */
    'tip_speed': number;
    /**
     * 
     * @type {Array<CscEntry>}
     * @memberof VehicleParameter
     */
    'consumption_speed_curve': Array<CscEntry>;
}
/**
 * Description of a passed locations on the route (by means of road names, road numbers, exit names, city names...).
 * @export
 * @interface ViaInfo
 */
export interface ViaInfo {
    /**
     * Name of the passed location.
     * @type {string}
     * @memberof ViaInfo
     */
    'name'?: string;
    /**
     * Kind of this via
     * @type {string}
     * @memberof ViaInfo
     */
    'kind'?: ViaInfoKindEnum;
    /**
     * 
     * @type {GeoPos}
     * @memberof ViaInfo
     */
    'position'?: GeoPos;
}

export const ViaInfoKindEnum = {
    ROAD_NAME: 'road_name',
    ROAD_NUMBER: 'road_number',
    TRANSIT_STOP: 'transit_stop',
    TRANSIT_ROUTE: 'transit_route',
    CHARGING_STOPOVER: 'charging_stopover',
    PLACE: 'place',
    RESTING_STOPOVER: 'resting_stopover',
    OVERNIGHT_STOPOVER: 'overnight_stopover'
} as const;

export type ViaInfoKindEnum = typeof ViaInfoKindEnum[keyof typeof ViaInfoKindEnum];


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {RouteRequest} routeRequest All relevant information for a route planning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routePost: async (routeRequest: RouteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'routeRequest' is not null or undefined
            assertParamExists('routePost', 'routeRequest', routeRequest)
            const localVarPath = `/route`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(routeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {RouteRequest} routeRequest All relevant information for a route planning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async routePost(routeRequest: RouteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RouteResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.routePost(routeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {RouteRequest} routeRequest All relevant information for a route planning.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        routePost(routeRequest: RouteRequest, options?: any): AxiosPromise<RouteResult> {
            return localVarFp.routePost(routeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {RouteRequest} routeRequest All relevant information for a route planning.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public routePost(routeRequest: RouteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).routePost(routeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


