import BatteryCharging20Icon from "@mui/icons-material/BatteryCharging20"
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull"
import { Box, Slider, Stack, Typography } from "@mui/material"
import { maxBy } from "lodash"
import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import { isDefined } from "ts-is-present"
import { ChargingCurveEntry, CscEntry } from "../../../out/gen/openapi/routeplanner"
import useStore from "../../Store"
import { getConnectors } from "../charger/ChargerHelper"
import { Vehicle, predefinedVehicles } from "../charger/PredefinedVehicles"
import { CarSelectionComponent } from "./CarSelection"
import { ChargingCurveComponent } from "./ChargingCurve"
import { ConnectorSelectorComponent } from "./ConnectorSelector"
import { grey } from "@mui/material/colors"
import { ConsumptionCurveComponent } from "./ConsuptionCurve"

interface IVehicleConfigComponentProps {}

export const VehicleConfigComponent: FunctionComponent<IVehicleConfigComponentProps> = props => {
   const { t } = useTranslation()
   const charging = useStore(store => store.charging)
   const updateCharging = useStore(store => store.updateCharging)
   const vehicleParameter = useStore(store => store.vehicleParameter)
   const updateVehicleParameter = useStore(store => store.updateVehicleParameter)
   const [batteryKwh, setBatteryKwh] = useState(vehicleParameter.max_energy_kWh)
   const vehicle = useStore(store => store.vehicle)
   const updateVehicle = useStore(store => store.updateVehicle)
   const [isPredefined, setIsPredefined] = useState(vehicle.brand !== "Custom")

   const updateCar = (
      max_energy_kWh?: number,
      charging_curve?: ChargingCurveEntry[],
      consumption?: CscEntry[],
      car?: Vehicle,
   ) => {
      const customCar = structuredClone(car ?? vehicle)
      customCar.brand = "Custom"
      if (max_energy_kWh !== undefined) {
         updateVehicleParameter({ max_energy_kWh: max_energy_kWh })
         customCar.capacity = max_energy_kWh
         setBatteryKwh(max_energy_kWh)
      }
      if (charging_curve) {
         updateCharging({ charging_curve: charging_curve, charge_power_dc: maxBy(charging_curve, ce => ce.pow)?.pow })
         customCar.chargingCurve = charging_curve
      }
      if (consumption) {
         updateVehicleParameter({
            consumption_speed_curve: consumption,
         })
         customCar.consumptionCurve = consumption
      }
      if (car) {
         updateVehicle(car)
         setIsPredefined(true)
      } else {
         setIsPredefined(false)
         updateVehicle(customCar)
         const index = predefinedVehicles.findIndex(v => v.brand === "Custom" && v.model === customCar.model)
         if (index !== -1) {
            predefinedVehicles[index] = customCar
         } else {
            predefinedVehicles.push(customCar)
         }
      }
   }

   return (
      <Stack spacing={2}>
         <CarSelectionComponent
            updateCar={c => {
               updateCar(c.capacity, c.chargingCurve, c.consumptionCurve, c)
            }}
            buttonText={`${
               isPredefined
                  ? vehicle.brand === "Custom"
                     ? t("VehicleConfig_Custom_car_brand")
                     : vehicle.brand
                  : t("VehicleConfig_Custom_car_brand")
            } ≻ ${vehicle.model}`}
         />
         <Box>
            <Typography variant="body1" mr={2} ml={1} color={vehicle.type === "Diesel" ? grey[500] : undefined}>
               {t("VehicleConfig_Battery_Cap")}: {batteryKwh} kwh
            </Typography>
            <Stack direction={"row"} mt={1} mr={2} ml={2}>
               <BatteryCharging20Icon />
               <Slider
                  min={1}
                  max={250}
                  value={batteryKwh}
                  step={1}
                  onChange={(e, v) => {
                     if (typeof v === "number") setBatteryKwh(v)
                  }}
                  onChangeCommitted={(e, v) => {
                     if (typeof v === "number") updateCar(v)
                  }}
                  sx={{ ml: 1, mr: 1 }}
                  disabled={vehicle.type === "Diesel"}
               ></Slider>
               <BatteryChargingFullIcon />
            </Stack>
         </Box>
         <Stack direction={"row"} mr={2} ml={2} spacing={1}>
            <ChargingCurveComponent
               key="ChargingCurveComponent"
               currentChargingCurve={charging.charging_curve}
               updateChargingCurve={curve => {
                  updateCar(undefined, curve)
               }}
            />
            <ConsumptionCurveComponent
               currentConsumptionCurve={vehicle.consumptionCurve}
               updateConsumptionCurve={curve => updateCar(undefined, undefined, curve)}
            />
            <ConnectorSelectorComponent
               connectors={getConnectors()}
               activeConnectors={
                  charging.connectorId?.map(id => getConnectors().find(con => con.ndsId === id)).filter(isDefined) ?? []
               }
               key="ConnectorSelectorComponent"
               updateConnectors={connectors =>
                  updateCharging({ connectorId: connectors.map(con => con.ndsId).filter(isDefined) })
               }
               disabled={vehicle.type === "Diesel"}
            />
         </Stack>
      </Stack>
   )
}
