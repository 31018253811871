export function toRouteDistance(distance: number) {
   let humanDistance: string

   if (distance < 1000) {
      humanDistance = new Intl.NumberFormat(undefined, {
         style: "unit",
         unit: "meter",
         maximumFractionDigits: 0,
      }).format(distance)
   } else if (distance < 10000) {
      humanDistance = new Intl.NumberFormat(undefined, {
         style: "unit",
         unit: "kilometer",
         maximumFractionDigits: 2,
      }).format(distance / 1000)
   } else {
      humanDistance = new Intl.NumberFormat(undefined, {
         style: "unit",
         unit: "kilometer",
         maximumFractionDigits: 0,
      }).format(distance / 1000)
   }

   return humanDistance
}
