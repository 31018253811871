import { Place } from "@aws-amplify/geo"
import AddLocationIcon from "@mui/icons-material/AddLocation"
import ClearIcon from "@mui/icons-material/Clear"
import Delete from "@mui/icons-material/Delete"
import MyLocationIcon from "@mui/icons-material/MyLocation"
import { useTabContext } from "@mui/lab"
import { Box, Button, CircularProgress, IconButton, Stack } from "@mui/material"
import { FunctionComponent } from "react"
import { useMap } from "react-map-gl/maplibre"
import { GeoPos, RouteResult } from "../../../out/gen/openapi/routeplanner"
import useStore from "../../Store"
import { theme } from "../../Theme"
import { LocationSearch } from "./LocationSearch"
import { useTranslation } from "react-i18next"

interface RoutingInputProps {
   onRoute: (route: RouteResult) => void
}

export const LocationsInputContainer: FunctionComponent<RoutingInputProps> = (props: RoutingInputProps) => {
   const { t } = useTranslation()
   const { current: map } = useMap()
   const context = useTabContext() // uses tab context from TabContext element

   if (context === null) {
      throw new TypeError("No TabContext provided")
   }
   const tabId = context.value

   const start = useStore(state => state.start)
   const updateStart = useStore(state => state.updateStart)
   const dest = useStore(state => state.dest)
   const updateWaypoint = useStore(state => state.updateWaypoint)
   const addWaypoint = useStore(state => state.addWaypoint)
   const removeWaypoint = useStore(state => state.removeWaypoint)
   const lastDestinationValid = useStore(state => state.lastDestinationValid)
   const calculationStatus = useStore(state => state.calculationStatus)
   const clearRoute = useStore(state => state.clearRoute)
   const clearRouteResult = useStore(state => state.clearRouteResult)

   return (
      <Box
         sx={{
            display: "route" === tabId ? undefined : "none",
         }}
      >
         <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* <LocationInput label="Start Position" positionInput={start} updatePosition={updateStart} /> */}
            <LocationSearch
               label={t("RoutingInput_PosSearch_StartPosition")}
               positionInput={start}
               updatePosition={updateStart}
               clearPosition={() => {
                  updateStart("")
                  clearRouteResult()
               }}
            />
            {start.pos && (
               <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                     if (start.pos) {
                        map?.flyTo({ center: [start.pos.lon, start.pos.lat], zoom: 14 })
                     }
                  }}
               >
                  <MyLocationIcon />
               </IconButton>
            )}
         </Box>
         {dest.map((wp, idx) => (
            <Box key={`PosInputBox-${idx}`} sx={{ marginTop: theme.spacing(1), display: "flex", alignItems: "center" }}>
               <LocationSearch
                  label={
                     idx < dest.length - 1
                        ? `${idx + 1}. ${t("RoutingInput_PosSearch_Waypoint")}`
                        : `${t("RoutingInput_PosSearch_Destination")}`
                  }
                  positionInput={wp}
                  updatePosition={(pos: GeoPos, place: Place) => {
                     updateWaypoint(pos, place, idx)
                  }}
                  clearPosition={() => {
                     updateWaypoint("", undefined, idx)
                     clearRouteResult()
                  }}
               />
               {wp.pos && (
                  <IconButton
                     color="primary"
                     size="small"
                     onClick={() => {
                        if (wp.pos) {
                           map?.flyTo({ center: [wp.pos.lon, wp.pos.lat], zoom: 14 })
                        }
                     }}
                  >
                     <MyLocationIcon />
                  </IconButton>
               )}
               {dest.length > 1 && (
                  <IconButton color="primary" size="small" onClick={() => removeWaypoint(idx)}>
                     <Delete />
                  </IconButton>
               )}
            </Box>
         ))}
         <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mt={2}>
            <Button
               color="primary"
               size="medium"
               variant="text"
               startIcon={<AddLocationIcon />}
               disabled={!lastDestinationValid()}
               onClick={() => {
                  // Only allow to add a new waypoint if the current last destination is valid.
                  if (lastDestinationValid()) {
                     addWaypoint("")
                  }
               }}
            >
               {t("RoutingInput_AddDestination")}
            </Button>
            {calculationStatus === "pending" && <CircularProgress />}
            <Button
               color="primary"
               size="medium"
               variant="text"
               startIcon={<ClearIcon />}
               disabled={calculationStatus !== "finished"}
               onClick={() => {
                  clearRoute()
               }}
            >
               {t("RoutingInput_ClearRoute")}
            </Button>
         </Stack>
         <Box></Box>
      </Box>
   )
}
