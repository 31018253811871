/* tslint:disable */
/* eslint-disable */
/**
 * EV API
 * API for requesting ev chargers by Neusoft One Core.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Attribute
 */
export interface Attribute {
    /**
     * 
     * @type {string}
     * @memberof Attribute
     */
    'languageCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attribute
     */
    'scriptCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attribute
     */
    'displayName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Attribute
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface Charger
 */
export interface Charger {
    /**
     * The charger ID (facilityId).
     * @type {number}
     * @memberof Charger
     */
    'id': number;
    /**
     * e.g. OpenChargeMap, Bundesnetzagentur, HERE
     * @type {string}
     * @memberof Charger
     */
    'provider': string;
    /**
     * key or id used by the provider
     * @type {string}
     * @memberof Charger
     */
    'providerKey'?: string;
    /**
     * operator or network for the charger, e.g. Fastned, Tesla, EnBW etc.
     * @type {string}
     * @memberof Charger
     */
    'supplier'?: string;
    /**
     * 
     * @type {number}
     * @memberof Charger
     */
    'priority'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Charger
     */
    'availability'?: boolean;
    /**
     * 
     * @type {GeoPos}
     * @memberof Charger
     */
    'position'?: GeoPos;
    /**
     * 
     * @type {Array<Outlet>}
     * @memberof Charger
     */
    'outlets': Array<Outlet>;
    /**
     * 
     * @type {Array<Attribute>}
     * @memberof Charger
     */
    'attributes': Array<Attribute>;
}
/**
 * @type ChargerGet200Response
 * @export
 */
export type ChargerGet200Response = Array<Charger> | CompressedChargersByPower;

/**
 * @type ChargerGetRequest
 * @export
 */
export type ChargerGetRequest = ChargerInputBbox | ChargerInputPosition | ChargerInputRoute;

/**
 * 
 * @export
 * @interface ChargerInputBbox
 */
export interface ChargerInputBbox {
    /**
     * bounding box in format: longitude, latitude, longitude, latitude
     * @type {Array<number>}
     * @memberof ChargerInputBbox
     */
    'bbox'?: Array<number>;
}
/**
 * 
 * @export
 * @interface ChargerInputPosition
 */
export interface ChargerInputPosition {
    /**
     * position in format: longitude, latitude
     * @type {Array<number>}
     * @memberof ChargerInputPosition
     */
    'position'?: Array<number>;
    /**
     * around position in meters
     * @type {number}
     * @memberof ChargerInputPosition
     */
    'radius'?: number;
}
/**
 * 
 * @export
 * @interface ChargerInputRoute
 */
export interface ChargerInputRoute {
    /**
     * A series of coordinates encoded into a string. See https://developers.google.com/maps/documentation/utilities/polylinealgorithm
     * @type {string}
     * @memberof ChargerInputRoute
     */
    'route'?: string;
    /**
     * buffer along route or around the position in meter
     * @type {number}
     * @memberof ChargerInputRoute
     */
    'buffer'?: number;
}
/**
 * 
 * @export
 * @interface CompressedChargersByPower
 */
export interface CompressedChargersByPower {
    /**
     * 
     * @type {Array<number>}
     * @memberof CompressedChargersByPower
     */
    'highPower'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompressedChargersByPower
     */
    'mediumPower'?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CompressedChargersByPower
     */
    'lowPower'?: Array<number>;
}
/**
 * 
 * @export
 * @interface Connector
 */
export interface Connector {
    /**
     * 
     * @type {number}
     * @memberof Connector
     */
    'ndsId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'ndsName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Connector
     */
    'displayName'?: string;
}
/**
 * A geoposition in WGS84 coordinates.
 * @export
 * @interface GeoPos
 */
export interface GeoPos {
    /**
     * Longitude parameter of a WGS84 coordinate.
     * @type {number}
     * @memberof GeoPos
     */
    'lon': number;
    /**
     * Latitude parameter of a WGS84 coordinate.
     * @type {number}
     * @memberof GeoPos
     */
    'lat': number;
}
/**
 * 
 * @export
 * @interface Outlet
 */
export interface Outlet {
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'country'?: number;
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'connector'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Outlet
     */
    'isDC'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'maxPower'?: number;
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'maxCurrent'?: number;
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'maxVoltage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Outlet
     */
    'estimatedPower'?: number;
    /**
     * 
     * @type {GeoPos}
     * @memberof Outlet
     */
    'position'?: GeoPos;
}
/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {number}
     * @memberof Supplier
     */
    'supplierId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    'name'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a list of chargers. If chargerId is omitted and format is compressed, all chargers are returned
         * @param {Array<number>} [chargerId] 
         * @param {'normal' | 'compressed'} [format] chooses result format, default normal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargerGet: async (chargerId?: Array<number>, format?: 'normal' | 'compressed', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/charger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (chargerId) {
                localVarQueryParameter['chargerId'] = chargerId;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves chargers for a bounding box, around a position or along a route
         * @summary Get a list of chargers
         * @param {ChargerGetRequest} chargerGetRequest Choose one of the input types: BBox, Route or Radius
         * @param {'normal' | 'compressed'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargerGet_1: async (chargerGetRequest: ChargerGetRequest, format?: 'normal' | 'compressed', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chargerGetRequest' is not null or undefined
            assertParamExists('chargerGet_1', 'chargerGetRequest', chargerGetRequest)
            const localVarPath = `/charger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chargerGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of connector types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectorsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of relevant suppliers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suppliersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/suppliers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a list of chargers. If chargerId is omitted and format is compressed, all chargers are returned
         * @param {Array<number>} [chargerId] 
         * @param {'normal' | 'compressed'} [format] chooses result format, default normal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargerGet(chargerId?: Array<number>, format?: 'normal' | 'compressed', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargerGet(chargerId, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves chargers for a bounding box, around a position or along a route
         * @summary Get a list of chargers
         * @param {ChargerGetRequest} chargerGetRequest Choose one of the input types: BBox, Route or Radius
         * @param {'normal' | 'compressed'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chargerGet_1(chargerGetRequest: ChargerGetRequest, format?: 'normal' | 'compressed', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChargerGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chargerGet_1(chargerGetRequest, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of connector types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async connectorsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Connector>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.connectorsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of relevant suppliers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async suppliersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Supplier>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.suppliersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a list of chargers. If chargerId is omitted and format is compressed, all chargers are returned
         * @param {Array<number>} [chargerId] 
         * @param {'normal' | 'compressed'} [format] chooses result format, default normal
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargerGet(chargerId?: Array<number>, format?: 'normal' | 'compressed', options?: any): AxiosPromise<ChargerGet200Response> {
            return localVarFp.chargerGet(chargerId, format, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves chargers for a bounding box, around a position or along a route
         * @summary Get a list of chargers
         * @param {ChargerGetRequest} chargerGetRequest Choose one of the input types: BBox, Route or Radius
         * @param {'normal' | 'compressed'} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chargerGet_1(chargerGetRequest: ChargerGetRequest, format?: 'normal' | 'compressed', options?: any): AxiosPromise<ChargerGet200Response> {
            return localVarFp.chargerGet_1(chargerGetRequest, format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of connector types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        connectorsGet(options?: any): AxiosPromise<Array<Connector>> {
            return localVarFp.connectorsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of relevant suppliers.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        suppliersGet(options?: any): AxiosPromise<Array<Supplier>> {
            return localVarFp.suppliersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get a list of chargers. If chargerId is omitted and format is compressed, all chargers are returned
     * @param {Array<number>} [chargerId] 
     * @param {'normal' | 'compressed'} [format] chooses result format, default normal
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chargerGet(chargerId?: Array<number>, format?: 'normal' | 'compressed', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chargerGet(chargerId, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves chargers for a bounding box, around a position or along a route
     * @summary Get a list of chargers
     * @param {ChargerGetRequest} chargerGetRequest Choose one of the input types: BBox, Route or Radius
     * @param {'normal' | 'compressed'} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public chargerGet_1(chargerGetRequest: ChargerGetRequest, format?: 'normal' | 'compressed', options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).chargerGet_1(chargerGetRequest, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of connector types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public connectorsGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).connectorsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of relevant suppliers.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public suppliersGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).suppliersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


