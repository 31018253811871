import { Fragment, FunctionComponent } from "react"
import { Popup, useMap } from "react-map-gl/maplibre"
import useStore from "../../Store"
import { ChargerInfoComponent } from "./ChargerInfoComponent"
import { PointLike, PositionAnchor } from "maplibre-gl"
import { getQ } from "./ChargerInfoQHelper"

const offsetsByQ: PointLike[] = [
   [-20, -5],
   [+20, -5],
   [-20, +5],
   [+20, +5],
]
const anchorsByQ: PositionAnchor[] = ["bottom-left", "bottom-right", "top-left", "top-right"]

type ChargerInfoPopupProps = {
   zIndex?: number
}

export const ChargerInfoPopup: FunctionComponent<ChargerInfoPopupProps> = props => {
   const selectedCharger = useStore(state => state.selectedCharger)
   const updateSelectedCharger = useStore(state => state.updateSelectedCharger)
   const { current: map } = useMap()
   const zIndex = props.zIndex ?? 7

   return (
      <Fragment>
         {selectedCharger && (
            <Popup
               latitude={selectedCharger?.charger?.position?.lat ?? selectedCharger?.stopover?.position?.lat ?? 0}
               longitude={selectedCharger?.charger?.position?.lon ?? selectedCharger?.stopover?.position?.lon ?? 0}
               offset={offsetsByQ[getQ(map, selectedCharger?.charger?.position)]}
               closeButton={false}
               onClose={() => updateSelectedCharger(null)}
               closeOnClick={true}
               anchor={anchorsByQ[getQ(map, selectedCharger?.charger?.position)]}
               style={{ zIndex }}
               closeOnMove={true}
            >
               <ChargerInfoComponent
                  charger={selectedCharger}
                  marginOverride={-2}
                  quadrant={getQ(map, selectedCharger?.charger?.position)}
               />
            </Popup>
         )}
      </Fragment>
   )
}
