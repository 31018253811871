import { Fragment, FunctionComponent } from "react"
import { Layer, LayerProps, Source } from "react-map-gl/maplibre"
import useStore from "../../Store"
import { theme } from "../../Theme"

const chargerClusterLayerId = "chargepoints-cluster"
const chargerClusterCountLayerId = "chargepoints-count"
export const chargerPointLayerId = "chargepoints-point"

export const ChargerLayer: FunctionComponent = () => {
   const chargers = useStore(store => store.chargerFeatureCollection)

   const chargerColor = theme.palette.secondary.light

   const chargerCluster: LayerProps = {
      id: chargerClusterLayerId,
      type: "circle",
      paint: {
         "circle-color": chargerColor,
         "circle-radius": ["step", ["get", "point_count"], 8, 10, 12, 100, 15, 750, 17],
         "circle-stroke-color": "#fff",
         "circle-stroke-width": 1,
         "circle-opacity": 0.7,
      },
      filter: ["has", "point_count"],
   }

   const chargerUnCluster: LayerProps = {
      id: chargerPointLayerId,
      type: "circle",
      filter: ["!", ["has", "point_count"]],
      paint: {
         "circle-color": [
            "match",
            ["get", "power"],
            "low",
            "#ff6961",
            "medium",
            "#FFFF00",
            "high",
            "#00FFEF",
            chargerColor, // default color
         ],
         "circle-radius": 4,
         "circle-stroke-width": 1,
         "circle-stroke-color": "#1F2229",
      },
   }

   const chargerClusterCount: LayerProps = {
      id: chargerClusterCountLayerId,
      type: "symbol",
      filter: ["has", "point_count"],
      layout: {
         "text-field": "{point_count_abbreviated}",
         "text-font": ["Noto Sans Regular"],
         "text-size": 12,
      },
      paint: {
         "text-color": theme.palette.getContrastText(chargerColor),
      },
   }
   // lowest layer for current map, dynamically changing this doesn't work
   const lowestLayerId = "water_name_line"

   return (
      <Fragment>
         <Source
            id="charger-source"
            type="geojson"
            data={chargers}
            cluster={true}
            clusterMaxZoom={11}
            clusterRadius={80}
            clusterMinPoints={5}
         >
            <Layer {...chargerClusterCount} beforeId={lowestLayerId} />
            <Layer {...chargerCluster} beforeId={chargerClusterCountLayerId} />
            <Layer {...chargerUnCluster} beforeId={chargerClusterCountLayerId} />
         </Source>
      </Fragment>
   )
}
