import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import {
   Autocomplete,
   Box,
   Button,
   Checkbox,
   Chip,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   TextField,
   colors,
   useMediaQuery,
   useTheme,
} from "@mui/material"
import { isEqual } from "lodash"
import { FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import { Supplier } from "../../../out/gen/openapi/ev"

interface ISupplierSelectorComponentProps {
   suppliers: Supplier[]
   activeSuppliers: Supplier[]
   updateSuppliers: (suppliers: Supplier[]) => void
   disabled: boolean
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

const collator = new Intl.Collator()

export const SupplierSelectorComponent: FunctionComponent<ISupplierSelectorComponentProps> = props => {
   const { t } = useTranslation()
   const [open, setOpen] = useState(false)
   const theme = useTheme()
   const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
   const [selectedSuppliers, setSelectedSuppliers] = useState<Supplier[]>([...props.activeSuppliers])

   const handleClickOpen = () => {
      setSelectedSuppliers([...props.activeSuppliers])
      setOpen(true)
   }

   const handleClose = (isOk: boolean) => {
      setOpen(false)
      if (isOk && !isEqual(selectedSuppliers, props.activeSuppliers)) {
         props.updateSuppliers(selectedSuppliers)
      }
   }

   return (
      <Box sx={{ pt: 1 }}>
         <Button variant="outlined" onClick={handleClickOpen} disabled={props.disabled}>
            {t("SupplierSelector_Select_Supplier")}
         </Button>
         <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"xl"}
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby={t("SupplierSelector_Select_Supplier")}
         >
            <DialogTitle id="responsive-dialog-title">{t("SupplierSelector_Select_Supplier")}</DialogTitle>
            <DialogContent>
               <Autocomplete
                  sx={{ m: 1 }}
                  multiple
                  id="supplier-autocomplete"
                  options={props.suppliers.sort((a, b) => collator.compare(a.name ?? "", b.name ?? ""))}
                  getOptionLabel={option => option.name ?? ""}
                  // defaultValue={[props.suppliers[1]]}
                  // filterSelectedOptions
                  disableCloseOnSelect
                  renderInput={params => (
                     <TextField
                        {...params}
                        label={t("SupplierSelector_Select_Supplier")}
                        placeholder={t("SupplierSelector_Select_Supplier")}
                     />
                  )}
                  renderOption={(props, option, { selected }) => (
                     <li {...props} key={option.supplierId ?? option.name ?? JSON.stringify(option)}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                     </li>
                  )}
                  onChange={(_, value, reason, details) => {
                     if (
                        (reason === "selectOption" || reason === "blur" || reason === "removeOption") &&
                        typeof value !== "string"
                     ) {
                        setSelectedSuppliers(value)
                     }
                     if (reason === "clear") {
                        setSelectedSuppliers([])
                     }
                  }}
                  renderTags={(value: Supplier[], getTagProps) =>
                     value.map((option: Supplier, index: number) => {
                        const chipColor = stringColor("" + option.name + option.supplierId)
                        return (
                           <Chip
                              variant="filled"
                              label={option.name}
                              {...getTagProps({ index })}
                              sx={{
                                 backgroundColor: chipColor,
                                 color: theme.palette.getContrastText(chipColor),
                              }}
                           />
                        )
                     })
                  }
                  value={selectedSuppliers}
               />
            </DialogContent>
            <DialogActions>
               <Button onClick={() => handleClose(false)} autoFocus>
                  {t("Generic_Cancel")}
               </Button>
               <Button onClick={() => handleClose(true)} autoFocus>
                  {t("Generic_Ok")}
               </Button>
            </DialogActions>
         </Dialog>
      </Box>
   )
}

function stringColor(name: string): string {
   const colorList = [
      colors.amber,
      colors.blue,
      colors.blueGrey,
      colors.brown,
      colors.cyan,
      colors.deepOrange,
      colors.deepPurple,
      colors.green,
      colors.grey,
      colors.indigo,
      colors.lightBlue,
      colors.lightGreen,
      colors.lime,
      colors.orange,
      colors.pink,
      colors.purple,
      colors.red,
      colors.teal,
      colors.yellow,
   ]

   let hash = 0
   for (let i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash)
   }

   const topLevelColor = colorList[Math.abs(Math.imul(hash, 22829) % colorList.length)]

   const subColorKeys = Object.values(topLevelColor)
   const subColor = subColorKeys[Math.abs(hash % subColorKeys.length)]

   return subColor
}
