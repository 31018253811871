import { FunctionComponent } from "react"
import { Box, Stack, Typography } from "@mui/material"
import { theme } from "../../Theme"
import useStore, { ChargerInfo } from "../../Store"
import HourglassTopIcon from "@mui/icons-material/HourglassTop"
import { toRouteDurationString } from "../itinerary/time"
import BatteryCharging60Icon from "@mui/icons-material/BatteryCharging60"
import FavoriteIcon from "@mui/icons-material/Favorite"
import NotInterestedIcon from "@mui/icons-material/NotInterested"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import { chargerConnectorIdTConnector } from "./ChargerHelper"
import PowerIcon from "@mui/icons-material/Power"
import DangerousIcon from "@mui/icons-material/Dangerous"
import { useTranslation } from "react-i18next"
import { FacilityPreference } from "../../../out/gen/openapi/routeplanner"
import { ComponentWithCheckCircle } from "../ComponentWithCheckCircle"

type ChargerInfoComponentProps = {
   charger: ChargerInfo
   marginOverride?: number
   quadrant?: number
}

const cssStyleForQuadrant = [
   {
      left: "10px",
      bottom: "-19px",
      transform: "rotate(180deg)",
   },
   {
      right: "10px",
      bottom: "-19px",
      transform: "rotate(180deg)",
   },
   {
      left: "10px",
      top: "-19px",
   },
   {
      right: "10px",
      top: "-19px",
   },
]

function isPreferred(props: ChargerInfoComponentProps, facilityPreference: FacilityPreference[] | undefined): boolean {
   if (facilityPreference === undefined) return false
   const find = facilityPreference.find(pref => pref.facility_id === props.charger.charger?.id)
   return find?.pref === "preferred" || find?.pref === "mandatory"
}

function isExcluded(props: ChargerInfoComponentProps, facilityPreference: FacilityPreference[] | undefined): boolean {
   if (facilityPreference === undefined) return false
   const find = facilityPreference.find(pref => pref.facility_id === props.charger.charger?.id)
   return find?.pref === "excluded"
}

/**
 * A component to display a Box with various information about a charger.
 *
 * The charger can be a plain charger on the map of it can be a charger
 * on a route enriched with stopover infos.
 */
export const ChargerInfoComponent: FunctionComponent<ChargerInfoComponentProps> = props => {
   const { t } = useTranslation()
   const debugModeEnabled = useStore(store => store.debugModeEnabled)
   const charging = useStore(store => store.charging)
   const addChargingFacilityPreference = useStore(store => store.addChargingFacilityPreference)
   const removeChargingFacilityPreference = useStore(store => store.removeChargingFacilityPreference)

   const formatOptions: Intl.NumberFormatOptions = {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: "percent",
   }

   return (
      <Box
         sx={{
            "padding": theme.spacing(1),
            "backgroundColor": theme.palette.primary.light,
            "color": theme.palette.info.contrastText,
            "borderRadius": 1,
            "m": props.marginOverride ?? 0,
            "&::before": {
               "content": '""',
               "position": "absolute",
               "z-index": "1",
               "border": "solid 10px transparent",
               "borderBottomColor": theme.palette.primary.light,
               ...cssStyleForQuadrant[props.quadrant ?? 0],
            },
         }}
      >
         <Stack id="stack-1" direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
            {props.charger.charger?.attributes[0].displayName !== undefined && (
               <Stack direction="row" justifyContent="flex-end" width={"100%"}>
                  <Typography variant={"body2"}>
                     {
                        // No language selection yet
                        props.charger.charger?.attributes[0].displayName
                     }
                  </Typography>
                  <Stack direction="row" justifyContent="flex-end" spacing={0} sx={{ pl: 2, mr: -1, mb: -2 }}>
                     <ComponentWithCheckCircle
                        tooltip={t("ChargerInfo_Prefer")}
                        onClick={() => {
                           if (!isPreferred(props, charging.facility_preference)) {
                              if (props.charger.charger?.id !== undefined) {
                                 addChargingFacilityPreference({
                                    facility_id: props.charger.charger?.id,
                                    pref: "preferred",
                                 })
                              }
                           } else {
                              if (props.charger.charger?.id !== undefined) {
                                 removeChargingFacilityPreference(props.charger.charger.id)
                              }
                           }
                        }}
                        isChecked={isPreferred(props, charging.facility_preference)}
                        component={<FavoriteIcon sx={{ stroke: "white", strokeWidth: 0.1 }} />}
                     />
                     <ComponentWithCheckCircle
                        tooltip={t("ChargerInfo_Avoid")}
                        onClick={() => {
                           if (!isExcluded(props, charging.facility_preference)) {
                              if (props.charger.charger?.id !== undefined) {
                                 addChargingFacilityPreference({
                                    facility_id: props.charger.charger?.id,
                                    pref: "excluded",
                                 })
                              }
                           } else {
                              if (props.charger.charger?.id !== undefined) {
                                 removeChargingFacilityPreference(props.charger.charger.id)
                              }
                           }
                        }}
                        isChecked={isExcluded(props, charging.facility_preference)}
                        component={<NotInterestedIcon sx={{ stroke: "white", strokeWidth: 0.1 }} />}
                     />
                  </Stack>
               </Stack>
            )}
            {props.charger.stopover?.reachability_by_energy === "NOT_REACHABLE" && (
               <Stack id="stack-2" direction="row" justifyContent="flex-start" alignItems="center">
                  <DangerousIcon fontSize="small" color="error" />
                  {props.charger.stopover.reachability_by_energy}
               </Stack>
            )}

            {props.charger.stopover?.assumed_charging_time !== undefined && (
               <Stack id="stack-3" direction="row" justifyContent="flex-start" alignItems="center">
                  {/* HourglassTopIcon in size medium appears to chunky. Use small with pixel precision adjustment... */}
                  <HourglassTopIcon fontSize="small" sx={{ ml: "2px" }} />
                  {toRouteDurationString(props.charger.stopover.assumed_charging_time ?? 0)}
               </Stack>
            )}
            {props.charger.stopover?.remaining_energy !== undefined &&
               props.charger.stopover?.recommended_recharge_level !== undefined && (
                  <Stack id="stack-4" direction="row" justifyContent="flex-start" alignItems="center">
                     <BatteryCharging60Icon />
                     {props.charger.stopover.remaining_energy?.toLocaleString(undefined, formatOptions)}
                     <ArrowRightAltIcon />
                     {props.charger.stopover.recommended_recharge_level?.toLocaleString(undefined, formatOptions)}
                  </Stack>
               )}

            {props.charger.charger?.supplier !== undefined && (
               <Stack id="stack-4" direction="row" justifyContent="flex-start" alignItems="center">
                  <AttachMoneyIcon fontSize={"medium"} />
                  {props.charger.charger.supplier}
               </Stack>
            )}
            {props.charger.charger?.outlets.map((outlet, outletIdx) => (
               <Stack
                  key={`charging-marker${outletIdx}`}
                  id="stack-4"
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
               >
                  <PowerIcon fontSize={"medium"} />
                  {(outlet.count ?? 0) > 1 ? `${outlet.count} × ` : ""}
                  {outlet.maxPower ?? outlet.estimatedPower} kW{" "}
                  {chargerConnectorIdTConnector(outlet.connector).displayName} {outlet.isDC ? "DC" : "AC"}
               </Stack>
            ))}
            {debugModeEnabled && (
               <Typography variant="body2" component="div">
                  debug-info:
                  <br />
                  availability: {props.charger.charger?.availability?.toString() ?? "n/a"}
                  <br />
                  id: {props.charger.charger?.id ?? "n/a"}
                  <br />
                  priority: {props.charger.charger?.priority ?? "n/a"}
                  <br />
                  provider: {props.charger.charger?.provider ?? "n/a"}
                  <br />
                  providerKey: {props.charger.charger?.providerKey ?? "n/a"}
                  <br />
                  supplier: {props.charger.charger?.supplier ?? "n/a"}
                  <br />
                  position: {props.charger.charger?.position?.lat}, {props.charger.charger?.position?.lon}
               </Typography>
            )}
         </Stack>
      </Box>
   )
}
