import { RouteLink, TILosOnLink } from "../../../out/gen/openapi/routeplanner"
import { lineString } from "@turf/helpers"
import length from "@turf/length"
import { Position } from "geojson"

export type TILosOnLink2 = {
   linkStartIdx: number
   linkEndIdx: number
   tiLosOnLink: TILosOnLink & {
      start: number
   }
}

export type RouteLink2 = RouteLink & {
   end_idx: number | undefined
}

/**
 * Enhance an array of RouteLinks:
 * 1. Add an 'endIdx' property. This is not given in the response, but it's implicit as
 *    we have the 'start' parameter of the subsequent link.
 *    However, for gradient calculation we need start and end of each element.
 *
 *    Note that since there is no subsequent link for the last element of the array, the
 *    calling side must provide the endIdx for last element.
 *    The index describes an element position on the route's polyline array,
 *    hence the end is always the index of the last element of the polyline.
 *
 * @param routeLinks
 * @param lastIndex the last possible index
 */
export function enhanceRouteLinkArray(routeLinks: RouteLink[], lastIndex: number): RouteLink2[] {
   const result: RouteLink2[] = []
   for (let i = 0; i < routeLinks.length; ++i) {
      if (i < routeLinks.length - 1) {
         result.push({
            ...routeLinks[i],
            end_idx: routeLinks[i + 1].start_idx,
         })
      } else {
         result.push({
            ...routeLinks[i],
            end_idx: lastIndex,
         })
      }
   }
   return result
}

/**
 * Enhance the TiLos array that is provided on a route link:
 * 1. Add a 'start' property. This is not given in the response, but it's implicit as
 *    we have the 'end' parameter and the that the elements in the array are in the
 *    correct order.
 *    However, for gradient calculation we need start and end of each element.
 *
 * 2. Add possible omitted 'NOT_ASSIGNED' entries at the end if the last element of the
 *    array does not stop at 'end':1.
 *    However, if the last 'end' entry is close to 1, we shift it to exactly 1.
 *
 * @param routeLink @see {@link RouteLink} but we only need "ti_los" and "start_idx"
 */
export function enhanceTiLosArrayOnLink(
   routeLink: Pick<RouteLink2, "ti_los" | "start_idx" | "end_idx">,
): TILosOnLink2[] {
   const linkStartIdx = routeLink.start_idx
   const linkEndIdx = routeLink.end_idx
   let tiLos = routeLink.ti_los

   if (linkStartIdx === undefined) {
      return []
   }
   if (linkEndIdx === undefined) {
      return []
   }
   if (tiLos === undefined || tiLos.length === 0) {
      return [
         {
            linkStartIdx,
            linkEndIdx,
            tiLosOnLink: {
               start: 0,
               end: 1,
               los: "NotAssigned",
            },
         },
      ]
   }

   // Workaround for https://jira.nts.neusoft.local/browse/CL-4142
   // Remove elements when 'end' did not rise monotonic
   // Only works if 'end' stays the same, will not work in weird situations when 'end' becomes smaller
   // TODO: Remove when issue is fixed
   {
      tiLos = tiLos.filter((element, i, arr) => {
         return i === 0 || arr[i - 1].end < element.end
      })
   }

   // Workaround for https://jira.nts.neusoft.local/browse/CL-4145
   // Remove short tiLos with los 'NotAssigned' (length < xx percent of the link length) when
   // they are surrounded (predecessor and successor los) that are not 'NotAssigned'.
   // TODO: Remove when issue is fixed
   {
      const threshold = 0.25
      tiLos = tiLos.filter((element, i, arr) => {
         if (i > 0) {
            if (arr[i].end - arr[i - 1].end < threshold) {
               if (arr[i].los === "NotAssigned" && arr[i - 1].los !== "NotAssigned") {
                  return false
               }
            }
         }
         return true
      })
   }

   const result: TILosOnLink2[] = []
   for (let i = 0; i < tiLos.length; ++i) {
      // Calculate the start of the part, which is 0 for first element or the end of the predecessor respectively.
      const start = i === 0 ? 0 : tiLos[i - 1].end ?? 0

      // Calculate the end of the part
      // In case of last element, check if we are at end===1 or apply special handling
      let addFinalElement = false
      const end = tiLos[i].end
      if (i === tiLos.length - 1) {
         if (tiLos[i].end !== 1) {
            addFinalElement = true
         }
      }

      const val = {
         linkStartIdx,
         linkEndIdx,
         tiLosOnLink: { los: tiLos[i].los, start, end: tiLos[i].end },
      }

      result.push(val)

      if (addFinalElement) {
         result.push({
            linkStartIdx,
            linkEndIdx,
            tiLosOnLink: { los: "NotAssigned", start: end, end: 1 },
         })
      }
   }
   return result
}

export type TILosWithOffset = {
   offset: number
   tiLos: TILosOnLink2
}

/**
 * Enhances (adds property) start of LOS given relative to total length.
 *
 * For TILos given from the API response we have the start on the segment given by
 * a) startIdx of the corresponding routing link on the polyline coordinate array
 * b) start on this link given relative to the link's total length from 0..1
 *
 * For some application (e.g. coloring the route line) we need to have the start,
 * and possibly the end, of the LOS given relative to the segment's total length 0..1
 * instead.
 *
 * @param tiLos
 * @param coordinates
 */
export function addOffsetToTiLos(tiLos: TILosOnLink2[], coordinates: Position[]): TILosWithOffset[] {
   // We need the total length of the polyline
   // TODO: we actually do calculation twice: a) here for total path and b) in loop incrementally
   // TODO: we could instead to only b and sum up to total and make the "relative" calculation ( x / totalLength) in another loop afterwards.
   // TODO: that's probably more efficient.
   const totalLength = length(lineString(coordinates), { units: "meters" })

   const result: TILosWithOffset[] = []

   let linkOffsetMeter = 0 // Cumulated length of the path to the start of thr current link
   let linkLengthMeter = 0
   let lastStartIndex = 0

   // For each item in the tiLos array, we calculate the length of the path to this item.
   // Calculation is done incremental by calculating only the length from the previous item
   // and summing up the cumulative length over the iterations.
   for (const item of tiLos) {
      // Length of the route from segment start to current link
      // No re-calculation when start index did not change, i.e. more than one ti los on link
      if (lastStartIndex !== item.linkStartIdx) {
         // Cumulate with link length old value and save new index
         linkOffsetMeter += linkLengthMeter
         lastStartIndex = item.linkStartIdx
      }

      // Calc new length of the _current_ link in meter
      // TODO: Only recalculate when not yet calculated for current link
      linkLengthMeter = length(lineString(coordinates.slice(item.linkStartIdx, item.linkEndIdx + 1)), {
         units: "meters",
      })

      // Calculate tiLos offset from the start of the segment, in meters and relative to the total length.
      const tiLosOffsetFromSegmentStartMeter = linkOffsetMeter + linkLengthMeter * item.tiLosOnLink.start
      const tiLosOffsetRelative = tiLosOffsetFromSegmentStartMeter / totalLength

      result.push({
         tiLos: item,
         offset: tiLosOffsetRelative,
      })
   }
   return result
}

export type GradientStops = (string | number)[][]

/**
 * Reduces an array of GradientStops by removing redundant entries.
 *
 * When a set of consecutive entries share the same color, they are reduced so
 * that only the first and last element of this set will remain in the result array .
 *
 * @param stops
 */
export function reduceGradientStops(stops: GradientStops): GradientStops {
   const result: GradientStops = []
   for (let i = 0; i < stops.length; ++i) {
      if (i > 0 && i < stops.length - 1) {
         if (stops[i - 1][1] === stops[i][1] && stops[i + 1][1] === stops[i][1]) {
            // don't push intermediate value if it's same to both it's neighbours
            continue
         }
      }
      // Otherwise push it
      result.push(stops[i])
   }
   return result
}
