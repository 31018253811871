import { Fragment, FunctionComponent } from "react"
import { Marker, useMap } from "react-map-gl/maplibre"
import { ChargerInfo } from "../../Store"
import { ChargerInfoComponent } from "./ChargerInfoComponent"
import { PointLike, PositionAnchor } from "maplibre-gl"
import { getQ } from "./ChargerInfoQHelper"

const offsetsByQ: PointLike[] = [
   [-20, -15],
   [+20, -15],
   [-20, +15],
   [+20, +15],
]
const anchorsByQ: PositionAnchor[] = ["bottom-left", "bottom-right", "top-left", "top-right"]

interface ChargerPopupProperties {
   onClick?: (e: Event) => void
   chargerInfo: ChargerInfo
   zIndex?: number
}

export const ChargerInfoMarker: FunctionComponent<ChargerPopupProperties> = props => {
   const { current: map } = useMap()

   return (
      <Fragment>
         <Marker
            anchor={
               anchorsByQ[
                  getQ(map, props.chargerInfo?.charger?.position ?? props.chargerInfo?.stopover?.position ?? undefined)
               ]
            }
            latitude={props.chargerInfo?.charger?.position?.lat ?? props.chargerInfo?.stopover?.position?.lat ?? 0}
            longitude={props.chargerInfo?.charger?.position?.lon ?? props.chargerInfo?.stopover?.position?.lon ?? 0}
            offset={
               offsetsByQ[
                  getQ(map, props.chargerInfo?.charger?.position ?? props.chargerInfo?.stopover?.position ?? undefined)
               ]
            }
            style={{
               zIndex: props.zIndex ?? 1,
               cursor: "pointer",
            }}
            onClick={e => {
               if (props.onClick) props.onClick?.(e.originalEvent)
            }}
         >
            <ChargerInfoComponent
               charger={props.chargerInfo}
               quadrant={getQ(
                  map,
                  props.chargerInfo?.charger?.position ?? props.chargerInfo?.stopover?.position ?? undefined,
               )}
            />
         </Marker>
      </Fragment>
   )
}
