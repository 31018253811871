import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { Box, Button, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from "@mui/material"
import { FunctionComponent } from "react"
import { Route } from "../../../out/gen/openapi/routeplanner"
import { theme } from "../../Theme"
import { toRouteDistance } from "./distance"
import { toRouteDurationString } from "./time"
import { toEnergyConsumptionString } from "./energy"
import { grey } from "@mui/material/colors"

interface AlternativeRoutesItemProperties {
   route: Route
   isSelected: boolean
   color: string
   onClick: () => void
   onSelectDetails: () => void
   onMouseEnter: () => void
   onMouseLeave: () => void
}

export const AlternativeRoutesItem: FunctionComponent<AlternativeRoutesItemProperties> = props => {
   const distance = props.route.travel_info.distance
   const duration = props.route.travel_info.time
   const trafficDelay = props.route.travel_info.delay ?? -1
   const energyConsumption = props.route.travel_info.energyConsumption
   const hasRoadblock = (props.route.number_traffic_blockings ?? -1) > 0

   const trafficDelayColor =
      trafficDelay / duration < 0.15
         ? theme.palette.success
         : trafficDelay / duration < 0.25
           ? theme.palette.warning
           : theme.palette.error

   const [firstVia] = props.route.via_infos?.map(v => v.name) ?? []
   const otherVias = props.route.via_infos?.filter((v, i) => i !== 0).map(v => v.name) ?? []

   return (
      <ListItemButton
         onClick={() => props.onClick()}
         onMouseEnter={() => props.onMouseEnter()}
         onMouseLeave={() => props.onMouseLeave()}
         sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "spread",
         }}
         selected={props.isSelected}
      >
         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               alignItems: "center",
               justifyContent: "space-between",
               width: "100%",
            }}
         >
            <ListItemIcon
               sx={{
                  color: props.color,
               }}
            >
               <DirectionsCarIcon />
            </ListItemIcon>
            <ListItemText
               primary={firstVia !== undefined ? "via " + firstVia : ""}
               secondary={otherVias.slice(0, 3).join(", ")}
               primaryTypographyProps={{
                  width: "10em",
               }}
               secondaryTypographyProps={{
                  width: "10em",
               }}
            />
            <Stack spacing={theme.spacing(1)} alignItems={"end"}>
               <Stack spacing={theme.spacing(1)} direction={"row"}>
                  {hasRoadblock && (
                     <Tooltip title="There are roadblocks on the route">
                        <WarningAmberIcon fontSize={"medium"} sx={{ color: theme.palette.error.main }} />
                     </Tooltip>
                  )}
                  {duration > -1 && (
                     <Typography variant={"body1"} color={trafficDelayColor.main}>
                        {toRouteDurationString(duration)}
                     </Typography>
                  )}
               </Stack>
               <Stack spacing={theme.spacing(1)} direction={"row"}>
                  {distance > -1 && (
                     <Typography variant={"body2"} sx={{ color: grey[500] }}>
                        {toRouteDistance(distance)}
                     </Typography>
                  )}
                  {energyConsumption !== undefined && (
                     <Typography variant={"body2"} sx={{ color: grey[500] }}>
                        {toEnergyConsumptionString(energyConsumption)}
                     </Typography>
                  )}
               </Stack>
            </Stack>
         </Box>

         {props.isSelected && (
            <Button
               variant="outlined"
               aria-label="details"
               size="large"
               sx={{
                  alignSelf: "flex-end",
                  mt: 1,
                  mb: 1,
               }}
               onClick={() => props.onSelectDetails()}
            >
               Details
            </Button>
         )}
      </ListItemButton>
   )
}
