import { FunctionComponent } from "react"
import { Accordion, AccordionDetails, AccordionSummary, Slider, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import useStore from "../../Store"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

export const BatteryConfiguration: FunctionComponent = () => {
   const { t } = useTranslation()
   const energyStatus = useStore(store => store.energyStatus)
   const updateEnergyStatus = useStore(store => store.updateEnergyStatus)

   // Introduce local state for controlled widgets to avoid propagating high-frequency state update (e.g. from sliders) to store.
   const [remainingEnergy, setRemainingEnergy] = useState<number>(energyStatus.remaining_energy_percent)
   const [minEnergyAtDestination, setMinEnergyAtDestination] = useState<number>(
      energyStatus.min_energy_at_destination_percent ?? 0,
   )
   const [minEnergyAtStopover, setMinEnergyAtStopover] = useState<number>(
      energyStatus.min_energy_at_stopover_percent ?? 0,
   )
   const [maxEnergy, setMaxEnergy] = useState<number>(energyStatus.max_energy_percent ?? 0)
   const [otherDevicesPowerConsumption, setOtherDevicePowerConsumption] = useState<number>(
      (energyStatus.other_devices_power_consumption_kW ?? 0) * 1000,
   )

   return (
      <Accordion>
         <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>{t("VehicleInput_BatterySettingsLabel")}</Typography>
         </AccordionSummary>
         <AccordionDetails>
            <Typography>{`${t("VehicleInput_BatterySettingsChargeAtDeparture")}: ${remainingEnergy}%`}</Typography>
            <Slider
               value={remainingEnergy} // use the local state, not the one from the store.
               onChange={(_, val) => setRemainingEnergy(val as number)}
               onChangeCommitted={(_, val) => updateEnergyStatus({ remaining_energy_percent: val as number })}
               max={100}
               min={1}
               aria-label="Default"
               valueLabelDisplay="auto"
            />
            <Typography>{`${t(
               "VehicleInput_BatterySettingsMinimumChargeLevelAtDest",
            )}: ${minEnergyAtDestination}%`}</Typography>
            <Slider
               value={minEnergyAtDestination} // use the local state, not the one from the store.
               onChange={(_, val) => {
                  const value = val as number
                  setMinEnergyAtDestination(Math.min(value, maxEnergy))
               }}
               onChangeCommitted={(_, val) => {
                  const value = val as number
                  updateEnergyStatus({ min_energy_at_destination_percent: Math.min(value, maxEnergy) })
               }}
               max={100}
               min={1}
               aria-label="Default"
               valueLabelDisplay="auto"
            />
            <Typography>{`${t("VehicleInput_BatterySettingsMinimumChargeLevel")}: ${minEnergyAtStopover}%`}</Typography>
            <Slider
               value={minEnergyAtStopover} // use the local state, not the one from the store.
               onChange={(_, val) => {
                  const value = val as number
                  setMinEnergyAtStopover(Math.min(value, maxEnergy))
               }}
               onChangeCommitted={(_, val) => {
                  const value = val as number
                  updateEnergyStatus({ min_energy_at_stopover_percent: Math.min(value, maxEnergy) })
               }}
               max={100}
               min={1}
               aria-label="Default"
               valueLabelDisplay="auto"
            />
            <Typography>{`Maximum charge level: ${maxEnergy}%`}</Typography>
            <Slider
               value={maxEnergy} // use the local state, not the one from the store.
               onChange={(_, val) => {
                  const value = val as number
                  const usedValue = Math.max(value, Math.max(minEnergyAtDestination, minEnergyAtStopover))
                  setMaxEnergy(usedValue)
               }}
               onChangeCommitted={(_, val) => {
                  const value = val as number
                  const usedValue = Math.max(value, Math.max(minEnergyAtDestination, minEnergyAtStopover))
                  updateEnergyStatus({ max_energy_percent: usedValue })
               }}
               max={100}
               min={1}
               aria-label="Default"
               valueLabelDisplay="auto"
            />
            <Typography>{`${t(
               "VehicleInput_BatterySettingsOtherDevicesPowerConsumption",
            )}: ${otherDevicesPowerConsumption} W`}</Typography>
            <Slider
               value={otherDevicesPowerConsumption} // use the local state, not the one from the store.
               onChange={(_, val) => setOtherDevicePowerConsumption(val as number)}
               onChangeCommitted={(_, val) =>
                  updateEnergyStatus({ other_devices_power_consumption_kW: (val as number) / 1000 })
               }
               max={1000}
               min={0}
               step={10}
               aria-label="Default"
               valueLabelDisplay="auto"
            />
         </AccordionDetails>
      </Accordion>
   )
}
