import { Fragment, FunctionComponent, useEffect } from "react"
import { LinkPath, Route } from "../../../out/gen/openapi/routeplanner"
import useStore, { EditBlockage, UpdateStore, UserBlockage, UserBlockageElement, PositionOnPolyline } from "../../Store"
import { Layer, LayerProps, LngLat, LngLatBounds, Source, MapLayerMouseEvent, useMap } from "react-map-gl/maplibre"
import { toGeoJSON } from "@mapbox/polyline"
import { BBox, Position, LineString } from "@turf/helpers"
import along from "@turf/along"
import distance from "@turf/distance"
import nearestPointOnLine from "@turf/nearest-point-on-line"
import { RouteSegment } from "../../../out/gen/openapi/routeplanner"
import {
   addOffsetToTiLos,
   enhanceRouteLinkArray,
   enhanceTiLosArrayOnLink,
   reduceGradientStops,
} from "./RouteLineDisplayHelper"
import { link } from "fs"
import { min } from "lodash"
import { Bed } from "@mui/icons-material"

export const UserBlockage_RenderLineLayerId = "UserBlockage_RenderLineLayerId"
export const UserBlockage_RenderLineEditLayerId = "UserBlockage_RenderLineEditLayerId"
export const UserBlockage_RenderLineRouteId = "UserBlockage_RenderLineRouteId"
export const UserBlockage_HitAreaPointLayerId = "UserBlockage_HitAreaPointLayerId"
export const UserBlockage_HitAreaLineLayerId = "UserBlockage_HitAreaLineLayerId"

function appropriateRadius(pos: Position, mapBounds: BBox): number {
   const minDistanceToBorder = min([
      distance(pos, [mapBounds[0], pos[1]]),
      distance(pos, [mapBounds[2], pos[1]]),
      distance(pos, [pos[0], mapBounds[1]]),
      distance(pos, [pos[0], mapBounds[3]]),
   ])
   return minDistanceToBorder !== undefined ? minDistanceToBorder / 2 : 1.0
}

function positionOnPolyline(routeSegment: RouteSegment, position: Position): PositionOnPolyline | undefined {
   const poly = toGeoJSON(routeSegment.polyline.line).coordinates
   const line: LineString = { type: "LineString", coordinates: poly }
   const nearPoint = nearestPointOnLine(line, position)
   if (
      nearPoint.properties.index !== undefined &&
      nearPoint.properties.index < line.coordinates.length - 1 &&
      nearPoint.properties.location !== undefined
   ) {
      const posOnRoute = along(line, nearPoint.properties.location ?? 0)
      const c = line.coordinates
      const segmentLength = distance(
         line.coordinates[nearPoint.properties.index],
         line.coordinates[nearPoint.properties.index + 1],
      )
      const distanceOnSegment = distance(line.coordinates[nearPoint.properties.index], posOnRoute)

      return {
         segmentIndex: nearPoint.properties.index,
         posOnSegment: segmentLength > 0.0 ? distanceOnSegment / segmentLength : 0,
      }
   }

   return undefined
}

function distancePointToRouteSegment(routeSegment: RouteSegment, point: Position): number | undefined {
   const poly = toGeoJSON(routeSegment.polyline.line).coordinates
   const line: LineString = { type: "LineString", coordinates: poly }
   const nearPoint = nearestPointOnLine(line, point)
   return nearPoint.properties.dist
}

export function createUserBlockage(
   updateStore: UpdateStore,
   activeRoute: Route | undefined,
   position: Position,
   mapBounds: BBox,
) {
   if (activeRoute && activeRoute.segments?.length > 0) {
      const distances = activeRoute.segments
         .map(s => distancePointToRouteSegment(s, position))
         .filter(d => d !== undefined) as [number]
      const minDistance = distances.reduce((d1, d2) => Math.min(d1, d2))
      const nearestSegmentIndex = distances.findIndex(d => d === minDistance)

      if (nearestSegmentIndex >= 0) {
         const poly = toGeoJSON(activeRoute.segments[nearestSegmentIndex].polyline.line).coordinates
         const line: LineString = { type: "LineString", coordinates: poly }
         const nearPoint = nearestPointOnLine(line, position)
         if (nearPoint.properties.index !== undefined && nearPoint.properties.location !== undefined) {
            const posOnRoute = along(line, nearPoint.properties.location ?? 0)
            const radius = appropriateRadius(posOnRoute.geometry.coordinates, mapBounds)

            const firstAllowedIndex = 1 // exclude first and last segment from blockage
            const lastAllowedIndex = line.coordinates.length - 3

            let firstToStartOutsideIndex = nearPoint.properties.index
            while (
               firstToStartOutsideIndex > firstAllowedIndex &&
               distance(line.coordinates[firstToStartOutsideIndex], posOnRoute) <= radius
            )
               firstToStartOutsideIndex -= 1

            const posOnFirstSegment = 0.0 // todo calculate exact value

            let firstToEndOutsideIndex = nearPoint.properties.index + 1
            while (
               firstToEndOutsideIndex < lastAllowedIndex &&
               distance(line.coordinates[firstToEndOutsideIndex], posOnRoute) <= radius
            )
               firstToEndOutsideIndex += 1

            const posOnLastSegment = 1.0 // todo calculate exact value

            updateStore(as => {
               const newId = as.userBlockages.length === 0 ? 0 : Math.max(...as.userBlockages.map(b => b.id)) + 1
               const blockage: UserBlockage = {
                  id: newId,
                  atRouteSegment: activeRoute.segments[nearestSegmentIndex],
                  begin: { segmentIndex: firstToStartOutsideIndex, posOnSegment: posOnFirstSegment },
                  end: { segmentIndex: firstToEndOutsideIndex - 1, posOnSegment: posOnLastSegment },
               }
               as.userBlockages.push(blockage)
            })
         }
      }
   }
}

function mid(from: number, to: number, t: number): number {
   return (1.0 - t) * from + t * to
}

function midPoint(fromPoint: GeoJSON.Position, toPoint: GeoJSON.Position, relPos: number): GeoJSON.Position {
   return [mid(fromPoint[0], toPoint[0], relPos), mid(fromPoint[1], toPoint[1], relPos)]
}

function blockageCoordinates(blockage: UserBlockage): GeoJSON.Position[] {
   const routeCoordinates = toGeoJSON(blockage.atRouteSegment.polyline.line).coordinates
   const coordinates = routeCoordinates.slice(blockage.begin.segmentIndex, blockage.end.segmentIndex + 2)
   const sz = coordinates.length
   if (
      sz >= 2 &&
      blockage.begin.segmentIndex >= 0 &&
      blockage.begin.segmentIndex < coordinates.length &&
      blockage.end.segmentIndex >= 0 &&
      blockage.end.segmentIndex < coordinates.length &&
      blockage.begin.segmentIndex <= blockage.end.segmentIndex
   ) {
      const startPosition = midPoint(coordinates[0], coordinates[1], blockage.begin.posOnSegment)
      const endPosition = midPoint(coordinates[sz - 2], coordinates[sz - 1], blockage.end.posOnSegment)
      coordinates[0] = startPosition
      coordinates[-1] = endPosition
   }
   return coordinates
}

function blockageRouteCoordinates(blockage: UserBlockage): GeoJSON.Position[] {
   const routeCoordinates = toGeoJSON(blockage.atRouteSegment.polyline.line).coordinates
   return routeCoordinates
}

function mapSegmentPosToSection(
   posOnSegment: PositionOnPolyline,
   coordList: GeoJSON.Position[],
   sectionBegin: number,
   sectionEnd: number,
): number | undefined {
   if (posOnSegment.segmentIndex >= sectionBegin && posOnSegment.segmentIndex < sectionEnd) {
      let lenBefore = 0.0
      let lenAfter = 0.0

      for (let i = sectionBegin; i < sectionEnd - 1; ++i) {
         const segmentLength = distance(coordList[i], coordList[i + 1])
         if (i < posOnSegment.segmentIndex) {
            lenBefore += segmentLength
         } else if (i === posOnSegment.segmentIndex) {
            lenBefore += posOnSegment.posOnSegment * segmentLength
            lenAfter += (1.0 - posOnSegment.posOnSegment) * segmentLength
         } else {
            lenAfter += segmentLength
         }
      }

      if (lenBefore + lenAfter > 0.0) return lenBefore / (lenBefore + lenAfter)
   }

   return undefined
}

export function toLinkPath(blockage: UserBlockage): LinkPath {
   const linkPath: LinkPath = { linkIds: [] }
   const links = blockage.atRouteSegment.route_links
   if (links != null) {
      const coordList = toGeoJSON(blockage.atRouteSegment.polyline.line).coordinates
      const lastLinksCoordIdx = coordList.length - 1
      for (let i = 0; i < links.length; ++i) {
         const linkCoordIdxFrom = links[i].start_idx
         const linkCoordIdxTo = i + 1 < links.length ? links[i + 1].start_idx : lastLinksCoordIdx
         if (linkCoordIdxFrom != null && linkCoordIdxTo != null) {
            const isOutside =
               linkCoordIdxFrom > blockage.end.segmentIndex || linkCoordIdxTo <= blockage.begin.segmentIndex
            if (!isOutside) {
               linkPath.linkIds.push(links[i].id)
               const posOnfFirstLink = mapSegmentPosToSection(
                  blockage.begin,
                  coordList,
                  linkCoordIdxFrom,
                  linkCoordIdxTo,
               )
               if (posOnfFirstLink !== undefined) linkPath.excludeBeginOfFirstLink = posOnfFirstLink
               const posOnLastLink = mapSegmentPosToSection(blockage.end, coordList, linkCoordIdxFrom, linkCoordIdxTo)
               if (posOnLastLink !== undefined) linkPath.includeBeginOfLastLink = posOnLastLink
            }
         }
      }
      return linkPath
   }
   return { linkIds: [] }
}

interface IUserBlockageDisplayProperties {}

export const UserBlockageDisplay: FunctionComponent<IUserBlockageDisplayProperties> = props => {
   const blockages = useStore(s => s.userBlockages)
   const mapEditStatus = useStore(s => s.mapEditStatus)
   const updateStore = useStore(s => s.update)
   const updateLocationSelectPopupProps = useStore(state => state.updateLocationSelectPopupProps)

   const { current: map } = useMap()

   function onMouseMoveHandler(e: MapLayerMouseEvent) {
      if (mapEditStatus?.mode === "blockage-drag") {
         // drag(editBlockage, e)
      } else {
         const findFeature = (element: UserBlockageElement) => e.features?.find(f => f.properties?.element === element)
         const bestFeature = findFeature("begin") ?? findFeature("end") ?? findFeature("section") // prefer endings

         if (bestFeature && typeof bestFeature.id === "number") {
            if (mapEditStatus === undefined || mapEditStatus?.mode === "blockage-hover") {
               const id = bestFeature.id
               updateStore(s => {
                  s.mapEditStatus = {
                     mode: "blockage-hover",
                     element: bestFeature.properties.element,
                     blockageId: bestFeature.id as number,
                  }
               })
            }
         }
      }
   }

   function onMouseLeaveHandler(e: MapLayerMouseEvent) {
      if ((mapEditStatus as EditBlockage)?.mode === "blockage-hover") {
         updateStore(s => {
            s.mapEditStatus = undefined
         })
      }
   }

   function drag(editBlockage: EditBlockage, ev: MapLayerMouseEvent) {
      if (editBlockage.element === "begin" || editBlockage.element === "end") {
         const routeSegment = blockages.find(b => b.id === editBlockage.blockageId)?.atRouteSegment
         const positionOnSegment = routeSegment
            ? positionOnPolyline(routeSegment, [ev.lngLat.lng, ev.lngLat.lat])
            : undefined

         if (positionOnSegment) {
            updateStore(s => {
               s.mapEditStatus = {
                  mode: "blockage-drag",
                  element: editBlockage.element,
                  blockageId: editBlockage.blockageId,
                  curPosition: positionOnSegment,
               }
            })
         }
      }
   }

   function onMouseDownHandler(e: MapLayerMouseEvent) {
      if (mapEditStatus?.mode === "blockage-hover") {
         e.preventDefault()
         drag(mapEditStatus, e)
      }
   }

   function onMouseDragHandler(e: MapLayerMouseEvent) {
      if (mapEditStatus?.mode === "blockage-drag") {
         e.preventDefault()
         drag(mapEditStatus, e)
      }
   }
   function onMouseUpHandler(e: MapLayerMouseEvent) {
      const editBlockageIndex = blockages.findIndex(b => b.id === mapEditStatus?.blockageId)

      updateStore(s => {
         if (editBlockageIndex >= 0) {
            s.userBlockages[editBlockageIndex] = updateEditBlockage(blockages[editBlockageIndex])
         }
         s.mapEditStatus = undefined
      })
   }

   function onContextMenu(e: MapLayerMouseEvent) {
      updateLocationSelectPopupProps({
         show: true,
         pos: { lat: e.lngLat.lat, lon: e.lngLat.lng },
      })
      updateStore(s => {
         if (s.mapEditStatus?.mode) s.mapEditStatus = { ...(mapEditStatus as EditBlockage), mode: "blockage-popup" }
      })
   }

   useEffect(() => {
      if (mapEditStatus?.mode === "blockage-hover") {
         map?.on("mouseleave", UserBlockage_HitAreaLineLayerId, onMouseLeaveHandler)
         map?.on("mouseleave", UserBlockage_HitAreaPointLayerId, onMouseLeaveHandler)
         map?.on("mousedown", UserBlockage_HitAreaPointLayerId, onMouseDownHandler)
      } else {
         map?.on("mousemove", UserBlockage_HitAreaLineLayerId, onMouseMoveHandler)
         map?.on("mousemove", UserBlockage_HitAreaPointLayerId, onMouseMoveHandler)
      }
      if (mapEditStatus?.mode === "blockage-drag") {
         map?.on("mouseup", onMouseUpHandler)
         map?.on("mousemove", onMouseDragHandler)
      }
      map?.on("contextmenu", UserBlockage_HitAreaLineLayerId, onContextMenu)
      map?.on("contextmenu", UserBlockage_HitAreaPointLayerId, onContextMenu)
      return () => {
         map?.off("mouseleave", UserBlockage_HitAreaLineLayerId, onMouseLeaveHandler)
         map?.off("mouseleave", UserBlockage_HitAreaPointLayerId, onMouseLeaveHandler)
         map?.off("mousemove", UserBlockage_HitAreaLineLayerId, onMouseMoveHandler)
         map?.off("mousemove", UserBlockage_HitAreaPointLayerId, onMouseMoveHandler)
         map?.off("contextmenu", UserBlockage_HitAreaLineLayerId, onContextMenu)
         map?.off("contextmenu", UserBlockage_HitAreaPointLayerId, onContextMenu)
         map?.off("mousedown", UserBlockage_HitAreaPointLayerId, onMouseDownHandler)
         map?.off("mouseup", onMouseUpHandler)
         map?.off("mousemove", onMouseDragHandler)
      }
   }, [mapEditStatus])

   function toLineString(blockage: UserBlockage) {
      const res = {
         type: "Feature",
         id: blockage.id,
         properties: {
            element: "section",
         },
         geometry: {
            type: "LineString",
            coordinates: blockageCoordinates(blockage),
         },
      }
      return res
   }

   function toPoint(blockage: UserBlockage, element: UserBlockageElement) {
      const lineSTring = blockageCoordinates(blockage)
      const res = {
         type: "Feature",
         id: blockage.id,
         properties: {
            element: element,
         },
         geometry: {
            type: "Point",
            coordinates: element === "begin" ? lineSTring.at(0) : lineSTring.at(-1),
         },
      }
      return res
   }

   function updateEditBlockage(blockage: UserBlockage): UserBlockage {
      if (mapEditStatus?.mode === "blockage-drag") {
         const beginPos =
            mapEditStatus.element === "begin" && mapEditStatus.curPosition ? mapEditStatus.curPosition : blockage.begin
         const endPos =
            mapEditStatus.element === "end" && mapEditStatus.curPosition ? mapEditStatus.curPosition : blockage.end
         const mustSwap =
            endPos.segmentIndex < beginPos.segmentIndex ||
            (endPos.segmentIndex === beginPos.segmentIndex && endPos.posOnSegment < beginPos.posOnSegment)
         return mustSwap ? { ...blockage, begin: endPos, end: beginPos } : { ...blockage, begin: beginPos, end: endPos }
      } else {
         return blockage
      }
   }

   const editBlockage = blockages.find(b => b.id === mapEditStatus?.blockageId)
   const editedBlockage = editBlockage ? updateEditBlockage(editBlockage) : undefined

   const editLayer = editedBlockage ? (
      <Source
         id={UserBlockage_RenderLineEditLayerId}
         key={UserBlockage_RenderLineEditLayerId}
         type="geojson"
         lineMetrics={true}
         data={{
            type: "Feature",
            id: editedBlockage.id,
            properties: {
               element: "section",
            },
            geometry: {
               type: "LineString",
               coordinates: blockageCoordinates(editedBlockage),
            },
         }}
      >
         <Layer
            id={UserBlockage_RenderLineEditLayerId}
            type="line"
            source="route"
            layout={{
               "line-join": "round",
               "line-cap": "round",
            }}
            paint={{
               "line-color": "#ffffff",
               "line-width": 16,
            }}
         />
         <Layer
            id={UserBlockage_RenderLineEditLayerId + "-inner"}
            type="line"
            source="route"
            layout={{
               "line-join": "round",
               "line-cap": "round",
            }}
            paint={{
               "line-color": "#ff0000",
               "line-width": 5,
            }}
         />
      </Source>
   ) : null

   const editLayerRoutePath =
      editBlockage && (mapEditStatus?.element === "begin" || mapEditStatus?.element === "end") ? (
         <Source
            id={UserBlockage_RenderLineRouteId}
            key={UserBlockage_RenderLineRouteId}
            type="geojson"
            lineMetrics={true}
            data={{
               type: "Feature",
               id: editBlockage.id,
               properties: {
                  element: "section",
               },
               geometry: {
                  type: "LineString",
                  coordinates: blockageRouteCoordinates(editBlockage),
               },
            }}
         >
            <Layer
               id={UserBlockage_RenderLineRouteId}
               type="line"
               source="route"
               layout={{
                  "line-join": "round",
                  "line-cap": "round",
               }}
               paint={{
                  "line-color": "#000000",
                  "line-width": 2,
               }}
            />
         </Source>
      ) : null

   const featureCollectionPoints = {
      type: "FeatureCollection",
      features: blockages.flatMap(b => (["begin", "end"] as UserBlockageElement[]).map(e => toPoint(b, e))),
   }

   const pointLayer = (
      <Source
         id={UserBlockage_HitAreaPointLayerId}
         key={UserBlockage_HitAreaPointLayerId}
         type="geojson"
         lineMetrics={true}
         data={featureCollectionPoints}
      >
         <Layer
            id={UserBlockage_HitAreaPointLayerId}
            type="circle"
            source="route"
            paint={{
               "circle-color": "#00000000", // invisible, just for mouse events
               "circle-radius": 10,
            }}
         />
      </Source>
   )

   const featureCollectionLinesNoEdit = {
      type: "FeatureCollection",
      features: blockages.filter(b => b.id !== mapEditStatus?.blockageId).map(b => toLineString(b)),
   }

   const featureCollectionLinesNll = {
      type: "FeatureCollection",
      features: blockages.map(b => toLineString(b)),
   }
   return (
      <Fragment>
         <Source
            id={UserBlockage_RenderLineLayerId}
            key={UserBlockage_RenderLineLayerId}
            type="geojson"
            lineMetrics={true}
            data={featureCollectionLinesNoEdit}
         >
            <Layer
               id={UserBlockage_RenderLineLayerId}
               type="line"
               source="route"
               layout={{
                  "line-join": "round",
                  "line-cap": "round",
               }}
               paint={{
                  "line-color": "#ffffff",
                  "line-width": 12,
               }}
            />
            <Layer
               id={UserBlockage_RenderLineLayerId + "-inner"}
               type="line"
               source="route"
               layout={{
                  "line-join": "round",
                  "line-cap": "round",
               }}
               paint={{
                  "line-color": "#ff0000",
                  "line-width": 2,
               }}
            />
         </Source>
         {editLayerRoutePath}
         {editLayer}
         <Source
            id={UserBlockage_HitAreaLineLayerId}
            key={UserBlockage_HitAreaLineLayerId}
            type="geojson"
            lineMetrics={true}
            data={featureCollectionLinesNll}
         >
            <Layer
               id={UserBlockage_HitAreaLineLayerId}
               type="line"
               source="route"
               layout={{
                  "line-join": "round",
                  "line-cap": "round",
               }}
               paint={{
                  "line-color": "#00000000",
                  "line-width": 16,
               }}
            />
         </Source>
         {pointLayer}
      </Fragment>
   )
}
